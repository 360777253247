import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest, HttpParams, HttpResponse } from '@angular/common/http';
import { EntityAbstractService } from '~/services/api/web/entity/entityAbstractService';
import { HttpClientWrapper } from '~/services/api/httpClientWrapper';
import { BaseWebService } from '~/services/api/web/base/baseWebService';
import { Observable } from 'rxjs';
import { map as _map, forEach as _forEach } from 'lodash';

// Models
import { UserModel } from '~/models/userModel';
import { EntityModel } from '~/models/entityModel';
import { BrandModel } from '~/models/brandModel';
import { ResponseModel } from '~/models/responseModel';
import { BaleMovementModel } from '~/models/baleMovementModel';
import { AssignPartnerModel } from '~/models/assignPartnerModel';
import { FtpConnectionModel } from '~/models/ftpConnectionModel';
import { WoolTypeMappingModel } from '~/models/woolTypeMappingModel';
import { WoolStoreLocationModel } from '~/models/woolStoreLocationModel';
import { EntityTradingOnboardModel } from '~/models/entityTradingOnboardModel';

@Injectable()
export class EntityService extends BaseWebService implements EntityAbstractService {

    public static readonly PATH:string = '/entities';

    //@TODO phase this out when upload functions are redone…
    private httpClientLegacy:HttpClient;

    constructor(http: HttpClientWrapper, httpClientLegacy:HttpClient) {
        super(http);
        this.httpClientLegacy = httpClientLegacy;
    }

    public getEntity(entityId:number) : Observable<ResponseModel> {
        let url:string = EntityService.URL + EntityService.PATH + '/' + entityId;
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return new EntityModel(response['data']);
        });
    }

    public getAll() : Observable<ResponseModel> {
        let url:string = EntityService.URL + EntityService.PATH;
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (entity) => new EntityModel(entity));
        });
    }

    /**
     * Retrieves all entities that have the WoolGrower Role
     * @returns {Observable<>}
     */
    public getAllByWoolGrowerRole() : Observable<ResponseModel> {
        let url:string = EntityService.URL + EntityService.PATH;
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            let data:EntityModel[] = [];

            _forEach(response['data'], (entity) => {
                let entityModel:EntityModel = new EntityModel(entity);
                if(entityModel.isWoolGrower) {
                    data.push(entityModel);
                }
            });

            return data;
        });
    }

    public getByPartner(partner:EntityModel) : Observable<ResponseModel> {
        let url:string = EntityService.URL + EntityService.PATH + '/getentityforpartner/';
        let params = new HttpParams();
        params = params.append('entityId', partner.entityId.toString());

        let options:{} = {
            params : params
        };
        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (entity) => new EntityModel(entity));
        });
    }

    public getAllByAdminPrivilege() : Observable<ResponseModel> {
        let url:string = EntityService.URL + EntityService.PATH + '/canadmin';
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (entity) => new EntityModel(entity));
        });
    }

    public getFullyIntegratedPartners() : Observable<ResponseModel> {
        let url:string = EntityService.URL + EntityService.PATH + '/fullyintegratedpartners';
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (entity) => new EntityModel(entity));
        });
    }

    public checkEntityName(entity:EntityModel) : Observable<ResponseModel> {
        let url:string = EntityService.URL + EntityService.PATH + '/nameexist';
        let body:any = entity;
        let options:{}={};

        return this.http.post(url,body,options);
    }

    public getPartiallyIntegratedPartners() : Observable<ResponseModel> {
        let url:string = EntityService.URL + EntityService.PATH + '/partialintegratedpartners';
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (entity) => new EntityModel(entity));
        });
    }

    public getEntityContacts(item:EntityModel|number) : Observable<ResponseModel> {
        let entityId = (item instanceof EntityModel) ? item.entityId : item;
        let url:string = EntityService.URL + EntityService.PATH +  '/' + entityId.toString() + '/primary_contacts';
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (contact) => new UserModel(contact));
        });
    }

    public updatePaymentMethod(entityId:number, paymentMethodId:number) : Observable<ResponseModel> {
        let url:string = EntityService.URL + EntityService.PATH + '/' + entityId.toString() + '/setpaymentmethod/'+ '/' + paymentMethodId.toString();
        let body:any = {};
        let options:{} = {};

        return this.http.post(url, body, options);
    }
    public updateKeyContact(contactId:number, entityId:number) :  Observable<ResponseModel> {
        let url:string = EntityService.URL + EntityService.PATH + '/' + entityId.toString() + '/keycontact/'+ '/' + contactId.toString() +'/update';
        let body:any = {};
        let options:{} = {};

        return this.http.post(url, body, options);
    }

    public getEntityStores(item:EntityModel|number) : Observable<ResponseModel> {
        let entityId = (item instanceof EntityModel) ? item.entityId : item;
        let url:string = EntityService.URL + EntityService.PATH + '/' + entityId.toString() + '/woolstore_locations';
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (woolStore) => new WoolStoreLocationModel(woolStore));
        });
    }

    public getEntityStoresForBrand(item:BrandModel|number) : Observable<ResponseModel> {
        let brandId = (item instanceof BrandModel) ? item.brandId : item;
        let url:string = EntityService.URL + EntityService.PATH + '/brand/' + brandId.toString() + '/woolstore_locations';
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (woolStore) => new WoolStoreLocationModel(woolStore));
        });
    }

    public getEntityAndBrokerStores(brandId:number, partnerId:number) : Observable<ResponseModel> {
        let url:string = EntityService.URL + EntityService.PATH + '/brand/' + brandId.toString() + '/woolstore_locations?partnerId=' + partnerId.toString();
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (woolStore) => new WoolStoreLocationModel(woolStore));
        });
    }

    public getBaleMovement(item:EntityModel|number) : Observable<ResponseModel> {
        let entityId = (item instanceof EntityModel) ? item.entityId : item;
        let url:string = EntityService.URL + EntityService.PATH + '/' + entityId.toString() + '/balemovement';
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return new BaleMovementModel(response['data']);
        });
    }

    public getWoolTypeMappings(entity:EntityModel) : Observable<ResponseModel> {
        let url:string = EntityService.URL + EntityService.PATH + '/' + entity.entityId.toString() + '/wooltypemapping';
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (woolTypeMapping) => new WoolTypeMappingModel(woolTypeMapping));
        });
    }

    public getEntityTradingOnboard(entity:EntityModel) : Observable<ResponseModel> {
        let url:string = EntityService.URL + EntityService.PATH + '/' + entity.entityId.toString() + '/tradingonboard';
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return new EntityTradingOnboardModel(response['data']);
        });
    }

    public search(item:EntityModel|number, query:string) : Observable<ResponseModel> {
        let entityId = (item instanceof EntityModel) ? item.entityId : item;
        let url:string = EntityService.URL + EntityService.PATH + '/' + entityId.toString() + '/search?nameoremail=' + encodeURIComponent(query);
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (entity) => new EntityModel(entity));
        });
    }

    public searchBrokers(item:EntityModel|number, query:string) : Observable<ResponseModel> {
        let entityId = (item instanceof EntityModel) ? item.entityId : item;
        let url:string = EntityService.URL + EntityService.PATH + '/' + entityId.toString() + '/brokers/search?nameoremail=' + encodeURIComponent(query);
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (entity) => new EntityModel(entity));
        });
    }

    public searchGroups(item:EntityModel|number, query:string) : Observable<ResponseModel> {
        let entityId = (item instanceof EntityModel) ? item.entityId : item;
        let url:string = EntityService.URL + EntityService.PATH + '/' + entityId.toString() + '/groups/search?nameoremail=' + encodeURIComponent(query);
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (entity) => new EntityModel(entity));
        });
    }
    public searchContacts(item:EntityModel|number, query:string) : Observable<ResponseModel> {
        let entityId:number = 0; // Can Query against an empty entity

        if(item instanceof EntityModel) {
            entityId = item.entityId;
        }
        else if(typeof item === 'number') {
            entityId = item;
        }

        let url:string = EntityService.URL + EntityService.PATH + '/' + entityId.toString() + '/searchcontacts?nameoremail=' + encodeURIComponent(query);
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (entity) => new EntityModel(entity));
        });
    }

    public assignPartner(entity:AssignPartnerModel):Observable<ResponseModel>{
        let url:string = EntityService.URL + EntityService.PATH + '/assignpartner';
        let body:any = entity;
        let options:{} = {};

        return this.http.post(url, body, options, (response:HttpResponse<any>)=>{
            return new EntityModel(response['data']);
        });
    }

    /**
     * Post (create|update) the entity
     *
     * If entity.entityId null => create
     * If entity.entityId int  => update
     *
     * @param entity:EntityModel
     * @returns Observable<ResponseModel>
     */
    public postEntity(entity:EntityModel) : Observable<ResponseModel> {
        let url:string = EntityService.URL + EntityService.PATH;
        let body:any = entity;
        let options:{} = {};

        return this.http.post(url, body, options, (response:HttpResponse<any>) => {
            return new EntityModel(response['data']);
        });
    }

    public postWoolTypeMappings(entity:EntityModel, woolTypeMappings:WoolTypeMappingModel[]) : Observable<ResponseModel> {
        let url:string = EntityService.URL + EntityService.PATH + '/' + entity.entityId.toString() + '/wooltypemapping';
        let body:any = woolTypeMappings;
        let options:{} = {};

        return this.http.post(url, body, options);
    }

    public toggleActivate(entity:EntityModel) : Observable<ResponseModel> {
        let url:string = EntityService.URL + EntityService.PATH + '/' + entity.entityId.toString() + '/activate';
        let body:any = entity;
        let params = new HttpParams();
        params = params.append('isActive', entity.isActive.toString());

        let options = {
            params : params
        };

        return this.http.post(url, body, options, (response:HttpResponse<any>) => {
            return new EntityModel(response['data']);
        });
    }

    // Entity Logo
    public createLogoUploader(entity:EntityModel) : Function {
        return (file:File) => {
            let _entity = entity;

            return this.uploadLogo(_entity, file);
        };
    }

    public uploadLogo(entity:EntityModel, file:File) : Observable<HttpEvent<any>> {

        let url = EntityService.URL + EntityService.PATH;
        url += '/' + entity.entityId.toString() + '/upload_logo_to_business_profile';

        let formData = new FormData();
        formData.append('filePayload', file);

        let params = new HttpParams();

        const options = {
            params: params,
            reportProgress: true,
        };

        const req = new HttpRequest('POST', url, formData, options);
        return this.httpClientLegacy.request(req);
    }

    public createLogoDeleter(entity:EntityModel) : Function {
        return () => {
            let _entity = entity;

            return this.deleteLogo(_entity);
        };
    }

    public deleteLogo(entity:EntityModel) : Observable<ResponseModel> {
        let url:string = EntityService.URL + EntityService.PATH  + '/' + entity.entityId.toString() + '/delete_business_logo';
        let body:any = null;
        let options:{} = {};

        return this.http.post(url, body, options, (response:HttpResponse<any>) => {
            return new EntityModel(response['data']);
        });
    }

    // Entity Photos
    public createPhotosUploader(entity:EntityModel) : Function {
        return (file:File) => {
            let _entity = entity;

            return this.uploadPhotos(_entity, file);
        };
    }

    public uploadPhotos(entity:EntityModel, file:File) : Observable<HttpEvent<any>> {

        let formData = new FormData();
        formData.append('filePayload', file);

        let params = new HttpParams();

        let url = EntityService.URL + EntityService.PATH + '/' + entity.entityId.toString() + '/upload_photo_to_business_profile';
        let options = {
            params: params,
            reportProgress: true,
        };

        const req = new HttpRequest('POST', url, formData, options);
        return this.httpClientLegacy.request(req);
    }

    public createPhotosDeleter(entity:EntityModel) : Function {
        return () => {
            let _entity = entity;

            return this.deletePhotos(_entity);
        };
    }

    public deletePhotos(entity:EntityModel) : Observable<ResponseModel> {
        let url:string = EntityService.URL + EntityService.PATH + '/' + entity.entityId.toString() + '/delete_business_photo';
        let body:any = null;
        let options:{} = {};

        return this.http.post(url, body, options, (response:HttpResponse<any>) => {
            return new EntityModel(response['data']);
        });
    }

    // Entity Videos
    public createVideosUploader(entity:EntityModel) : Function {
        return (file:File) => {
            let _entity = entity;

            return this.uploadVideos(_entity, file);
        };
    }

    public uploadVideos(entity:EntityModel, file:File) : Observable<HttpEvent<any>> {
        let url = EntityService.URL + EntityService.PATH;
        url += '/' + entity.entityId.toString() + '/upload_video_to_business_profile';

        let formData = new FormData();
        formData.append('filePayload', file);

        let params = new HttpParams();

        const options = {
            params: params,
            reportProgress: true,
        };

        const req = new HttpRequest('POST', url, formData, options);
        return this.httpClientLegacy.request(req);
    }

    public createVideosDeleter(entity:EntityModel) : Function {
        return () => {
            let _entity = entity;

            return this.deleteVideos(_entity);
        };
    }

    public deleteVideos(entity:EntityModel) : Observable<ResponseModel> {
        let url:string = EntityService.URL + EntityService.PATH + '/' + entity.entityId.toString() + '/delete_business_video';
        let body:any = null;
        let options:{} = {};

        return this.http.post(url, body, options, (response:HttpResponse<any>) => {
            return new EntityModel(response['data']);
        });
    }

    public uploadGalleryPhotos(entity:EntityModel, file:File) : Observable<HttpEvent<any>> {
        let url = EntityService.URL + EntityService.PATH;
        url += '/' + entity.entityId.toString() + '/upload_image_to_gallery';

        let formData = new FormData();
        formData.append('filePayload', file);

        let params = new HttpParams();

        const options = {
            params: params,
            reportProgress: true,
        };

        const req = new HttpRequest('POST', url, formData, options);
        return this.httpClientLegacy.request(req);
    }

    public uploadGalleryVideo(entity:EntityModel, file:File) : Observable<HttpEvent<any>> {
        let url = EntityService.URL + EntityService.PATH;
        url += '/' + entity.entityId.toString() + '/upload_video_to_gallery';

        let formData = new FormData();
        formData.append('filePayload', file);

        let params = new HttpParams();

        const options = {
            params: params,
            reportProgress: true,
        };

        const req = new HttpRequest('POST', url, formData, options);
        return this.httpClientLegacy.request(req);
    }

    public deleteGalleryPhotos(entityImageId:number) : Observable<ResponseModel> {
        let url:string = EntityService.URL + EntityService.PATH + '/delete_image_from_gallery?entityImageId=' + entityImageId;
        let body:any = null;
        let options:{} = {};

        return this.http.post(url, body, options, (response:HttpResponse<any>) => {
            return new EntityModel(response['data']);
        });
    }

    public deleteGalleryVideo(entityVideoId:number) : Observable<ResponseModel> {
        let url:string = EntityService.URL + EntityService.PATH + '/delete_video_from_gallery?entityVideoId=' + entityVideoId;
        let body:any = null;
        let options:{} = {};

        return this.http.post(url, body, options, (response:HttpResponse<any>) => {
            return new EntityModel(response['data']);
        });
    }

    // Ftp Connection
    public testFtpConnection(ftpConnection:FtpConnectionModel) : Observable<ResponseModel> {
        let url:string = EntityService.URL + '/util/ftptest';
        let body:any = ftpConnection;
        let options:{} = {};

        return this.http.post(url, body, options);
    }

    public SendBrokerToken(entityId:number) : Observable<ResponseModel> {
        let url:string = EntityService.URL + EntityService.PATH + '/' + entityId.toString() + '/brokertoken';
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return response['data'];
        });
    }

    public TraceabilitySummary(item:EntityModel|number) : Observable<ResponseModel> {
        let entityId = (item instanceof EntityModel) ? item.entityId : item;
        let url:string = EntityService.URL + '/traceability/' + entityId.toString() + '/summary';
        let options:{} = {};
        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return response;
        });
    }
}
