import { Component, ContentChildren, QueryList, Input, Output, EventEmitter, ElementRef, TemplateRef, ViewContainerRef,
     KeyValueDiffers, KeyValueDiffer, ChangeDetectorRef, AfterViewInit, ViewChild, HostBinding, OnDestroy, ViewRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, Validator } from '@angular/forms';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { OptionComponent } from './option/option';
import { Overlay, OverlayRef, OverlayConfig } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { isEqual as _isEqual, toPlainObject as _toPlainObject, isNil as _isNil } from 'lodash';

import { AbstractThemeableComponent } from '~/components/abstractThemable/abstractThemeable';
import { ComponentThemes } from '~/components/abstractThemable/abstractThemeable';

export enum Variants {
    LINE = 'line',
    BLUE = 'blue',
    STANDARD = 'standard',
}

@Component({
    selector: 'wep-select',
    templateUrl: './select.html',
    host: {'class': 'wep-select'},
    styleUrls: ['./select.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: SelectComponent,
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: SelectComponent,
            multi: true,
        }],
    animations: [
        trigger('selectBarAnimation', [
            state('active', style({height: '*', padding: '10px 0'})),
            state('*', style({height: '0', padding: '0'})),
            transition('active => *', animate('300ms ease-in-out')),
            transition('* => active', animate('300ms ease-in-out'))
        ]),
        trigger('selectArrowAnimation', [
            state('active', style({ transform: 'rotate(-180deg)'})),
            state('*', style({ transform: 'rotate(0deg)' })),
            transition('active => *', [style({transform: 'rotate(180deg)'}), animate('300ms ease-in')]),
            transition('* => active', [style({transform: 'rotate(-360deg)'}), animate('300ms ease-out')])
        ])
    ]
})

export class SelectComponent extends AbstractThemeableComponent implements ControlValueAccessor, Validator, AfterViewInit, OnDestroy {

    // Styles

    @Input()
    public variant:string = '';

    @HostBinding('class.wep-select--theme--dark')
    public get isDarkTheme() {
        return this.theme === ComponentThemes.DARK.toString();
    }

    @HostBinding('class.wep-select--theme--light')
    public get isLightTheme() {
        return this.theme === ComponentThemes.LIGHT.toString();
    }

    @HostBinding('class.wep-select--variant--line')
    public get isVariantLine() : boolean {
        return this.variant === Variants.LINE;
    }

    @HostBinding('class.wep-select--variant--blue')
    public get isVariantBlue() : boolean {
        return this.variant === Variants.BLUE;
    }

    // Settings

    @ContentChildren(OptionComponent)
    public options:QueryList<OptionComponent>;

    @Input()
    public placeholder:string;

    @Input()
    public disabled:boolean;

    @Input('aria-label')
    public ariaLabel:string = 'Select';

    @Output()
    public focused = new EventEmitter<any>();

    @Output()
    public changed = new EventEmitter<any>();

    @Output()
    public blurred = new EventEmitter<any>();


    private onTouched = () => {};
    private propagateChange = (_: any) => { };

    public selectedOption:OptionComponent;
    private displayBar: boolean = false;

    @Input('selected')
    public value:any;

    private keyValueDiffer:KeyValueDiffer<any, any>;
    private changeDetectorRef:ChangeDetectorRef;
    private nativeElement:any;

    //Select Bar and Overlay
    private viewContainerRef:ViewContainerRef;
    private overlay:Overlay;
    private overlayRef:OverlayRef;

    @ViewChild('selectBarTemplate',{static: false})
    public selectBarTemplate:TemplateRef<any>;

    @ViewChild('selectButton',{static: false})
    public selectButton:ElementRef;

    @ViewChild('selectBar',{static: false})
    public selectBar:ElementRef;

    ///Animation delay for toggling the selectBar
    private timeoutDelay:number = 300;
    private isAnimating:boolean = false;

    constructor(keyValueDiffers: KeyValueDiffers,
                changeDetectorRef:ChangeDetectorRef,
                viewContainerRef:ViewContainerRef,
                elementRef:ElementRef,
                overlay:Overlay) {
        super();

        this.keyValueDiffer = keyValueDiffers.find({}).create();
        this.changeDetectorRef = changeDetectorRef;
        this.viewContainerRef = viewContainerRef;
        this.nativeElement = elementRef.nativeElement;

        this.overlay = overlay;
    }

    public writeValue(data: any) {
        if(this.value !== data && !_isNil(data)) {
            setTimeout(() => {
                if (this.options) {
                    this.options.forEach((option) => {
                        //Unset values
                        option.selected = false;

                        let isSameString = (option.value === data);

                        let isSameObj = false;
                        if (data instanceof Object && option.value instanceof Object) {
                            let plainData = _toPlainObject(data);
                            let plainValue = _toPlainObject(option.value);
                            isSameObj = _isEqual(plainData, plainValue);
                        }

                        if (isSameString || isSameObj) {

                            //Set current select option
                            this.selectedOption = option;
                            this.selectedOption.selected = true;

                            //Set the value for this component
                            this.value = data;

                            if(!(this.changeDetectorRef as ViewRef).destroyed) {
                                this.changeDetectorRef.detectChanges()
                            }
                            return;
                        }
                    });
                }
            }, 100);
        }
        else {
            this.value = !_isNil(data) ? data : null;
        }
    }

    public registerOnChange(fn: any) : void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public validate() : any {
        return null;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public ngAfterViewInit() : void {
        let changeDiff = this.keyValueDiffer.diff(this.value);

        if(changeDiff) {
            this.options.forEach((option) => {
                //Unset values
                option.selected = false;

                if(option.value === this.value) {

                    //Set current select option
                    this.selectedOption = option;
                    this.selectedOption.selected = true;

                    //Set the value for this component
                    this.value = option.value;

                    if(!(this.changeDetectorRef as ViewRef).destroyed) {
                        this.changeDetectorRef.detectChanges()
                    }
                    return;
                }
            });
        }
    }

    public onFocus() : void {
        this.focused.emit(true);
    }

    /**
     * Creates the Overlay and return the OverlayRef
     * @returns {OverlayRef}
     */
    public createOverlay() : OverlayRef {

        // Returns an OverlayRef which is a PortalHost
        let overlayRef = this.overlay.create(this.getOverlayConfig());
        let tempaltePortal = new TemplatePortal(this.selectBarTemplate, this.viewContainerRef);

        overlayRef.attach(tempaltePortal);

        //Close event
        overlayRef.backdropClick().subscribe(() => {
            this.toggle();
        });

        return overlayRef;
    }

    /**
     * Select Overlay customisations
     */
    private getOverlayConfig() : OverlayConfig {
        let overlayConfig = new OverlayConfig();

        let selectButtonClientRect = this.selectButton.nativeElement.getBoundingClientRect();
        overlayConfig.width = selectButtonClientRect.width;

        overlayConfig.scrollStrategy = this.overlay.scrollStrategies.block();
        overlayConfig.hasBackdrop = true;
        overlayConfig.backdropClass = 'cdk-overlay-transparent-backdrop';

        overlayConfig.positionStrategy = this.overlay.position().connectedTo(
            this.selectButton,
            {originX: 'start', originY: 'bottom'},
            {overlayX: 'start', overlayY: 'top'});

        return overlayConfig;
    }

    /**
     * Keydown event for clicking the selec tbutton
     * @param event
     */
    public selectButtonKeydown(event) : void {
        //Enter, Tab, Up and Down
        if(event.which === 13 ||
            event.which === 9 ||
            event.which === 40 ||
            event.which === 38) {
            event.preventDefault();
            this.toggle();
        }
    }

    /**
     * Sets the selected option on keydown
     * @param event
     * @param selectedOption
     */
    public selectOptionKeydown(event, selectedOption:OptionComponent) {
        if(event.which === 13) {
            event.preventDefault();
            this.setSelectedOption(selectedOption);
        }

        if(event.which === 9) {
            event.preventDefault();
            this.toggle();
        }

        //Go to previous or next item
        if(event.which === 38) {
            event.preventDefault();
            if(event.currentTarget.previousElementSibling) {
                event.currentTarget.previousElementSibling.focus();
            }
        }
        if(event.which === 40) {
            event.preventDefault();
            if(event.currentTarget.nextElementSibling) {
                event.currentTarget.nextElementSibling.focus();
            }
        }
    }

    /**
     * Select box empty keydown event
     * @param event
     */
    public selectOptionEmptyKeydown(event) : void {
        if(event.which === 13 ||
            event.which === 9 ||
            event.which === 38 ||
            event.which === 40) {
            this.toggle();
        }
    }

    /**
     * Toggles the select bar
     */
    public toggle() : void {

        //Is the select bar not animating, otherwise do nothing
        if(!this.isAnimating) {
            this.isAnimating = true;
            if(!this.disabled) {
                //Toggles the state of the select bar
                this.displayBar = !this.displayBar;

                //Open the select bar
                if(this.displayBar) {
                    this.overlayRef = this.createOverlay();

                    setTimeout(() => {
                        this.focusToSelectedOption(this.selectedOption);
                        this.isAnimating = false;
                    }, this.timeoutDelay);
                }
                //Close the select bar
                else {
                    if(this.overlayRef) {
                        setTimeout(() => {
                            this.overlayRef.dispose();
                            this.isAnimating = false;
                            this.blurred.emit();
                            this.onTouched();
                        }, this.timeoutDelay);
                    }
                }
            }
            else {
                this.displayBar = false;

                //Close disabled select bar
                if(this.overlayRef) {
                    setTimeout(() => {
                        this.overlayRef.dispose();
                        this.isAnimating = false;
                        this.blurred.emit();
                        this.onTouched();

                    }, this.timeoutDelay);
                }
            }
        }
    }

    /**
     * Sets the selected option
     * @param selectedOption
     */
    public setSelectedOption(selectedOption:OptionComponent) : void {

        this.options.forEach((option) => {
            option.selected = false;
        });

        //Set current select option
        this.selectedOption = selectedOption;
        this.selectedOption.selected = true;

        //Set the value for this component
        this.value = selectedOption.value;

        //Propogate the change
        this.propagateChange(this.value);
        this.onTouched();

        //Toggle then emit the value
        this.toggle();
        setTimeout(() => {
            this.changed.emit(this.value);
            this.blurred.emit();
            //Trigger the option clicked event, if any
            if(this.selectedOption.clicked) {
                this.selectedOption.clicked.emit(true);
            }
        }, this.timeoutDelay);
    }

    /**
     * Retreives the animation string state
     * @returns string
     */
    public getAnimationState() : string {
        return this.displayBar ? 'active' : '';
    }

    /**
     * Focuses on the selected option.
     * @param selectedOption
     */
    public focusToSelectedOption(selectedOption:OptionComponent) : void {
        let selectedOptionElement:HTMLElement;
        let found = false;

        this.options.forEach((option, index) => {

            //Focus on the selected option
            if(option === selectedOption) {
                let cssClass = '.wep-select__option--' + index;
                selectedOptionElement = this.selectBar.nativeElement.querySelector(cssClass);
                selectedOptionElement.focus();
                found = true;
                return;
            }
        });
        //Focus on the first selected option
        if(!found && this.options.length > 1) {
            selectedOptionElement = this.selectBar.nativeElement.querySelector('.wep-select__option--0');
            selectedOptionElement.focus();
        }
    }

    public isValueEmpty() {
        return _isNil(this.value);
    }

    public ngOnDestroy() {
        this.changeDetectorRef.detach();
    }
}
