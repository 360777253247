import { Injectable } from '@angular/core';
import { HttpResponse, HttpParams } from '@angular/common/http';
import { AuctionCatalogAbstractService } from '~/services/api/web/auctionCatalog/auctionCatalogAbstractService';
import { BaseWebService } from '~/services/api/web/base/baseWebService';
import { Observable, of } from 'rxjs';
import { map as _map, filter as _filter, isNil as _isNil } from 'lodash';
import { map } from 'rxjs/operators';

// Models
import { AuctionCatalogEditModel } from '~/models/auctionCatalogEditModel';
import { AuctionCatalogModel, AuctionCatalogModelStatuses } from '~/models/auctionCatalogModel';
import { AuctionCountdownModel } from '~/models/auctionCountdownModel';
import { EntityModel } from '~/models/entityModel';
import { MarketSummaryModel } from '~/models/marketSummaryModel';
import { ResponseModel, ResponseModelCode } from '~/models/responseModel';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import * as moment from 'moment';
import { TenderCatalogModel, TenderCatalogModelStatuses } from '~/models/tenderCatalogModel';
import { TenderCatalogEditModel } from '~/models/tenderCatalogEditModel';
import { TenderOfferModel } from '~/models/tenderOfferModel';
import { BidInputModel } from '~/models/bidInputModel';
import { OfferService } from '../offer/offerService';
import { OrderBookServerPaginationModel } from '~/models/serverPagination/orderBookServerPaginationModel';
import { TenderOfferBidderModel } from '~/models/tenderOfferBidderModel';

enum TenderOfferIntStatus
{
    Draft = 1,
    Open = 2,
    Closed = 9
}


@Injectable()
export class TenderOfferService extends BaseWebService {

    public static readonly PATH:string = '/tenderOffers';

    public getLiveOffer(id:number) : Observable<ResponseModel> {
        let url:string = TenderOfferService.URL + TenderOfferService.PATH + '/open';
        let params = new HttpParams();

        if(!_isNil(id)) {
            params = params.append('catId', id.toString());
        }


        let options = {
            params : params
        };

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (data) => new TenderOfferModel(data));
        });
    }
    public getBrokerTenderOffers(savedSearchId:number = null, page:number = null, itemsPerPage:number = null, search:string = '', date:moment.Moment = null, status:string = '', accountName:string = '', catalogueId:number = null) : Observable<ResponseModel> {
        let url:string = TenderOfferService.URL + TenderOfferService.PATH + '/broker';
        let params = new HttpParams();

        if(!_isNil(catalogueId)) {
            params = params.append('catId', catalogueId.toString());
        }

        let options = {
            params : this.getOptionalParam(savedSearchId,page,itemsPerPage,search,date,status,accountName, catalogueId)
        };

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return new OrderBookServerPaginationModel<TenderOfferModel>(TenderOfferModel, response['data']);
        });
    }

    public getTenderOffers(savedSearchId:number = null, page:number = null, itemsPerPage:number = null, search:string = '', date:moment.Moment = null, status:string = '', accountName:string = '', catalogueId:number = null) : Observable<ResponseModel> {
        let url:string = TenderOfferService.URL + TenderOfferService.PATH + '/buyer';
        let params = new HttpParams();

        let options = {
            params : this.getOptionalParam(savedSearchId,page,itemsPerPage,search,date,status,accountName, catalogueId)
        };

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return new OrderBookServerPaginationModel<TenderOfferModel>(TenderOfferModel, response['data']);
        });
    }

    public getBidderList(offerId:number) : Observable<ResponseModel> {
        let url:string = TenderOfferService.URL + TenderOfferService.PATH + '/' + offerId.toString() + '/bidhistories';
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (data) => new TenderOfferBidderModel(data));
        });
    }

    public sellOfferToBuyer(offerId:number, bid:TenderOfferBidderModel) : Observable<ResponseModel> {
        let url:string = TenderOfferService.URL + TenderOfferService.PATH + '/' + offerId.toString() + '/acceptbuyer';
        let body:any = bid;
        let options:{} = {};

        return this.http.post(url, body, options);
    }
    public withdrawOffer(offerId:number) : Observable<ResponseModel> {
        let url:string = TenderOfferService.URL + TenderOfferService.PATH + '/' + offerId.toString() + '/soldwithdraw';
        let body:any = {};
        let options:{} = {};

        return this.http.post(url, body, options);
    }

    public postBid(offer:any, inputBid:BidInputModel) : Observable<ResponseModel> {
        let url:string = TenderOfferService.URL + TenderOfferService.PATH + '/' + offer.id.toString() + '/bid';
        let body:any = inputBid;
        let options:{} = {};

        return this.http.post(url, body, options);
    }
    public acceptBid(id:number) : Observable<ResponseModel> {
        let url:string = TenderOfferService.URL + TenderOfferService.PATH + '/' + id.toString() + '/accept';
        let body:any = {};
        let options:{} = {};

        return this.http.post(url, body, options);
    }
    public declineBid(id:number) : Observable<ResponseModel> {
        let url:string = TenderOfferService.URL + TenderOfferService.PATH + '/' + id.toString() + '/decline';
        let body:any = {};
        let options:{} = {};

        return this.http.post(url, body, options);
    }

    public postBida(offer:any, inputBid:BidInputModel) : Observable<ResponseModel> {
        let url:string = OfferService.URL + OfferService.PATH + '/' + offer.id.toString() + '/bid';
        let body:any = inputBid;
        let options:{} = {};

        return this.http.post(url, body, options);
    }

    public exportTenderOfferPDF(catalogId:string) : Observable<ResponseModel> {
        let url:string = TenderOfferService.URL + TenderOfferService.PATH + '/pdf';

        let params = new HttpParams();
        params = params.append('catalogId', catalogId);

        let options:{} = {
            params : params,
            responseType: 'blob' as 'blob'
        };

        return this.http.get(url, options, (response:HttpResponse<any>) => {
             return response;
        });
    }

    public exportTenderBrokerOfferPDF(catalogId:string) : Observable<ResponseModel> {
        let url:string = TenderOfferService.URL + TenderOfferService.PATH + '/broker/pdf';

        let params = new HttpParams();
        params = params.append('catalogId', catalogId);

        let options:{} = {
            params : params,
            responseType: 'blob' as 'blob'
        };

        return this.http.get(url, options, (response:HttpResponse<any>) => {
             return response;
        });
    }

    private getOptionalParam(savedSearchId:number = null, page:number = null, itemsPerPage:number = null, search:string = '', date:moment.Moment = null, status:string = '', accountName:string = '', catalogueId:number = null) : HttpParams {
        let params = new HttpParams();

                if(!_isNil(savedSearchId)) {
                    params = params.append('savedSearchId', savedSearchId.toString());
                }
                if(!_isNil(page)) {
                    params = params.append('page', page.toString());
                }
                if(!_isNil(itemsPerPage)) {
                    params = params.append('pageSize', itemsPerPage.toString());
                }
                if(!_isNil(search)) {
                    params = params.append('search', search.toString());
                }
                if(!_isNil(date)) {
                    params = params.append('date', date.format('YYYY-MM-DD'));
                }
                if(!_isNil(status)) {
                    params = params.append('status', status);
                }
                if(!_isNil(accountName)) {
                    params = params.append('accountName', accountName);
                }
                if(!_isNil(catalogueId)) {
                    params = params.append('catalogueId', catalogueId.toString());
                }
        return params;
    }
}
