<wep-image class="wep-avatar__image"
           *ngIf="!displayFallback"
           [src]="src"
           [alt]="alt"
           [maxTicks]="maxTicks"
           (load)="displayFallback = false"
           (error)="onError()"
           [objectFitContain]="objectFitContain">
</wep-image>
<div class="wep-avatar__image wep-avatar__image--fallback" *ngIf="displayFallback && shouldDisplayFallback">
    <div class="wep-avatar__image--fallback__text"
          [hidden]="!fallbackText"
          [ngStyle]="getStyle()">
        {{fallbackText}}
    </div>
</div>