import { Injectable } from '@angular/core';
import { LiteEntityAbstractService } from '~/services/api/web/liteEntity/liteEntityAbstractService';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { HttpClientWrapper } from '~/services/api/httpClientWrapper';
import { BaseWebService } from '~/services/api/web/base/baseWebService';
import { ResponseModel } from '~/models/responseModel';
import { Observable } from 'rxjs';
import { EntityModel } from '~/models/entityModel';
import { map as _map } from 'lodash';

@Injectable()
export class LiteEntityService extends BaseWebService implements LiteEntityAbstractService {

    public static readonly PATH:string = '/liteentities';

    private httpClientLegacy:HttpClient;

    constructor(http:HttpClientWrapper, httpClientLegacy:HttpClient) {
        super(http);
        this.httpClientLegacy = httpClientLegacy;
    }

    public getAll(search:string) : Observable<ResponseModel> {
        let url:string = LiteEntityService.URL + LiteEntityService.PATH + '?keyword=' + search;
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (entity) => new EntityModel(entity));
        });
    }
}