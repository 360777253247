import { Component, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NetworkStatusService } from '~/services/util/networkStatusService';
import { LocalService } from '~/services/storage/local/localService';
import { SegmentService } from '~/services/segment/segmentService';
import { UserModel } from '~/models/userModel';
import { AdminTemplateService } from './services/adminTemplateService';
import { IntercomService } from '~/services/intercom/intercomService';
import { takeWhile } from 'rxjs/operators';
import { ConversationsService } from '~/templates/admin/views/conversations/services/conversationsService';
import { MediaQueryService } from '~/services/util/mediaQueryService';
import { OffCanvasService } from '~/templates/admin/components/offCanvas/services/offCanvasService';
import { OffCanvasComponent, offCanvasAnimations } from '~/templates/admin/components/offCanvas/offCanvas';
import { SmoothScrollService } from '~/services/util/smoothScrollService';
import 'foundation-sites';

@Component({
    selector: 'wep-tpl-admin',
    templateUrl: './admin.html',
    host: {'class': 'tpl tpl--admin'},
    styleUrls: ['./admin.scss'],
    providers: [
        AdminTemplateService,
        ConversationsService,
        OffCanvasService,
        SmoothScrollService
    ],
    animations: [
        offCanvasAnimations
    ]
})

export class AdminTemplateComponent extends OffCanvasComponent implements AfterViewInit {

    private networkStatus:NetworkStatusService;
    private localService:LocalService;
    private segmentService:SegmentService;
    private router:Router;
    private activatedRoute:ActivatedRoute;
    private adminTemplateService:AdminTemplateService;
    private isPageLoaded:boolean;
    private intercomService:IntercomService;
    private smoothScrollService:SmoothScrollService;

    public static scrollContainerId:string = 'page-tpl__main'; //HTMLElement id which is inside all /admin pageTemplates
    public static scrollProperties = {
        containerId: AdminTemplateComponent.scrollContainerId,
    };

    public hideSharedHeading:boolean = false;

    constructor(mediaQueryService:MediaQueryService,
                offCanvasService:OffCanvasService,
                networkStatus:NetworkStatusService,
                localService:LocalService,
                segmentService:SegmentService,
                router:Router,
                activatedRoute:ActivatedRoute,
                adminTemplateService:AdminTemplateService,
                intercomService:IntercomService,
                changeDetectorRef:ChangeDetectorRef,
                smoothScrollService:SmoothScrollService) {
        super(offCanvasService, mediaQueryService, changeDetectorRef);

        this.networkStatus = networkStatus;
        this.localService = localService;
        this.segmentService = segmentService;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.adminTemplateService = adminTemplateService;
        this.intercomService = intercomService;
        this.smoothScrollService = smoothScrollService;

        /**
         * Putting scrollContainerId at the admin level is reasonable as all the page templates contain the 'page-tpl__main' HTMLElement.
         * Assuming the scrollContainerId changes between page templates,
         * these properties can be instantiated in their respective pageTemplateComponents instead.
         */
        this.smoothScrollService.setScrollProperties(AdminTemplateComponent.scrollProperties);

        this.loadServices();
    }

    /**
     * Call the Segment Service and the Intercom Service
     */
    private loadServices() : void {
        this.adminTemplateService.getUser().pipe(
            takeWhile(() => {
                return !this.isPageLoaded;
            })
        ).subscribe((user:UserModel) => {
            if(user) {
                this.activatedRoute.url.subscribe(() => {
                    //Segment
                    this.segmentService.page({
                        userId: user.email,
                        properties: {
                            url: window.location.href,
                            path: this.router.url,
                            title: 'Wool Exchange Portal',
                            referrer: window.location.href
                        }
                    });
                    this.isPageLoaded = true;
                });

                //Intercom
                this.intercomService.boot({
                    name:user.getFullName(),
                    email:user.email,
                    user_id:user.contactId
                });
            }
        });
    }

    public ngAfterViewInit() : void {
        $(document).foundation();
        super.ngAfterViewInit();
    }
}