import { Component, OnChanges, Input, SimpleChange } from '@angular/core';
import { AvatarComponent } from '~/components/avatar/avatar';
import { UserModel } from '~/models/userModel';

@Component({
    selector: 'wep-avatar-user',
    templateUrl: './../avatar.html',
    host: {'class': 'wep-avatar'},
    styleUrls: ['./../avatar.scss'],
})

export class AvatarUserComponent extends AvatarComponent implements OnChanges {

    @Input()
    public user:UserModel;

    public ngOnChanges(changes: {[propertyName: string]: SimpleChange}) : void {
        if (changes['user']) {
            if(this.user) {
                this.src = this.user.profilePictureURL;
                this.alt = this.user.getLabel();
                this.displayFallback = false;

                if((this.user.firstName && this.user.firstName.length > 0) || (this.user.lastName && this.user.lastName.length > 0)) {
                    this.fallbackText = (this.user.firstName) ? this.user.firstName[0] : '' + (this.user.lastName) ? this.user.lastName[0] : '';
                }
            }
        }
    }
}
