<div mat-dialog-title class="dialog__actions dialog__actions--top clearfix">
    <wep-button class="dialog__actions--top__button" (clicked)="close()">&times;</wep-button>
</div>
<mat-dialog-content id="dialog__content" class="dialog__content">
    <div class="dialog__content__inner">
        <h2 class="dialog__title">
            {{ data.title }}
        </h2>
        <div [ngClass]= "{ 'text-center' : data.isTextCentered }">
            {{ data.text }}
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="dialog__actions dialog__actions--bottom">
    <div class="dialog__actions--bottom__inner">
        <wep-button [expanded]="true" (clicked)="dialogRef.close(data)">{{data.closeText}}</wep-button>
    </div>
</mat-dialog-actions>