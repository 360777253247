import { RouterModule, Routes } from '@angular/router';

/**
 * To apply a templateComponent, match the path with the component… '/admin', '/login'
 * To apply a pageComponent, match the next path… '', '/dashboard'…
 *
 * An example of applying a block template and page component
 * {
 *     path: 'block',
 *     component: BlockTemplateComponent
 *     children: [
 *         path: '',
 *         component: BlockPageComponent
 *     ]
 * }
 */
const routes: Routes = [
    {
        path: 'login',
        loadChildren: () => import('src/app/pages/login/modules/loginPagesModule').then(m => m.LoginPagesModule)
    },
    {
        path: 'admin',
        data: { breadcrumb: 'Home' },
        loadChildren: () => import('src/app/pages/admin/modules/adminPagesModule').then(m => m.AdminPagesModule)
    },
    {
        path: '',
        loadChildren: () => import('src/app/pages/static/modules/staticPagesModule').then(m => m.StaticPagesModule)
    }
];

//Adds sandbox routing
if(process.env['APP_DEPLOYMENT']['isDev'] ||
   process.env['APP_DEPLOYMENT']['isQa'] ||
   process.env['APP_DEPLOYMENT']['isStaging'] ||
   process.env['APP_DEPLOYMENT']['isDemo']) {
    routes.push.apply(routes, [{
        path: 'sandbox',
        loadChildren: () => import('src/app/templates/sandbox/modules/sandboxTemplateModule').then(m => m.SandboxTemplateModule)
    }]);
}

export const appRouting = RouterModule.forRoot(routes);
