import { BaseModel } from './baseModel';

export class InventoryOrderLotModel extends BaseModel{
	public balesCount: number;
	public balesGross: number;
	public balesTare: number; 
	public certificateIdentity: string;
	public clientCrossReference: string;
	public dateDelivery: string;
	public datePickup: string;
	public lotId: number;
	public lotReference: string;
	public lotOrderNumber: string;
	public sellingOrganisation: string;
	public saleIdentity: string;

    constructor(values: Object = {}) {
		super(values);
    }
}