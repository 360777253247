import { BaseModel } from '~/models/baseModel';

export class PriceBoxModel extends BaseModel {

    public label:string;
    public price:string;
    public variation:string;
    public color:string;

    get isPositive() : boolean {
        return 0 < parseInt(this.variation, 10);
    }
}
