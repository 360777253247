import { Injectable } from '@angular/core';
import { NotificationAbstractService } from '~/services/api/web/notification/notificationAbstractService';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { HttpClientWrapper } from '~/services/api/httpClientWrapper';
import { BaseWebService } from '~/services/api/web/base/baseWebService';
import { ResponseModel } from '~/models/responseModel';
import { NotificationModel } from '~/models/notificationModel';
import { Observable } from 'rxjs';
import { map as _map } from 'lodash';

@Injectable()
export class NotificationService extends BaseWebService implements NotificationAbstractService {

    public static readonly PATH:string = '/notifications';

    constructor(http: HttpClientWrapper) {
        super(http);
    }

    public getNotifications() : Observable<ResponseModel> {
        let url:string = NotificationService.URL + NotificationService.PATH;
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (notification) => new NotificationModel(notification) );
        });
    }

    public sendLastReadNotification(notification:NotificationModel) : Observable<ResponseModel> {
        let url:string = NotificationService.URL + NotificationService.PATH + '/read';

        let params = new HttpParams();
        params = params.append('lastId', notification.notificationId.toString());

        let body:any = null;
        let options:{} = {
            params : params
        };

        return this.http.post(url, body, options);
    }
}
