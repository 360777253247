import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { FormBuilder } from '@angular/forms';
import { AgGridCellEditorAbstractComponent, AgGridCellEditorParams } from '~/components/agGrid/cellEditors/abstract/abstract';

export interface AgGridCellEditorDateParams extends AgGridCellEditorParams {
    datepickerSettings?: {
        min:Date;
    };
}

@Component({
    selector: 'wep-ag-grid-cell-editor-datepicker',
    templateUrl: './datepicker.html',
    host: { 'class' : 'wep-ag-grid-cell-editor-datepicker' },
    styleUrls: ['./datepicker.scss']
})

export class AgGridCellEditorDatePickerComponent extends AgGridCellEditorAbstractComponent implements AfterViewInit {

    @ViewChild('datePicker',{static: false})
    public datePicker:MatDatepicker<any>;

    constructor(formBuilder:FormBuilder) {
        super(formBuilder);
    }

    public agInit(params: any) : void {
        super.agInit(params);
        this.params = params;
    }

    public onDatepickerClosed() : void {
        this.params.api.stopEditing(false);
    }

    public ngAfterViewInit() : void {
        //Implies that the datepicker is clicked
    }
}