import { Injectable, Injector } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { UserModel } from '~/models/userModel';
import { UserPreferenceLocalService } from '~/services/api/web/userPreference/userPreferenceLocalService';
import { UserPreferenceKeyNames } from '~/services/api/web/userPreference/userPreferenceLocalService';
import { SnackBarService } from '~/components/snackBar/services/snackBarService';
import { UserPreferenceModel } from '~/models/userPreferenceModel';
import { isNil as _isNil } from 'lodash';
import { AuthService } from '~/services/api/auth/authService';

@Injectable()
export class ConversationsService {
    private selectedConversationUserSubject:BehaviorSubject<UserModel> = new BehaviorSubject<UserModel>(null);
    private userPreferenceLocalService:UserPreferenceLocalService;
    private snackBarService:SnackBarService;
    private authService:AuthService

    constructor(userPreferenceLocalService:UserPreferenceLocalService, snackBarService:SnackBarService, injector:Injector) {
        this.userPreferenceLocalService = userPreferenceLocalService;
        this.snackBarService = snackBarService;
        this.authService = injector.get(AuthService);
        this.authService.hasValidAuthToken().then((isValid) => {
            if (isValid){
                this.loadConversationUsers();
            }
        })
    }

    public loadConversationUsers() : void {
        this.userPreferenceLocalService.getObjectValue(UserPreferenceKeyNames.App.Templates.Admin.Conversations.SelectedConversationUser)
            .subscribe((userPreference:UserPreferenceModel<Object>) => {
                if(!_isNil(userPreference) && !_isNil(userPreference.value)) {
                    this.setSelectedConversationUser(new UserModel(userPreference.value));
                }
            }
        );
    }

    public setSelectedConversationUser(user:UserModel) : void {
        if(user && user.contactId) {
            //Use a plain object for this request
            let newUser:{} = {
                contactId: user.contactId,
                firstName: user.firstName,
                lastName: user.lastName,
                profilePictureURL: user.profilePictureURL
            };

            this.userPreferenceLocalService.postObjectValue(UserPreferenceKeyNames.App.Templates.Admin.Conversations.SelectedConversationUser, newUser)
                .subscribe((userPreference:UserPreferenceModel<Object>) => {
                    if(!_isNil(userPreference) && !_isNil(userPreference.value)) {
                        let userFromPreferenceObject = new UserModel(userPreference.value);

                        if(userFromPreferenceObject && userFromPreferenceObject.contactId) {
                            this.selectedConversationUserSubject.next(userFromPreferenceObject);
                        }
                        else {
                            this.snackBarService.openAsError('Unable to open a conversation popup with the user.');
                        }
                    }
                }
            );
        }
    }

    public getConversationUser() : Observable<UserModel> {
        return this.selectedConversationUserSubject.asObservable();
    }

    public removeConversationUser() : void {
        this.selectedConversationUserSubject.next(null);
        this.userPreferenceLocalService.deleteValue(UserPreferenceKeyNames.App.Templates.Admin.Conversations.SelectedConversationUser).subscribe();
    }
}