import { BaseModel } from '~/models/baseModel';
import { QualitySchemeModel } from '~/models/qualitySchemeModel';

export class WoolGrowerExtensionModel extends BaseModel {
    public awiShareholderID:string;
    public qualitySchemas:string;

    public hasValues() : boolean {
        let hasValues = (typeof this.awiShareholderID !== 'undefined' && this.awiShareholderID.length > 0);

        return hasValues;
    }

    get qualityScheme() {
        return new QualitySchemeModel(this);
    }
}

