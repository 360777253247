import { Directive, ElementRef, Renderer2, OnInit, AfterViewInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { MaskedInputDirective } from 'angular2-text-mask';

interface BaseMaskSettings {
}

export interface NumberMaskSettings extends BaseMaskSettings {
    prefix?:string;
    allowDecimal?:boolean;
    includeThousandsSeparator?:boolean;
    thousandsSeparatorSymbol?:string;
    decimalLimit?:number;
    suffix?:string;
    allowNegative?:boolean;
    returnAsString?:boolean;
    allowLeadingZeroes?: boolean;
}

export interface TimeMaskSettings extends BaseMaskSettings {
    dateFormat:string;
}

@Directive({
    host: {
        '(input)': '_handleInput($event.target.value)',
        '(blur)': 'onTouched()'
    },
    selector: '[wepBaseMask]'
})

export class BaseMaskDirective extends MaskedInputDirective implements OnInit, AfterViewInit {

    protected model:NgControl;
    protected elementRef:ElementRef;
    protected defaultMaskSettings:BaseMaskSettings;
    protected settings:BaseMaskSettings = {};

    constructor(renderer2:Renderer2,
                elementRef:ElementRef,
                ngControl:NgControl) {
        super(renderer2, elementRef, false);

        this.elementRef = elementRef;
        this.model = ngControl;
    }

    public ngOnInit() { }
    public ngAfterViewInit() { }
    public updateMaskConfig() { }
}
