import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AgGridCellEditorInputComponent } from '~/components/agGrid/cellEditors/input/input';

/**
 * A Cell Editor with a wepNumberMask
 */
@Component({
    selector: 'wep-ag-grid-cell-editor-input-number',
    templateUrl: './inputNumber.html',
    host: { 'class' : 'wep-ag-grid-cell-editor-input wep-ag-grid-cell-editor-input-number' },
    styleUrls: ['./../input.scss']
})

export class AgGridCellEditorInputNumberComponent extends AgGridCellEditorInputComponent {

    constructor(formBuilder:FormBuilder) {
        super(formBuilder);
    }
}