import { Injectable } from '@angular/core';
import { SpecieService } from '~/services/api/web/specie/specieService';
import { ResponseModel } from '~/models/responseModel';
import { BrandService } from '~/services/api/web/brand/brandService';
import { ClipService } from '~/services/api/web/clip/clipService';
import { MobService } from '~/services/api/web/mobs/mobService';
import { BaleService } from '~/services/api/web/bale/baleService';
import { LineService } from '~/services/api/web/line/lineService';
import { ClipModel } from '~/models/clipModel';
import { Observable, forkJoin } from 'rxjs';

@Injectable()
export class ESpeciPageService {

    private brandService:BrandService;
    private clipService:ClipService;
    private specieService:SpecieService;
    private mobService:MobService;
    private baleService:BaleService;
    private lineService:LineService;

    constructor(brandService:BrandService, clipService:ClipService, specieService: SpecieService,
                mobService:MobService, baleService:BaleService, lineService:LineService) {
        this.brandService = brandService;
        this.clipService = clipService;
        this.specieService = specieService;
        this.mobService = mobService;
        this.baleService = baleService;
        this.lineService = lineService;
        this.specieService = specieService;
    }

    /**
     * Retrieves the eSpeci related values on the clip level
     * @param brand
     * @param clip
     * @returns {any}
     */
    public getESpeciClipData(clip:ClipModel) : Observable<ResponseModel[]> {

        //Values related to the clip
        let mobService = this.mobService.getMobsByClip(clip);
        let baleService = this.baleService.getBalesByClip(clip);
        let lineService = this.lineService.getLinesByClip(clip);
        let specieService = this.specieService.getSpeciesByClip(clip);

        // Clip settings
        let woolDescriptionService = this.clipService.getClipWoolDescriptions(clip);
        let binCodeService = this.clipService.getClipBinCodes(clip);

        return forkJoin([mobService, baleService, lineService, specieService, woolDescriptionService, binCodeService]);
    }
}
