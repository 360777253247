import { BaseModel } from './baseModel';
import { map as _map } from 'lodash'


export class BrandAttributeModel extends BaseModel
{
    public brandAttributesId:number;
    public attributeType:number;
    public text:string;
}

export class BrandAttributesViewModel extends BaseModel {
    public sustainability:BrandAttributeModel[] = [];
    public animalHealthAndWelfare:BrandAttributeModel[] = [];
    public traceability:BrandAttributeModel[] = [];
    public socialResponsibility:BrandAttributeModel[] = [];

    constructor(values:Object = {}) {
        super(values);

        if(typeof values['sustainability'] !== 'undefined') {
          this.sustainability = _map(values['sustainability'], (attr) => new BrandAttributeModel(attr));
        }

        if(typeof values['animalHealthAndWelfare'] !== 'undefined') {
          this.animalHealthAndWelfare = _map(values['animalHealthAndWelfare'], (attr) => new BrandAttributeModel(attr));
        }

        if(typeof values['traceability'] !== 'undefined') {
          this.traceability = _map(values['traceability'], (attr) => new BrandAttributeModel(attr));
        }

        if(typeof values['socialResponsibility'] !== 'undefined') {
          this.socialResponsibility = _map(values['socialResponsibility'], (attr) => new BrandAttributeModel(attr));
        }
    }
}