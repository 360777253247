import { BaseModel } from '~/models/baseModel';
import { ImportLotModel } from '~/models/importLotModel';
import { map as _map } from 'lodash';
import { Moment } from '~/decorators/moment/momentDecorator';
import * as moment from 'moment';

export class ImportClipModel extends BaseModel {
    public clipId:number;
    public lots:ImportLotModel[];
    public clipReference:string;

    @Moment()
    public clipStartDate:moment.Moment;

    constructor(values: Object = {}) {
        super(values);

        this.lots = _map(values['lots'], (lot) => {
            return new ImportLotModel(lot);
        });
    }
}