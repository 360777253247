import { BaseModel } from '~/models/baseModel';
import { forEach as _forEach } from 'lodash';

export class FtpConnectionModel extends BaseModel {
    public host:string;
    public path:string;
    public username:string;
    public password:string;
    public fileType:string;

    public hasValues() : boolean {
        let propsStr = ['host', 'path', 'username', 'password'];
        let hasValues = false;

        _forEach(propsStr, (prop) => {
            hasValues = hasValues || (typeof this[prop] !== 'undefined' && this[prop].length > 0);
        });

        return hasValues;
    }
}