import { SpecieModel } from '~/models/specieModel';
import { MobModel } from '~/models/mobModel';
import { map as _map } from 'lodash';

export class InventorySpecieModel extends SpecieModel {
    public noBales:number;
    public broker:string;
    public mobs:MobModel[];
    public readOnly:boolean;
    public description:string;
    public lineIDs:number[];

    constructor(values: Object = {}) {
        super(values);

        if(values['mobs']) {
            this.mobs = _map(values['mobs'], (mob) => {
                return new MobModel(mob);
            });
        }
    }
}