import { Injectable } from '@angular/core';
import { AccountAbstractService } from '~/services/api/web/account/accountAbstractService';
import { HttpClientWrapper } from '~/services/api/httpClientWrapper';
import { HttpClient, HttpEvent, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { ResponseModel } from '~/models/responseModel';
import { OnBoardingModel } from '~/models/onBoardingModel';
import { BaseWebService } from '~/services/api/web/base/baseWebService';
import { UserModel } from '~/models/userModel';
import { Observable } from 'rxjs';
import { FeedbackModel } from '~/models/feedbackModel';

@Injectable()
export class AccountService extends BaseWebService implements AccountAbstractService {

    public static readonly PATH:string = '/account';

    private httpClientLegacy:HttpClient;

    constructor(http:HttpClientWrapper, httpClientLegacy:HttpClient) {
        super(http);
        this.httpClientLegacy = httpClientLegacy;
    }

    public getOnBoarding() : Observable<ResponseModel> {
        let url:string = AccountService.URL + AccountService.PATH + '/onboard';
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return new OnBoardingModel(response['data']);
        });
    }

    public getProfile() : Observable<ResponseModel> {
        let url:string = AccountService.URL + AccountService.PATH + '/profile';
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return new UserModel(response['data']);
        });
    }

    public postAccount(contactModel:UserModel) : Observable<ResponseModel> {
        let url:string = AccountService.URL + AccountService.PATH + '/saveprofile';
        let body:any = contactModel;
        let options:{} = {};

        return this.http.post(url, body, options, (response:HttpResponse<any>) => {
            return new UserModel(response['data']);
        });
    }

    public createAccount(contactModel:UserModel) : Observable<ResponseModel> {
        let url:string = AccountService.URL + AccountService.PATH + '/createprofile';
        let body:any = contactModel;
        let options:{} = {};

        return this.http.post(url, body, options, (response:HttpResponse<any>) => response);
    }

    public postFeedback(feedback:FeedbackModel) : Observable<ResponseModel> {
        let url:string = AccountService.URL + AccountService.PATH + '/feedback';
        let body:any = feedback;
        let options:{} = {};

        return this.http.post(url, body, options);
    }

    public postSelectedTradingEntityId(entityId:number) : Observable<ResponseModel> {
        let url:string = AccountService.URL + AccountService.PATH + '/selectedtradingentityid/' + entityId.toString();
        let body:any = null;
        let options:{} = {};

        return this.http.post(url, body, options);
    }

    public verifyAccount(key:string) : Observable<ResponseModel> {
        let url:string = AccountService.URL + AccountService.PATH + '/verify/' + key;
        let options:{} = {};

        return this.http.get(url, options);
    }

    public uploadProfilePicture(file:File) : Observable<HttpEvent<any>> {
        let url = AccountService.URL + AccountService.PATH +  '/upload_profile_picture';

        let formData = new FormData();
        formData.append('filePayload', file);

        let params = new HttpParams();

        const options = {
            params: params,
            reportProgress: true,
        };

        const req = new HttpRequest('POST', url, formData, options);
        return this.httpClientLegacy.request(req);
    }

    public deleteProfilePicture() : Observable<HttpEvent<any>> {
        let url = AccountService.URL + AccountService.PATH +  '/delete_profile_picture';

        let params = new HttpParams();

        const options = {
            params: params,
            reportProgress: true,
        };

        const req = new HttpRequest('POST', url, null, options);
        return this.httpClientLegacy.request(req);
    }
}