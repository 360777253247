import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientWrapper } from '~/services/api/httpClientWrapper';

//API IDS Services
import { AuthService } from '~/services/api/auth/authService';

//API Web Services
import { BaleService } from '~/services/api/web/bale/baleService';
import { BrandService } from '~/services/api/web/brand/brandService';
import { ClipService } from '~/services/api/web/clip/clipService';
import { ConstantsService } from '~/services/api/web/constants/constantsService';
import { LineService } from '~/services/api/web/line/lineService';
import { MobService } from '~/services/api/web/mobs/mobService';
import { SpecieService } from '~/services/api/web/specie/specieService';
import { ContactPermissionsService } from '~/services/api/web/contactPermission/contactPermissionsService';
import { EntityService } from '~/services/api/web/entity/entityService';
import { GroupService } from '~/services/api/web/group/groupService';
import { CertificateService } from '~/services/api/web/certificate/certificateService';
import { AccountService } from '~/services/api/web/account/accountService';
import { EpiService } from '~/services/api/web/epi/epiService';
import { PartnerService } from '~/services/api/web/partner/partnerService';
import { MarketService } from '~/services/api/web/market/marketService';
import { SearchService } from '~/services/api/web/search/searchService';
import { ExportService } from '~/services/api/web/export/exportService';
import { NotificationService } from '~/services/api/web/notification/notificationService';
import { InventoryService } from '~/services/api/web/inventory/inventoryService';
import { BrokerInventoryService } from '~/services/api/web/brokerInventory/brokerInventoryService';
import { InvoiceService } from '~/services/api/web/invoice/invoiceService';
import { ReadyReckonerService } from '~/services/api/web/readyReckoner/readyReckonerService';
import { LiteEntityService } from '~/services/api/web/liteEntity/liteEntityService';
import { SocialService } from '~/services/api/web/social/socialService';
import { ActivityStreamService } from '~/services/api/web/activityStream/activityStreamService';
import { ImportService } from '~/services/api/web/import/importService';
import { MyWoolService } from '~/services/api/web/myWool/myWoolService';
import { UserPreferenceService } from '~/services/api/web/userPreference/userPreferenceService';
import { UserPreferenceLocalService } from '~/services/api/web/userPreference/userPreferenceLocalService';
import { TempBrandService } from '~/services/api/web/tempBrand/tempBrandService';
import { AuctionCatalogService } from '~/services/api/web/auctionCatalog/auctionCatalogService';
import { OfferService } from '~/services/api/web/offer/offerService';
import { TradingService } from '~/services/api/web/trading/tradingService';
import { OrderBookService } from '~/services/api/web/orderBook/orderBookService';
import { PaymentService } from '~/services/api/web/payment/paymentService';
import { TransactionService } from '~/services/api/web/transaction/transactionService';
import { AdminService } from '~/services/api/web/admin/adminService';
import { TraceabilityService } from '~/services/api/web/traceability/traceabilityService';

//API EPI Services
import { EntityEpiService } from '~/services/api/epi/entity/entityEpiService';
import { ContentEpiService } from '~/services/api/epi/content/contentEpiService';


import { RiemannService } from '~/services/api/web/riemann/prmService';

//Interceptors
import { AuthInterceptor } from '~/services/authInterceptor';
import { HttpCancelInterceptor } from '~/services/httpCancelInterceptor';

//Util Services
import { SmoothScrollService } from '~/services/util/smoothScrollService';
import { NetworkStatusService } from '~/services/util/networkStatusService';
import { FileService } from '~/services/util/fileService';
import { MapsService } from '~/services/util/mapsService';
import { MediaQueryService } from '~/services/util/mediaQueryService';
import { OperatingSystemDetectionService } from '~/services/util/operatingSystemDetectionService';
import { BitlyService } from '~/services/bitly/bitlyService';
import { XLSXExportService } from '~/services/util/xlsxExportService';
import { HttpCancelService } from '~/services/util/httpCancelService';

//Guards
import { GuestGuard } from '~/guards/guestGuard';

//Storage Services
import { LocalService } from '~/services/storage/local/localService';
import { SessionService } from '~/services/storage/session/sessionService';

//Segment
import { SegmentService } from '~/services/segment/segmentService';

//Intercom
import { IntercomService } from '~/services/intercom/intercomService';

//ngx-scroll-to
//import { ScrollToService } from '@akaustav/ngx-scroll-to';

import { TenderCatalogService } from '~/services/api/web/tenderCatalog/tenderCatalogService';
import { TenderOfferService } from '~/services/api/web/tenderCatalog/tenderOfferService';

// Analytics GA Service wrapper
import { AnalyticsService } from '~/services/util/analyticsService';
import { IntegrationService } from '~/services/api/web/integration/integrationService';
import { OktaAuthGuard } from '@okta/okta-angular';

@NgModule({
    providers: [
        AuthService,

        EpiService,
        BrandService,
        BaleService,
        ClipService,
        ConstantsService,
        LineService,
        MobService,
        SpecieService,
        ContactPermissionsService,
        AccountService,
        PartnerService,
        MarketService,
        SearchService,
        InvoiceService,
        IntegrationService,

        ExportService,
        NotificationService,
        InventoryService,
        BrokerInventoryService,
        ReadyReckonerService,
        SocialService,
        ActivityStreamService,
        LiteEntityService,
        ImportService,
        MyWoolService,
        UserPreferenceService,
        UserPreferenceLocalService,
        TempBrandService,
        AuctionCatalogService,
        TenderCatalogService,
        TenderOfferService,
        OfferService,
        TradingService,
        OrderBookService,
        AdminService,
        PaymentService,
        TransactionService,
        TraceabilityService,

        EntityService,
        GroupService,
        CertificateService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },

        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpCancelInterceptor,
            multi: true
        },

        EntityEpiService,
        ContentEpiService,

        RiemannService,

        LocalService,

        AnalyticsService,
        SmoothScrollService,
        NetworkStatusService,
        FileService,
        MapsService,
        MediaQueryService,
        OperatingSystemDetectionService,
        BitlyService,
        XLSXExportService,
        HttpCancelService,

        OktaAuthGuard,
        GuestGuard,

        SessionService,
        SegmentService,
        IntercomService,
        //ScrollToService,

        HttpClientWrapper
    ],
})

export class AppServiceModule {
}
