import { Component, AfterViewChecked } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ElementRef } from '@angular/core';

@Component({
    selector: 'wep-ag-grid-cell-renderer-market-auction-details',
    templateUrl: './details.html',
    host: { 'class' : 'wep-ag-grid-cell-renderer wep-ag-grid-cell-renderer-market wep-ag-grid-cell-renderer-market-auction-details' },
    styleUrls: ['./details.scss']
})

/**
 * Contains a topLabel that is absolutely positioned to extend beyond the other cells.
 */
export class AgGridCellRendererMarketAuctionDetailsComponent implements ICellRendererAngularComp, AfterViewChecked {
    public params: any;
    private nativeElement:HTMLElement;

    constructor(element:ElementRef) {
        this.nativeElement = element.nativeElement;
    }

    public agInit(params: any) : void {
        this.params = params;
    }

    public ngAfterViewChecked() : void {
        this.params.eGridCell.style.width = '100%';
        this.params.eGridCell.style.minWidth = '80px';
        this.params.eGridCell.style.minHeight = '56px';
    }

    public refresh() : boolean {
        return false;
    }
}
