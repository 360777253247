import { Injectable } from '@angular/core';

export enum OperatingSystemItems {
    Windows,
    MacOS,
    iOS,
    Android,
    Linux
}

/**
 * Wrapper to detect Operating System
 * Refer to:
 *  https://developer.mozilla.org/en-US/docs/Web/API/NavigatorID/platform
 *  https://stackoverflow.com/questions/38241480/detect-macos-ios-windows-android-and-linux-os-with-js
 */
@Injectable()
export class OperatingSystemDetectionService {

    private operatingSystem:OperatingSystemItems = null;

    constructor() {
        this.identifyOS();
    }

    public identifyOS() : void {
        let userAgent:string = window.navigator.userAgent;
        let platform:string = window.navigator.platform;
        let macosPlatforms:string[] = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
        let windowsPlatforms:string[] = ['Win32', 'Win64', 'Windows', 'WinCE'];
        let iosPlatforms:string[] = ['iPhone', 'iPad', 'iPod'];

        if(macosPlatforms.indexOf(platform) !== -1) {
            this.operatingSystem = OperatingSystemItems.MacOS;
        }
        else if (iosPlatforms.indexOf(platform) !== -1) {
            this.operatingSystem = OperatingSystemItems.iOS;
        }
        else if (windowsPlatforms.indexOf(platform) !== -1) {
            this.operatingSystem = OperatingSystemItems.Windows;
        }
        else if (/Linux/.test(platform)) {
            this.operatingSystem = OperatingSystemItems.Linux;
        }
        else if (/Android/.test(userAgent)) {
            this.operatingSystem = OperatingSystemItems.Android;
        }
    }

    public getOS() : OperatingSystemItems {
        return this.operatingSystem;
    }
}