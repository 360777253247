import { Injectable } from '@angular/core';
import { BaseWebService } from '~/services/api/web/base/baseWebService';
import { ActivityStreamAbstractService } from '~/services/api/web/activityStream/activityStreamAbstractService';
import { HttpClient, HttpEvent, HttpRequest, HttpParams, HttpResponse } from '@angular/common/http';
import { HttpClientWrapper } from '~/services/api/httpClientWrapper';
import { PostModel } from '~/models/postModel';
import { CommentModel } from '~/models/commentModel';
import { EntityModel } from '~/models/entityModel';
import { ResponseModelCode } from '~/models/responseModel';
import { ResponseModel } from '~/models/responseModel';
import { Observable, of } from 'rxjs';
import { map as _map } from 'lodash';

@Injectable()
export class ActivityStreamService extends BaseWebService implements ActivityStreamAbstractService {

    public static readonly PATH:string = '/social';

    //@TODO phase this out when upload functions are redone…
    private httpClientLegacy:HttpClient;

    constructor(http:HttpClientWrapper, httpClientLegacy:HttpClient) {
        super(http);

        this.httpClientLegacy = httpClientLegacy;
    }

    public getPosts() : Observable<ResponseModel> {
        let url:string = ActivityStreamService.URL + ActivityStreamService.PATH + '/posts';
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (post) => new PostModel(post));
        });
    }

    public getMorePosts(lastPost:PostModel, size:number = 10) : Observable<ResponseModel> {
        let url:string = ActivityStreamService.URL + ActivityStreamService.PATH + '/posts?lastId=' + lastPost.id.toString() + '&size=' + size.toString();
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (post) => new PostModel(post));
        });
    }

    public getEntityPosts(entity:EntityModel) : Observable<ResponseModel> {
        return this.getEntityPostsById(entity.entityId);
    }

    public getEntityPostsById(entityId:number) : Observable<ResponseModel> {
        let url:string = ActivityStreamService.URL + ActivityStreamService.PATH + '/entities/' + entityId.toString() + '/posts';
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (post) => new PostModel(post));
        });
    }

    public getMoreEntityPosts(lastPost:PostModel, size:number = 10) : Observable<ResponseModel> {
        let url:string = ActivityStreamService.URL + ActivityStreamService.PATH +  '/entities/' + lastPost.entity.entityId.toString() + '/posts?lastId=' + lastPost.id.toString() + '&size=' + size.toString();
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (post) => new PostModel(post));
        });
    }

    public postPost(post:PostModel) : Observable<ResponseModel> {
        let url:string = ActivityStreamService.URL + ActivityStreamService.PATH + '/entities/' + post.entityId.toString() + '/posts';
        let body:any = post;
        let options:{} = {};

        return this.http.post(url, body, options, (response:HttpResponse<any>) => {
            return new PostModel(response['data']);
        });
    }

    public postComment(comment:CommentModel) : Observable<ResponseModel> {
        let url:string = ActivityStreamService.URL + ActivityStreamService.PATH + '/posts/' + comment.postId.toString() + '/comments';
        let body:any = comment;
        let options:{} = {};

        return this.http.post(url, body, options, (response:HttpResponse<any>) => {
            return new PostModel(response['data']);
        });
    }

    public like(post:PostModel) : Observable<ResponseModel> {
        let url:string = ActivityStreamService.URL + ActivityStreamService.PATH + '/posts/' + post.id.toString() + '/like';
        let body:any = '';
        let options:{} = {};

        return this.http.post(url, body, options, (response:HttpResponse<any>) => {
            return new PostModel(response['data']);
        });
    }

    public unlike(post:PostModel) : Observable<ResponseModel> {
        let url:string = ActivityStreamService.URL + ActivityStreamService.PATH + '/posts/' + post.id.toString() + '/unlike';
        let body:any = '';
        let options:{} = {};

        return this.http.post(url, body, options, (response:HttpResponse<any>) => {
            return new PostModel(response['data']);
        });
    }

    public uploadPostPhotos(file:File) : Observable<HttpEvent<any>> {
        let url = ActivityStreamService.URL + ActivityStreamService.PATH + '/images';

        let formData = new FormData();
        formData.append('filePayload', file);

        let params = new HttpParams();

        const options = {
            params: params,
            reportProgress: true,
        };

        const req = new HttpRequest('POST', url, formData, options);
        return this.httpClientLegacy.request(req);
    }

    public deletePost(post:PostModel) : Observable<ResponseModel> {
        let url:string = ActivityStreamService.URL + ActivityStreamService.PATH + '/posts/' + post.id.toString() + '/delete';
        let body:any = null;
        let options:{} = {};

        return this.http.post(url, body, options);
    }

    public deleteCommentPost(comment:CommentModel) : Observable<ResponseModel> {
        if(!comment || !comment.postId || !comment.id) {
            return of(new ResponseModel({
                data: '',
                code: ResponseModelCode.Error,
            }));
        }
        let url:string = ActivityStreamService.URL + ActivityStreamService.PATH + '/posts/' + comment.postId.toString() + '/comments/' + comment.id.toString() + '/delete';
        let body:any = null;
        let options:{} = {};

        return this.http.post(url, body, options);
    }
}
