import { Injectable } from '@angular/core';
import { InventoryAbstractService } from '~/services/api/web/inventory/inventoryAbstractService';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { HttpClientWrapper } from '~/services/api/httpClientWrapper';
import { BaseWebService } from '~/services/api/web/base/baseWebService';
import { ResponseModel } from '~/models/responseModel';
import { Observable } from 'rxjs';
import { map as _map } from 'lodash';
import { InvoiceModel } from '~/models/invoiceModel';


@Injectable()
export class InvoiceService extends BaseWebService {

    public static readonly PATH: string = '/invoice';

    constructor(http: HttpClientWrapper) {
        super(http);
    }

    public getInvoices(entityId: number): Observable<ResponseModel> {
        let url:string = InvoiceService.URL + InvoiceService.PATH  + '/' + entityId.toString();

        let params = new HttpParams();

        let options:{} = {
            params: params
        };

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return _map(response['data'], (inv) => new InvoiceModel(inv));
        });
    }

    public getInvoicePDF(invoiceId:number) : Observable<ResponseModel> {
        let url:string =InvoiceService.URL + InvoiceService.PATH  + '/' + invoiceId.toString() + '/download';

        let options:{} = {
            params : {},
            responseType: 'blob' as 'blob'
        };

        return this.http.get(url, options, (response:HttpResponse<any>) => {
             return response;
        });
    }
}
