import { BaseModel } from '~/models/baseModel';
import { InventorySpecieModel } from '~/models/inventorySpecieModel';
import { InventoryClipModel } from '~/models/inventoryClipModel';
import { InventoryMobModel } from '~/models/inventoryMobModel';
import { InventoryLineModel } from '~/models/inventoryLineModel';
import { InventoryLotModel } from '~/models/inventoryLotModel';

export class BaleRelatedItemsModel extends BaseModel {
    public mobs:InventoryMobModel[];
    public species:InventorySpecieModel[];
    public clip:InventoryClipModel;
    public line:InventoryLineModel;
    public lot:InventoryLotModel;

    constructor(values?:{}) {
        super(values);

        if(!this.mobs) {
            this.mobs = [];
        }
        if(!this.species) {
            this.species = [];
        }
        if(!this.clip) {
            this.clip = null;
        }
        if(!this.line) {
            this.line = null;
        }
        if(!this.lot) {
            this.lot = null;
        }
    }
}
