import { BaseModel } from '~/models/baseModel';
import { Moment } from '~/decorators/moment/momentDecorator';
import * as moment from 'moment';

export class SoldLotModel extends BaseModel {
    @Moment()
    public date:moment.Moment;
    public cleanPrice:number;
    public price:number;
    public micron:number;
    public id:number;
    public saleType:string;
}
