import { Injectable } from '@angular/core';
import { TransactionAbstractService } from '~/services/api/web/transaction/transactionAbstractService';
import { ResponseModel, ResponseModelCode } from '~/models/responseModel';
import { TransactionModel, TransactionStatus } from '~/models/transactionModel';
import { Observable, of } from 'rxjs';
import { map as _map } from 'lodash';
import * as moment from 'moment';

@Injectable()
export class TransactionMockService implements TransactionAbstractService {

    //@TODO when the api is ready and the real columns are available refactor the field values.
    private failed:TransactionModel[] =  _map([
        {
            transactionId: 'ABC123',

            business: 'Business Test',
            userName: 'User Test',

            bales: 20,
            bidCost: 1000,

            invoiceDate: moment(),
            totalFee: 2000,
            status: TransactionStatus.Unresolved,
        },
    ], (transaction) => new TransactionModel(transaction));

    public getFailedTransactions() : Observable<ResponseModel> {
        return of(
            new ResponseModel({
                code: ResponseModelCode.Ok,
                data: this.failed
            })
        );
    }

    public postTransactionCancel(_transaction:TransactionModel) : Observable<ResponseModel> {
        return of(
            new ResponseModel({
                code: ResponseModelCode.Ok,
                data: []
            })
        );
    }

    public postTransactionRetry(_transaction:TransactionModel) : Observable<ResponseModel> {
        return of(
            new ResponseModel({
                code: ResponseModelCode.Ok,
                data: []
            })
        );
    }
}
