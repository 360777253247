import { Injectable } from '@angular/core';
import { SnackBarService } from '~/components/snackBar/services/snackBarService';

/**
 * Displays SnackBars when the page goes online or offline
 */
@Injectable()
export class NetworkStatusService {

    private snackBarService:SnackBarService;

    constructor(snackBarService:SnackBarService) {
        this.snackBarService = snackBarService;

        window.addEventListener('online', () => {
            this.displayStatusEvent();
        });

        window.addEventListener('offline', () => {
            this.displayStatusEvent();
        });
    }

    private displayStatusEvent() : void {
        if(navigator.onLine) {
            this.displayOnlineMessage();
        }
        else {
            this.displayOfflineMessage();
        }
    }

    private displayOfflineMessage() : void {
        this.snackBarService.openAsError('Your internet is offline.');
    }

    private displayOnlineMessage() : void {
        this.snackBarService.open('Connected.');
    }
}
