import { Component, AfterViewChecked } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'wep-ag-grid-cell-renderer-market-auction-sticky',
    templateUrl: './sticky.html',
    host: { 'class' : 'wep-ag-grid-cell-renderer wep-ag-grid-cell-renderer-market wep-ag-grid-cell-renderer-market-auction-sticky' },
    styleUrls: ['./sticky.scss']
})

export class AgGridCellRendererMarketAuctionStickyComponent implements ICellRendererAngularComp, AfterViewChecked {
    public params: any;

    public agInit(params: any) : void {
        this.params = params;
    }

    public refresh() : boolean {
        return false;
    }

    public ngAfterViewChecked() : void {
        this.params.eGridCell.style.minHeight = '56px';
    }
}