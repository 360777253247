import { Component, Directive, ViewChild, ElementRef, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { AdminAbstractPageTemplateComponent } from '~/pageTemplates/admin/pageTemplateAbstract/pageTemplateAbstract';

@Directive({
    selector:
    'wep-page-tpl-admin-default-heading-left, ' +
    'wep-page-tpl-admin-default-heading-right, ' +
    'wep-page-tpl-admin-default-subheading-left, '+
    'wep-page-tpl-admin-default-content, ' +
    'wep-page-tpl-admin-default-footer, ' +
    'wep-page-tpl-admin-default-sidebar'
})
export class AdminDefaultPageTemplateDirective { }

@Component({
    selector: 'wep-page-tpl-admin-default',
    templateUrl: './pageTemplateDefault.html',
    host: {'class': 'page-tpl page-tpl--admin page-tpl--admin--default'},
    styleUrls: ['./pageTemplateDefault.scss']
})

export class AdminDefaultPageTemplateComponent extends AdminAbstractPageTemplateComponent implements AfterViewInit {

    @ViewChild('headingRight',{static: true})
    public headingRight:ElementRef;

    public hasHeadingRight:boolean;

    private changeDetectorRef:ChangeDetectorRef;

    constructor(changeDetectorRef:ChangeDetectorRef) {
        super();
        this.changeDetectorRef = changeDetectorRef;
    }

    public ngAfterViewInit() : void {
        if(this.headingRight) {
            this.hasHeadingRight = this.headingRight.nativeElement && this.headingRight.nativeElement.children.length > 0;
            this.changeDetectorRef.detectChanges();
        }
    }
}
