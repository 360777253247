import { BaseModel } from "./baseModel";

export class Percentile extends BaseModel {
    micron: string;
    close: number;
    p95: number;
    p90: number;
    p85: number;
    p80: number;
    p75: number;
    p70: number;
    p65: number;
    p60: number;
    p55: number;
    p50: number;
    p40: number;
    p30: number;
    p20: number;
    p10: number;
    selected: number;

    p95Color: string = '#ff0000';
    p90Color: string = '#f4b084';
    p85Color: string = '#fce4d6';
    p80Color: string = '#ffd966';
    p75Color: string = '#ffe699';
    p70Color: string = '#fce4d6';
    p65Color: string = '#ffff00';
    p60Color: string = '#dce6f1';
    p55Color: string = '#b8cce4';
    p50Color: string = '#8db4e2';
    p40Color: string = '#538dd5';
    p30Color: string = '#538dd5';
    p20Color: string = '#538dd5';
    p10Color: string = '#538dd5';

}