import { BaseModel } from '~/models/baseModel';

export class QualitySchemeEligibilityModel extends BaseModel {
    public code:string;
    public description:string;
    public iconUrl:string;
    public websiteUrl:string;

    constructor(values:Object = {}) {
        super(values);

        this.code = values['code'];
        this.description = values['description'];
        this.iconUrl = values['iconUrl'];
        this.websiteUrl = values['websiteUrl'];
    }
}

