import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AgGridCellEditorInputComponent } from '~/components/agGrid/cellEditors/input/input';
import { isNil as _isNil } from 'lodash';

/**
 * A Cell Editor with a wepCurrencyMask
 */
@Component({
    selector: 'wep-ag-grid-cell-editor-input-currency',
    templateUrl: './inputCurrency.html',
    host: { 'class' : 'wep-ag-grid-cell-editor-input wep-ag-grid-cell-editor-input-currency' },
    styleUrls: ['./../input.scss']
})

export class AgGridCellEditorInputCurrencyComponent extends AgGridCellEditorInputComponent {

    constructor(formBuilder:FormBuilder) {
        super(formBuilder);
    }

    public agInit(params: any) : void {
        if(_isNil(params.maskSettings)) {
            params.maskSettings = {};
        }
        super.agInit(params);
    }
}