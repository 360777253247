import { BaseModel } from '~/models/baseModel';
import { BrokerServicePriceType } from '~/models/brokerServicePriceType';


export class BrokerServiceModel extends BaseModel {
    public brokerServiceId:number;
    public serviceName:string;
    public description:string;
    public priceUnit:string;
    public priceValue:number;

    private price:BrokerServicePriceType;

    constructor(values:{} = {}) {
        super(values);

        this.price = new BrokerServicePriceType();
        this.price.setPrice(values);

        if (typeof values['price'] !== 'undefined') {
            this.priceValue = this.price.priceValue;
            this.priceUnit = this.price.priceUnit;
        }
    }
}
