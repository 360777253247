import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { HttpClientWrapper } from '~/services/api/httpClientWrapper';
import { BaseWebService } from '~/services/api/web/base/baseWebService';
import { SearchAbstractService } from '~/services/api/web/search/searchAbstractService';

// Models
import { ResponseModel } from '~/models/responseModel';
import { SavedSearchModel } from '~/models/savedSearchModel';

// RxJS
import { Observable } from 'rxjs';
import { SearchStateModel } from '~/models/searchStateModel';

import { map as _map } from 'lodash';

@Injectable()
export class SearchService extends BaseWebService implements SearchAbstractService {

    public static readonly PATH:string = '/search';

    constructor(http: HttpClientWrapper) {
        super(http);
    }

    public saveSearch(search:SavedSearchModel) : Observable<ResponseModel> {
        let url:string = SearchService.URL + SearchService.PATH;
        let body:any = search;
        let options:{} = {};

        return this.http.post(url, body, options, (response:HttpResponse<any>) => {
            return new SavedSearchModel(response['data']);
        });
    }

    public getSearchStatesByEntity(entityId:number) : Observable<ResponseModel> {
        let url:string = SearchService.URL + SearchService.PATH + '/searchstates/entity/' + entityId.toString();
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (search) => new SearchStateModel(search));
        });
    }

    public delete(savedSearchId:number) : Observable<ResponseModel> {
        let url:string = SearchService.URL + SearchService.PATH + '/' + savedSearchId.toString() + '/delete';
        let options:{} = {};

        return this.http.get(url, options);
    }

    public getSearchStates(filter:SavedSearchModel) : Observable<ResponseModel> {
        let url:string = SearchService.URL + SearchService.PATH + '/searchstates';
        let body:any = filter;
        let options:{} = {};

        return this.http.post(url, body, options, (response:HttpResponse<any>) => {
            return new SearchStateModel(response['data']);
        });
    }
}
