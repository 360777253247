import { Component, AfterViewInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AgGridCellEditorInputComponent } from '~/components/agGrid/cellEditors/input/input';
import { isFunction as _isFunction } from 'lodash';

/**
 * A variant of the InputCellEditor which is a “display” of the CellValue
 * For the most part, this is complimented with an onClickEvent that would open a dialog.
 * This then can be updated using the AgGrid API.
 */
@Component({
    selector: 'wep-ag-grid-cell-editor-input-readonly',
    templateUrl: './inputReadonly.html',
    host: { 'class' : 'wep-ag-grid-cell-editor-input wep-ag-grid-cell-editor-input-readonly' },
    styleUrls: ['./inputReadonly.scss']
})

export class AgGridCellEditorInputReadonlyComponent extends AgGridCellEditorInputComponent implements AfterViewInit {
    @ViewChild('button', {read: ViewContainerRef, static: false})
    public button:ViewContainerRef;

    constructor(formBuilder:FormBuilder) {
        super(formBuilder);
    }

    public onClick() : void {
        if(_isFunction(this.params.clickEvent)) {
            this.params.clickEvent(this.params, this);
        }
    }

    public ngAfterViewInit() : void {
        setTimeout(() => {
            this.button.element.nativeElement.focus();
        });
    }

    public keyDown(event:KeyboardEvent) : void {
        event.preventDefault();
        if (event.which === 13) {
            this.onClick();
        }
        // Previous Cell
        else if(event.shiftKey && event.keyCode === 9) {
            this.params.api.tabToPreviousCell();
        }
        // Next Cell
        else if(event.which === 9) {
            this.params.api.tabToNextCell();
        }
    }

    public updateValue(value:any) {
        this.formGroup.controls['cell'].setValue(value);
        this.params.node.setDataValue(this.params.column.colId, value);
        this.params.api.setFocusedCell(this.params.node.rowIndex, this.params.column.colId);
    }
}