import { BaseModel } from '~/models/baseModel';
import { Moment } from '~/decorators/moment/momentDecorator';
import * as moment from 'moment';

export class NotificationModel extends BaseModel {
    public notificationId:number;
    public notificationType:string;
    public notificationMessage:string;
    public receiver_ContactID:number;
    public sender_ContactID:number;

    @Moment()
    public generatedDate:moment.Moment;
    public isRead:boolean;
    public iconType:string;

    constructor(values?:{}) {
        super(values);
    }
}
