<div *ngIf="options.length > 0"
     #selectButton
     class="wep-select__button"
     [ngClass]="{'wep-select__button--active' : displayBar, 'wep-select__button--disabled' : disabled}"
     tabindex="0"
     (click)="toggle()"
     (keydown)="selectButtonKeydown($event)"
     (focus)="onFocus()"
     [attr.aria-label]="ariaLabel">
    <div class="wep-select__button__text" *ngIf="selectedOption && !isValueEmpty(value)">
        <div class="wep-select__button__text__inner">{{selectedOption.displayText}}</div>
    </div>
    <div class="wep-select__button__text wep-select__button__text--empty" *ngIf="isValueEmpty(value)">
        <div class="wep-select__button__text__inner">{{placeholder}}</div>
    </div>
    <div class="wep-select__button__dropdown-arrow">
        <span class="wep-select__button__dropdown-arrow__icon-container"
              [@selectArrowAnimation]="getAnimationState()">
            <i [ngClass]="{'icons icons--arrow-down': isVariantLine, 'fas fa-chevron-down': !isVariantLine }"></i>
       </span>
    </div>
</div>
<div *ngIf="options.length === 0" class="wep-select__button select__button--disabled">
    <div class="wep-select__button__text wep-select__button__text--empty">
        <div class="wep-select__button__text__inner">{{placeholder}}</div>
    </div>
    <div class="wep-select__button__dropdown-arrow">
        <i [ngClass]="{'icons icons--arrow-down': isVariantLine, 'fas fa-chevron-down': !isVariantLine }"></i>
    </div>
</div>
<ng-template #selectBarTemplate>
    <div *ngIf="options.length > 0"
         #selectBar
         class="wep-select__bar"
         [ngClass]="{'wep-select__bar--variant--line': isVariantLine }"
         [@selectBarAnimation]="getAnimationState()">
        <div class="wep-select__bar__inner">
            <div *ngFor="let option of options; let i = index;"
                 [ngClass]="'wep-select__option--' + i"
                 class="wep-select__option"
                 tabindex="0"
                 (click)="setSelectedOption(option)"
                 (keydown)="selectOptionKeydown($event, option)">
                {{option.index}}
                <div class="wep-select__option__inner">{{option.displayText}}</div>
            </div>
        </div>
    </div>
</ng-template>
<input type="hidden" [value]="value" [disabled]="disabled">
