import { PermissionModel } from '~/models/permissionModel';
import { BaleModel } from '~/models/baleModel';
import { MobModel } from '~/models/mobModel';
import { SpecieModel } from '~/models/specieModel';
import { Moment } from '~/decorators/moment/momentDecorator';
import * as moment from 'moment';
import { forEach as _forEach, orderBy as _orderBy, sortedUniqBy as _sortedUniqBy, sumBy as _sumBy, map as _map } from 'lodash';

export class LineModel extends PermissionModel {

    @Moment()
    public createdDate:moment.Moment;

    public ss:string;
    public stapleStrength:string;
    public weight:number;
    public estWeight:number;
    public lineId:number;
    public brandID:number;
    public specieID:number;
    public lineNo:number;
    public orderNo:number;
    public clipID:number;
    public clipReference:string;
    public noBales:number;
    public lineDescription:string;
    public binCode:string;
    public mulesingStatus:string;
    public notes:string;
    public lineFolio:string;
    public micron:string;
    public vm:string;
    public am:boolean;
    public yield:string;
    public length:number;
    public strength:string;
    public pobb:number;
    public pobm:number;
    public pobt:number;
    public guid:string;
    public images:string[];

    public isSubmitted:boolean;

    //Local ID’s
    public localSpecieID:string;
    public localBrandID:string;
    public localClipID:string;

    //Statuses
    public status:string;
    public testStatus:string;

    //Associated Bales
    public bales:BaleModel[] = [];

    //Associated Specie
    public specie:SpecieModel;

    /**
     * Retrieves the count of the bales
     * @returns number
     */
    public getBalesCount() : number {
        return this.bales.length;
    }

    public getBalesWeight() : number {
        let weight = 0;

        weight = _sumBy(this.bales, (bale:BaleModel) => {
            return +bale.weight;
        });

        return weight;
    }

    get average() : number {
        return this.getAverageWoolLength();
    }

    /**
     * Returns the average woolLength of mobs in this line
     * This value is rounded to be displayed
     * @returns {number}
     */
    public getAverageWoolLength() : number {
        if (this.bales.length === 0) {
            return 0;
        }

        let woolLength = 0;

        woolLength = _sumBy(this.bales, (bale:BaleModel) => {
            return bale.getAverageWoolLength();
        });

        return Math.round(woolLength / this.bales.length);
    }

    /**
     * Retrieves the bale numbers separated by comma
     */
    public displayBalesAsString() : string {
        return _map(this.getSortedBales(), (bale:BaleModel) => {
            return bale.baleNo;
        }).join(', ');
    }

    /**
     * Returns all the bales sorted by the bale number
     * @returns {BaleModel[]}
     */
    public getSortedBales() : BaleModel[] {
        return _orderBy(this.bales, (bale:BaleModel) => {
            return bale.baleNo;
        });
    }

    /**
     * Returns all the associated mobs in this line
     * @returns {MobModel[]}
     */
    public getAssociatedMobs() : MobModel[] {
        let associatedMobs:MobModel[] = [];

        _forEach(this.bales, (bale:BaleModel) => {
            _forEach(bale.mobs, (mob:MobModel) => {
                 associatedMobs.push(mob);
            });
        });
        return _sortedUniqBy(_orderBy(associatedMobs, ['mobId', 'mobNo']), 'mobNo');
    }

    /**
     * Returns true (to display an alert icon) if if certain fields are not set
     * @returns {boolean}
     */
    public shouldDisplayAlert() : boolean {
        return !(this.bales.length > 0);
    }
}
