import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'wep-ag-grid-cell-renderer-avatar-user',
    templateUrl: './avatarUser.html',
    host: { 'class' : 'wep-ag-grid-cell-renderer-avatar-user' },
    styleUrls: ['./avatarUser.scss']
})

export class AgGridCellRendererAvatarUserComponent implements ICellRendererAngularComp {
    public params: any;

    public agInit(params: any) : void {
        this.params = params;
    }

    public refresh() : boolean {
        return false;
    }
}