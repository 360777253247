import { BaseModel } from '~/models/baseModel';
import { UserModel } from '~/models/userModel';
import { Moment } from '~/decorators/moment/momentDecorator';
import * as moment from 'moment';

export class ConversationNotificationModel extends BaseModel {
    public contact:UserModel;
    public count:number;

    @Moment()
    public lastSent:moment.Moment;
    public lastMessage:string;

    constructor(values:{}) {
        super(values);
        this.contact = new UserModel(values['contact']);
    }
}