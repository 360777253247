import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { isFunction as _isFunction, isUndefined as _isUndefined } from 'lodash';

export interface AgGridCellRendererActionParams extends ICellEditorParams {
    clickEvent?: (params) => void;
    isDisabled?: boolean;
    class?:((params) => void) | string; //Either class or text is to be displayed
    displayText?:((params) => void) | string;
}

@Component({
    selector: 'wep-ag-grid-cell-renderer-action',
    templateUrl: './action.html',
    host: { 'class' : 'wep-ag-grid-cell-renderer-action' },
    styleUrls: ['./action.scss']
})

export class AgGridCellRendererActionComponent implements ICellRendererAngularComp {
    public params: any;

    public agInit(params: any) : void {
        this.params = params;
    }

    /**
     * Enables a click event for the button outside the scope of the cell
     */
    public onClick(_event:any) : void {
        if(_isFunction(this.params.clickEvent)) {
            this.params.clickEvent(this.params);
        }
    }

    public isVisibale() : boolean {
        if(_isFunction(this.params.isVisibale)) {
            return this.params.isVisibale(this.params);
        }
        return _isUndefined(this.params.isVisibale) ? true : this.params.isVisibale;
    }

    /**
     * Checks whether the button should be disabled based on a condition
     * @returns {boolean}
     */
    public isDisabled() : boolean {
        if(_isFunction(this.params.isDisabled)) {
            return this.params.isDisabled(this.params);
        }
        return false;
    }

    /**
     * Retrieves the CSS class to be displayed, can also use a callback for dynamic classes
     * @returns {string}
     */
    public getClass() : string {
        if(_isFunction(this.params.class)) {
            return this.params.class(this.params);
        }
        return this.params.class;
    }

    public getDisplayText() : string {
        if(_isFunction(this.params.displayText)) {
            return this.params.displayText(this.params);
        }
        return this.params.displayText;
    }

    public refresh() : boolean {
        return false;
    }
}
