import { PermissionModel } from '~/models/permissionModel';

export class OrderModel extends PermissionModel {
    public orderId:number;
	public orderNumber: string;

    constructor(values: Object = {}) {
        super(values);

    }
}
