import { PropertyMapper } from '~/decorators/propertyMapper/propertyMapper';
import { forEach as _forEach, includes as _includes } from 'lodash';

interface KeyValue {
    key:string;
    value:string;
}

/**
 * Passes in a list of keyValues that will create boolean properties
 * The boolean properties are set to true when they are in the values CSV string
 *
 * An example of this includes:
 *
 * Inputs:
 * objectContainerKey: 'myObject'
 * keyValues = [ { 'key' : 'x', : 'value': 1 }, { 'key' : 'y', value : 2 }, { 'key' : 'z', value : 3 }]
 * this.value = '2,3'
 *
 * Outputs:
 * this.myObject.x = false;
 * this.myObject.y = true;
 * this.myObject.z = true;
 * this.value = '2,3'
 *
 * @param {KeyValue[]} keyValues
 * @returns {(target: any, key: string) => void}
 * @constructor
 */
export function CSVBooleanMapper(objectContainerKey:string, keyValues:KeyValue[]) {
    //Prevents recursive calls for setting CSV and Boolean Values
    let settingStringValue:boolean = false;
    let settingBooleanValues:boolean = false;

    return function(target:any, key:string) {

        PropertyMapper(target, key, (value: any, protoTarget:any) => {
            let valueAsArray = value ? value.split(',') : [];

            if(!settingStringValue) {
                settingBooleanValues = true;
                protoTarget[objectContainerKey] = {};
                _forEach(keyValues, (keyValue: KeyValue) => {

                    if (_includes(valueAsArray, keyValue.value)) {
                        protoTarget[objectContainerKey][keyValue.key] = true;
                    }
                    else {
                        protoTarget[objectContainerKey][keyValue.key] = false;
                    }
                });
                settingBooleanValues = false;
            }
            return value;
        });

        PropertyMapper(target, objectContainerKey, (value: any, protoTarget:any) => {
            if(!settingBooleanValues) {
                let newValue = [];

                _forEach(keyValues, (keyValue: KeyValue) => {
                    if (value[keyValue.key]) {
                        newValue.push(keyValue.value);
                    }
                });

                settingStringValue = true;
                protoTarget[key] = newValue.join(',');
                settingStringValue = false;
            }
            return value;
        });
    };
}