import { Injectable } from '@angular/core';
import { MatSnackBarConfig, MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackBarTheme, SnackBarComponent } from '~/components/snackBar/snackBar';

@Injectable()
export class SnackBarService {
    private snackBar: MatSnackBar;

    constructor(snackBar: MatSnackBar) {
        this.snackBar = snackBar;
    }

    /**
     * Generic static method to call an instance of a snackBar
     * @param message displayed text on the snackBar
     * @returns {MatSnackBarRef<SnackBarComponent>}
     */
    public open(message:string) : MatSnackBarRef<any> {
        let snackBarConfig = this.getSnackbarConfig();

        snackBarConfig.data = {
            message: message
        };

        return this.snackBar.openFromComponent(SnackBarComponent, snackBarConfig);
    }

    /**
     * Generic static method to call an instance of an error snackBar
     * @param message displayed text on the snackBar
     * @returns {MatSnackBarRef<SnackBarComponent>}
     */
    public openAsError(message:string) : MatSnackBarRef<any> {
        let snackBarConfig = this.getSnackbarConfig();

        snackBarConfig.data = {
            message: message,
            snackBarTheme: SnackBarTheme.Error
        };

        return this.snackBar.openFromComponent(SnackBarComponent, snackBarConfig);
    }

    /**
     * Gets the default configurations for the snackbar
     * @returns {MatSnackBarConfig}
     */
    private getSnackbarConfig() : MatSnackBarConfig {
        let snackBarConfig = new MatSnackBarConfig();

        //Generic configuration
        snackBarConfig.duration = 3000;
        snackBarConfig.verticalPosition = 'top';
        snackBarConfig.horizontalPosition = 'right';
        snackBarConfig.panelClass = ['mat-snack-bar-container--wep-snack-bar'];

        return snackBarConfig;
    }
}
