import { BaseModel } from '~/models/baseModel';

export class CertificateModel extends BaseModel {
    public certificateId:number;
    public certificateName:string;
    public fileUrl:string;
    public uploadedFileName:string;

    set fileName(value:string) {
        this.uploadedFileName = value;
    }

    get fileName() : string {
        return this.uploadedFileName;
    }
}
