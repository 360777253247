import { BaseModel } from '~/models/baseModel';
import { Moment } from '~/decorators/moment/momentDecorator';
import * as moment from 'moment';

export enum TenderCatalogModelStatuses {
    Draft = 'Draft',
    Open = 'Open',
    Closed = 'Closed'
};

export class TenderCatalogModel extends BaseModel {
    public id:number;
    public name:string;

    @Moment()
    public startDate:moment.Moment | string;
    @Moment()
    public endDate:moment.Moment | string;
    public startTime:string;
    public startTimeFormatted:string;
    public endTime:string;
    public endTimeFormatted:string;
    @Moment()
    public start:moment.Moment | string;
    @Moment()
    public end:moment.Moment | string;
    public status:TenderCatalogModelStatuses;

    public endCountDown:number;
    public startCountDown:number;
    public brokerEntityId:number;
    public ownerEntityId:number;
}
