import { BaseModel } from '~/models/baseModel';

export enum SettingScope {
    Global = 'Global',
    Specie = 'Specie'
}

export class SettingModel extends BaseModel {
    public value:string;
    public scope:SettingScope;
}
