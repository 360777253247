import { BaseModel } from '~/models/baseModel';

export class WoolClipModel extends BaseModel {
    public brandId:number;
    public linkingCode:string;
    public contactId:number;
    public woolClipLinked:string;
    public expiryDateTime:string;
    public isExpired:boolean;

    get isLinked() { return (this.linkingCode && this.linkingCode.length > 0 && this.woolClipLinked); }
}
