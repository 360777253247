
export enum SignalRMethodsEnum {
    NewNotification = 'NewNotification',
    UserNotification = 'UserNotification',
    P2PMessages = 'P2PMessages',
    OfferUpdate = 'OfferUpdate',
    AuctionUpdate = 'AuctionUpdate',
    AuctionCountDownUpdate = 'AuctionCountDownUpdate',
    AuctionBlockUpdate = 'AuctionBlockUpdate'
}
