import { FormBuilder, FormGroup, FormControl, ValidatorFn } from '@angular/forms';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';

export interface AgGridCellEditorParams extends ICellEditorParams {
    validators?: ValidatorFn | ValidatorFn[]; //Validators for the Cell value
    validatorsForRowNodeData?: ValidatorFn | ValidatorFn[]; //Validators for the rowNode value (which is relevant to this Cell)
}

export class AgGridCellEditorAbstractComponent implements ICellEditorAngularComp {
    public params: any;
    public formGroup:FormGroup;
    public formBuilder:FormBuilder;

    constructor(formBuilder:FormBuilder) {
        this.formBuilder = formBuilder;
    }

    public agInit(params: any) : void {
        this.params = params;

        this.formGroup = this.formBuilder.group({
            cell: new FormControl()
        });

        this.cellFormControl.setValue(this.params.value);

        //Validate against cell
        if(this.params.validators) {
            this.cellFormControl.setValidators(this.params.validators);
        }

        //Validate against rowNode validators
        if(this.params.validatorsForRowNodeData) {
            this.formGroup.addControl('rowData', new FormControl());
            this.rowDataFormControl.setValue(this.params.node.data);
            this.rowDataFormControl.setValidators(this.params.validatorsForRowNodeData);
            this.rowDataFormControl.updateValueAndValidity();
            if(this.rowDataFormControl.invalid) {
                this.params.eGridCell.classList.add('ag-cell-value-invalid');
            }
            else {
                this.params.eGridCell.classList.remove('ag-cell-value-invalid');
            }
        }
    }

    public get rowDataFormControl() { return this.formGroup.controls['rowData']; }
    public get cellFormControl() { return this.formGroup.controls['cell']; }

    public getValue(): any {
        this.markAsDirtyAndTouched();
        this.updateClasses();
        return this.cellFormControl.value;
    }

    public refresh(params: any): boolean {
        this.params = params;
        return true;
    }

    public onBlur() : void {
        this.params.api.stopEditing(false);
    }

    public markAsDirtyAndTouched() : void {
        if(this.rowDataFormControl) {
            this.rowDataFormControl.markAsDirty();
            this.rowDataFormControl.markAsTouched();
        }
        this.cellFormControl.markAsDirty();
        this.cellFormControl.markAsTouched();
    }

    public updateClasses() : void {
        if(this.formGroup.touched &&
            this.formGroup.invalid) {
            this.params.eGridCell.classList.add('ag-cell-value-invalid');
        }
        else {
            this.params.eGridCell.classList.remove('ag-cell-value-invalid');
        }
    }
}