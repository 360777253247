import { Injectable } from '@angular/core';
import { EpiAbstractService } from '~/services/api/web/epi/epiAbstractService';
import { HttpResponse } from '@angular/common/http';
import { HttpClientWrapper } from '~/services/api/httpClientWrapper';
import { ResponseModel } from '~/models/responseModel';
import { BaseWebService } from '~/services/api/web/base/baseWebService';
import { EpiModel } from '~/models/epiModel';
import { Observable } from 'rxjs';

@Injectable()
export class EpiService extends BaseWebService implements EpiAbstractService {

    public static readonly PATH:string = '/epi';

    constructor(http:HttpClientWrapper) {
        super(http);
    }

    public getEpi(entityId:number) : Observable<ResponseModel> {
        let url:string = EpiService.URL + EpiService.PATH + '/entities/' + entityId.toString();
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return new EpiModel(response['data']);
        });
    }

}
