import { BaseModel } from '~/models/baseModel';
import { Moment } from '~/decorators/moment/momentDecorator';
import * as moment from 'moment';

export class MarketValueItemModel extends BaseModel {
    @Moment()
    public date:moment.Moment;
    public unitPrice:number;
    public totalPrice:number;
    public clnPrice:number;
    public isReal:boolean;
    public salePrice:number;
}
