import { Component, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { isFunction as _isFunction } from 'lodash';

export interface AgGridCellRendererCheckboxParams extends ICellRendererParams {
    isDisabled?: (params) => boolean;
}

@Component({
    selector: 'wep-ag-grid-cell-renderer-checkbox',
    templateUrl: './checkbox.html',
    host: { 'class' : 'wep-ag-grid-cell-renderer-checkbox' },
    styleUrls: ['./checkbox.scss']
})

export class AgGridCellRendererCheckboxComponent implements ICellRendererAngularComp, OnDestroy {
    public params: any;
    private changeDetectorRef:ChangeDetectorRef;

    constructor(changeDetectorRef:ChangeDetectorRef) {
        this.changeDetectorRef = changeDetectorRef;
    }

    public agInit(params: any) : void {
        this.params = params;
        if(params.node) {
            this.params.node.setSelected(params.node.isSelected());
        }
    }

    public onClick() : void {
        let toggle = !this.params.node.isSelected();
        this.params.node.setSelected(toggle);

        if(_isFunction(this.params.clickEvent)) {
            this.params.clickEvent(this.params, this);
        }
        this.changeDetectorRef.detectChanges();
    }

    public isDisabled() : boolean {
        if(_isFunction(this.params.isDisabled)) {
            return this.params.isDisabled(this.params);
        }
        return false;
    }

    public refresh() : boolean {
        return false;
    }

    public ngOnDestroy() : void {
        this.changeDetectorRef.detach();
    }
}