import { ClipModel } from '~/models/clipModel';
import { ImportBrandModel } from '~/models/importBrandModel';
import { ImportBaleModel } from '~/models/importBaleModel';
import { map as _map } from 'lodash';
import { BaseModel } from './baseModel';
import { Moment } from '~/decorators/moment/momentDecorator';
import * as moment from 'moment';

export class ImportLotModel extends BaseModel {
    public import:ImportBrandModel;
    public clips:ClipModel[];
    public bales:ImportBaleModel[];

    public lotId:number;
    public lh_code:number;
    public intrefno:number;
    public folio:number;
    public broker:string;
    public lh_stloc:string;
    public lh_sale:string;
    public lh_season:string;
    public lh_bales:number;
    public lh_rnetkg:number;
    public lh_pprice:number;
    public buyer:string;
    public lh_typec:string;
    public wooltype:string;
    public resprice:number;
    public valprice:number;

    @Moment()
    public datesold:moment.Moment;
    public soldprice:number;
    public grossvalue:number;

    @Moment()
    public proceedsda:moment.Moment;
    public bh_brandp:string;
    public bh_descp:string;
    public ct_mic:number;
    public ct_vmb:number;
    public ct_vm1:number;
    public ct_vm2:number;
    public ct_vm3:number;
    public ct_wbase:number;
    public acy:number;
    public sdr1:number;
    public ct_slen:number;
    public ct_slencv:number;
    public ct_str:number;
    public ct_pobt:number;
    public ct_pobm:number;
    public ct_pobb:number;
    public ct_haut:number;
    public l2_mules:string;
    public ct_lscvm:number;
    public ct_lssdm:number;
    public ct_lscomft:number;
    public ct_lscurmn:number;
    public linetype:string;
public status:string;
    public jcsy:number;
    public cert2:string;
    public cert1:string;
    public lh_pcode:number;

    constructor(values: Object = {}) {
        super(values);

        this.bales = _map(values['bales'], (bale) => {
            return new ImportBaleModel(bale);
        });
    }
}

