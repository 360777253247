import { OrderModel } from '~/models/orderModel';
import { BaseModel } from './baseModel';

export class InventoryOrderModel extends OrderModel {
	public combinationCertificateId: string;
	public combinationCertificateDate: string;
	public bookingReference: string; 
	public buyerName: string;
	public dateDelivery: string;
	public datePickup: string;
	public dumpLocation: string;
	public finalDestination: string;
	public portOfDischarge: string;

    public combinationCoreTestResult: CombinationCoreTestModel;

    constructor(values: Object = {}) {
        super(values);
    }
}

export class CombinationCoreTestModel extends BaseModel {
	public woolBase: number;
	public meanFibreDiameter: number;
	public coefficientOfVariation: number;
	public vmb: number;
	public yield1: number;
	public yield2: number;
	public yield3: number;
	public yield4: number;
	public yield5: number;
	public cleanWeight1: number;
	public cleanWeight2: number;
	public cleanWeight3: number;
	public cleanWeight4: number;
	public cleanWeight5: number;

    constructor(values: Object = {}) {
        super(values);
    }
}