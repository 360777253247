import { Injectable } from '@angular/core';

let intercomSettings;

interface IntercomSettings {
    app_id?:string;
    name:string;
    email:string;
    created_at?:number;
    user_id:number;
}

/**
 * Refer to: https://developers.intercom.com/docs/single-page-app
 */
@Injectable()
export class IntercomService {

    private APP_ID:string = process.env['INTERCOM_APP_ID'];

    constructor() {
        this.loadScript();
    }

    public loadScript() : void {
        let w = window;
        let ic = w['Intercom'];
        if(typeof ic === 'function') {
            ic('reattach_activator');
            ic('update', intercomSettings);
        }
        else {
            let d = document;
            let i = function() {
                i['c'](arguments);
            };
            i['q'] = [];
            i['c'] = (args) => {
                i['q'].push(args);
            };
            w['Intercom'] = i;

            let l = () => {
                let s = d.createElement('script');
                s.type = 'text/javascript';
                s.async = true;
                s.src = 'https://widget.intercom.io/widget/' + this.APP_ID;
                let x = d.getElementsByTagName('script')[0];
                x.parentNode.insertBefore(s,x);
            };
            if(w['attachEvent']){
                w['attachEvent']('onload', l);
            }
            else{
                w.addEventListener('load', l, false);
            }
        }
    }

    public boot(intercomSettings:IntercomSettings) : void {
        window['Intercom']('boot', {
            app_id : this.APP_ID,
            email : intercomSettings.email,
            name: intercomSettings.name,
            user_id: intercomSettings.user_id
        });
    }

    public shutdown() : void {
        window['Intercom']('shutdown');
    }
}