import { BaseModel } from '~/models/baseModel';
import { InventoryClipModel } from '~/models/inventoryClipModel';
import { InventorySpecieModel } from '~/models/inventorySpecieModel';
import { InventoryBaleModel } from '~/models/inventoryBaleModel';

export class MobRelatedItemsModel extends BaseModel {
    public bales:InventoryBaleModel[];
    public species:InventorySpecieModel[];
    public clip:InventoryClipModel;

    constructor(values?:{}) {
        super(values);

        if(!this.bales) {
            this.bales = [];
        }
        if(!this.species) {
            this.species = [];
        }
        if(!this.clip) {
            this.clip = null;
        }
    }
}