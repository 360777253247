import { BaseModel } from '~/models/baseModel';
import { Moment } from '~/decorators/moment/momentDecorator';
import * as moment from 'moment';

export class ForwardMarketValueModel extends BaseModel {
    @Moment()
    public date:moment.Moment;
    public bidPrice:number;
    public offerPrice:number;
}
