// Models
import { BaseModel } from '~/models/baseModel';
import { FaqItemModel } from '~/models/epi/faqItemModel';
import { VideoLinkModel } from '~/models/epi/videoLinkModel';
import { ContentLinkModel } from '~/models/epi/contentLinkModel';

// Moment
import { Moment } from '~/decorators/moment/momentDecorator';
import * as moment from 'moment';

// Lodash
import { forEach as _forEach } from 'lodash';

export class TutorialDetailModel extends BaseModel {

    public id:number;

    public url:string;
    public name:string;
    public urlSegment:string;
    public thumbnailUrl:string;

    @Moment()
    public publicationDate:moment.Moment;

    get title() : string {
        return this.name;
    }

    public hasThumb() : boolean {
        return (this.thumbnailUrl && this.thumbnailUrl.length > 0);
    }

    public summary:string;
    public description:string;
    public videos:VideoLinkModel[] = [];
    public factSheets:ContentLinkModel[] = [];
    public userGuides:ContentLinkModel[] = [];
    public faqs:FaqItemModel[] = [];

    constructor(values?:{}) {
        super(values);

        if(typeof values !== 'undefined' &&
           typeof values['faqs'] !== 'undefined' &&
           values['faqs'] &&
           values['faqs'].length > 0) {
            let faqs:FaqItemModel[] = [];

            _forEach(values['faqs'], (data:{}) => {
                let faq:FaqItemModel = new FaqItemModel(data);

                faqs.push(faq);
            });

            this.faqs = faqs;
        }

        if(typeof values !== 'undefined' &&
           typeof values['videos'] !== 'undefined' &&
           values['videos'] &&
           values['videos'].length > 0) {
            let videos:VideoLinkModel[] = [];

            _forEach(values['videos'], (data:{}) => {
                let video:VideoLinkModel = new VideoLinkModel(data);

                videos.push(video);
            });

            this.videos = videos;
        }

        if(typeof values !== 'undefined' &&
           typeof values['factSheets'] !== 'undefined' &&
           values['factSheets'] &&
           values['factSheets'].length > 0) {
            let factSheets:ContentLinkModel[] = [];

            _forEach(values['factSheets'], (data:{}) => {
                let item:ContentLinkModel = new ContentLinkModel(data);

                factSheets.push(item);
            });

            this.factSheets = factSheets;
        }

        if(typeof values !== 'undefined' &&
           typeof values['userGuides'] !== 'undefined' &&
           values['userGuides'] &&
           values['userGuides'].length > 0) {
            let userGuides:ContentLinkModel[] = [];

            _forEach(values['userGuides'], (data:{}) => {
                let item:ContentLinkModel = new ContentLinkModel(data);

                userGuides.push(item);
            });

            this.userGuides = userGuides;
        }
    }
}
