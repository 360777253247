import { BaseModel } from '~/models/baseModel';
import { OfferLotModel } from '~/models/offerLotModel';

export class AuctionLotModel extends BaseModel {
    public auctionCatalogId:number;
    public buynow:number;
    public offerId:number;
    public reserve:number;
    public startPrice:number;
    public currentBid:number;
    public heldBy:number;
    public lot:OfferLotModel;

    constructor(values:Object = {}) {
        super(values);
        if(values['lot']) {
            this.lot = new OfferLotModel(values['lot']);
        }
    }
}
