import { Injectable, OnDestroy } from '@angular/core';
import { SocketAbstractService } from '~/services/api/web/socket/socketAbstractService';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import { SignalRMethodsEnum } from '~/constants/enums/signalRMethodsEnum';
import { AuthTokenModel } from '~/models/authentication/authTokenModel';
import { NotificationModel } from '~/models/notificationModel';
import { AuthService } from '~/services/api/auth/authService';
import { MessageModel } from '~/models/messageModel';
import { OfferModel } from '~/models/offerModel';
import { OfferLotModel } from '~/models/offerLotModel';
import { AuctionCatalogModel } from '~/models/auctionCatalogModel';
import { SearchModel } from '~/models/searchModel';
import { FilterModel } from '~/models/filterModel';
import { forEach as _forEach, forOwn as _forOwn, filter as _filter } from 'lodash';
import { StringFilterModel } from '~/models/stringFilterModel';
import { NumberFilterModel } from '~/models/numberFilterModel';
import { HttpTransportType } from '@aspnet/signalr';
import { AuctionCountdownModel } from '~/models/auctionCountdownModel';
import { Dialog } from '~/components/dialog/dialog';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

@Injectable({
    providedIn: 'root',
  })
export class SocketService implements SocketAbstractService, OnDestroy {

    public static readonly NOTIFICATION_HUB_URL = process.env['NOTIFICATION_HUB_URL'];
    private readonly HubConnectionTimeout:number = 60000;
    private readonly ReconnectInterval:number = 10000;
    private _hubConnection: HubConnection;
    private authService:AuthService;
    private userNotificationSubject:BehaviorSubject<NotificationModel> = new BehaviorSubject<NotificationModel>(null);
    private userPrivateMessageSubject:BehaviorSubject<MessageModel> = new BehaviorSubject<MessageModel>(null);
    private userP2PMessageSubject:BehaviorSubject<MessageModel> = new BehaviorSubject<MessageModel>(null);
    private offerUpdateSubject:Subject<OfferModel[]> = new Subject<OfferModel[]>();
    private auctionUpdateSubject:Subject<AuctionCatalogModel> = new Subject<AuctionCatalogModel>();
    private auctionCountDownUpdateSubject:Subject<AuctionCountdownModel> = new Subject<AuctionCountdownModel>();
    private auctionBlockUpdateSubject:Subject<boolean> = new Subject<boolean>();

    private dialog:MatDialog;

    constructor(authService:AuthService, dialog:MatDialog) {
        this.authService = authService;
        this.dialog = dialog;
        if (!process.env['APP_DEPLOYMENT']['isDev']){
            this.connectNotificationHub();
        }
    }

    public connectNotificationHub() : void {
        //@TODO: make auth token to behavior subject so websocket can disconnect and reconnect when token is refreshed
        let authToken:string = this.authService.getAuthToken();
        let options = {
            accessTokenFactory: () => {
                return authToken;
            },
            transport: HttpTransportType.WebSockets
        };

        this._hubConnection = new HubConnectionBuilder().withUrl(SocketService.NOTIFICATION_HUB_URL, options).build();
        this._hubConnection.serverTimeoutInMilliseconds = this.HubConnectionTimeout;

        this._hubConnection.start()
            .then(() => {
                console.log('SocketService::info Connection Started');
            })
            .catch((_error:string) => {
                this.authService.hasValidAuthToken().then((isValid) => {
                    if (!isValid){
                        return;
                    }

                    let dialogConfig:MatDialogConfig = new MatDialogConfig();
    
                    dialogConfig.data = {
                        title : 'Connection lost',
                        text: 'It looks like you have lost your internet connection. Please check your connection and refresh the page.',
                        closeText: 'Close and refresh',
                    };
    
                    let dialogRef:MatDialogRef<Dialog> = this.dialog.open(Dialog, dialogConfig);
    
                    dialogRef.afterClosed().subscribe(() => {
                        location.reload();
                    });
                })
            });

        this._hubConnection.onclose(() => {
            this._hubConnection.off(SignalRMethodsEnum.UserNotification);
            this._hubConnection.off(SignalRMethodsEnum.P2PMessages);
            this._hubConnection.off(SignalRMethodsEnum.OfferUpdate);
            this._hubConnection.off(SignalRMethodsEnum.AuctionCountDownUpdate);
            this._hubConnection.off(SignalRMethodsEnum.AuctionBlockUpdate);
            this._hubConnection.stop();
            this.reconnectNotification();
        });

        this._hubConnection.on(SignalRMethodsEnum.UserNotification, (data:NotificationModel) => {
            this.userNotificationSubject.next(data);
        });
        this._hubConnection.on(SignalRMethodsEnum.P2PMessages, (data:MessageModel) => {
            this.userPrivateMessageSubject.next(data);
        });
        this._hubConnection.on(SignalRMethodsEnum.OfferUpdate, (data:OfferModel[]) => {
            this.offerUpdateSubject.next(data);
        });
        this._hubConnection.on(SignalRMethodsEnum.AuctionUpdate, (data:AuctionCatalogModel) => {
            this.auctionUpdateSubject.next(data);
        });
        this._hubConnection.on(SignalRMethodsEnum.AuctionCountDownUpdate, (data:AuctionCountdownModel) => {
            this.auctionCountDownUpdateSubject.next(new AuctionCountdownModel(data));
        });
        this._hubConnection.on(SignalRMethodsEnum.AuctionBlockUpdate, (data:boolean) => {
            this.auctionBlockUpdateSubject.next(data);
        });
    }

    public getOfferUpdate() : Observable<OfferModel[]> {
        return this.offerUpdateSubject.asObservable();
    }

    public getAuctionUpdate() : Observable<AuctionCatalogModel> {
        return this.auctionUpdateSubject.asObservable();
    }

    public getAuctionCountDownUpdate() : Observable<AuctionCountdownModel> {
        return this.auctionCountDownUpdateSubject.asObservable();
    }

    public getAuctionBlockUpdate() : Observable<Boolean> {
        return this.auctionBlockUpdateSubject.asObservable();
    }

    public getUserNotification() : Observable<NotificationModel> {
       return this.userNotificationSubject.asObservable();
    }

    public getPrivateMessage() : Observable<MessageModel> {
        return this.userPrivateMessageSubject.asObservable();
    }

    public getP2PMessage() : Observable<MessageModel> {
        return this.userP2PMessageSubject.asObservable();
    }

    public subscribeP2PMessage(methods:string) : void {
        if(this._hubConnection) {
            this._hubConnection.on(methods,(data:any) => {
                this.userP2PMessageSubject.next(data);
            });
        }
    }

    public unsubscribeP2PMessage(methods:string) : void {
        if(this._hubConnection) {
            this._hubConnection.off(methods);
        }
    }

    public postMessage(message:MessageModel) : Promise<void> {
        let result = Promise.resolve();
        if(this._hubConnection) {
           result = this._hubConnection.invoke('Send',message);
        }
        return result;
    }

    public reconnectNotification() : void {
        setTimeout(() => {
            this.connectNotificationHub();
        },this.ReconnectInterval);
    }

    public isFiltered(lot:OfferLotModel, search:SearchModel) : boolean {
        let filtered = true;
        _forOwn(search, (_value,key) =>{
            let first = _filter(search[key], (filter) => { return !filter.logic; });
            let result = (first && first.length > 0) ? this.getComparisonOperator(lot,key,first[0]) : true;
            let filters = _filter(search[key], (filter) => { return !!filter.logic; });
            _forEach(filters, (filter) => {
                result = this.getLogicalOperator(filter.logic, result, this.getComparisonOperator(lot, key, filter));
            });
            filtered = filtered && result;
        });
        return filtered;
    }

    private getComparisonOperator(obj:OfferLotModel, property:string, filter:FilterModel) : boolean {
        switch(filter.condition) {
            case 'EqualTo':
                return obj[property] ===  (filter as StringFilterModel).valueFrom;
            case 'GreaterThan':
                return filter.isNumeric() && obj[property] > (filter as NumberFilterModel).valueFrom;
            case 'LessThan':
                return filter.isNumeric() && obj[property] < (filter as NumberFilterModel).valueTo;
            case 'Not':
                return obj[property] !== (filter as StringFilterModel).valueFrom;
            default:
                return obj[property].toString().indexOf((filter as StringFilterModel).valueFrom) > -1;
        }
    }

    private getLogicalOperator(operator:string, leftValue:boolean, rightValue:boolean) : boolean {
        switch(operator) {
            case 'And':
                return leftValue && rightValue;
            case 'Or':
                return leftValue || rightValue;
            default:
                return rightValue;
        }
    }

    public ngOnDestroy() : void {
        this._hubConnection.off(SignalRMethodsEnum.UserNotification);
        this._hubConnection.off(SignalRMethodsEnum.P2PMessages);
        this._hubConnection.off(SignalRMethodsEnum.OfferUpdate);
        this._hubConnection.off(SignalRMethodsEnum.AuctionCountDownUpdate);
        this._hubConnection.off(SignalRMethodsEnum.AuctionBlockUpdate);
        this._hubConnection.off(SignalRMethodsEnum.AuctionUpdate);
        this._hubConnection.stop();
    }
}
