import { Injectable } from '@angular/core';
import { BrandAbstractService } from '~/services/api/web/brand/brandAbstractService';
import { BaseWebService } from '~/services/api/web/base/baseWebService';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { HttpClientWrapper } from '~/services/api/httpClientWrapper';
import { ResponseModel } from '~/models/responseModel';
import { BrandModel } from '~/models/brandModel';
import { Observable } from 'rxjs';
import { map as _map } from 'lodash';
import { EntityModel } from '~/models/entityModel';
import { BrandAttributesViewModel } from '~/models/brandAttributeModel';
import { QualitySchemasModel } from '~/models/constants/qualitySchemas';
import { QualitySchemeModel } from '~/models/qualitySchemeModel';
import { QualitySchemeEligibilityModel } from '~/models/qualitySchemeEligibilityModel';
import { AttributesModel } from '~/models/attributesModel';

@Injectable()
export class BrandService extends BaseWebService implements BrandAbstractService {

    public static readonly PATH:string = '/brands';

    constructor(http:HttpClientWrapper) {
        super(http);
    }

    public getBrands() : Observable<ResponseModel> {
        let url:string = BrandService.URL + BrandService.PATH;
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (brand) => new BrandModel(brand));
        });
    }

    public getBrandAttributes() : Observable<ResponseModel> {
        let url:string = BrandService.URL + BrandService.PATH + '/attributes';
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return new BrandAttributesViewModel(response['data']);
        });
    }


    public getPrefillBrand(entityId:number)  : Observable<ResponseModel> {
        let url:string = BrandService.URL + BrandService.PATH + '/prefill_from_entity?entityId=' + entityId.toString();
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return new BrandModel(response['data']);
        });
    }

    public getBrandById(brandId:number) : Observable<ResponseModel> {
        let url:string = BrandService.URL + BrandService.PATH + '/' + brandId.toString();
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return new BrandModel(response['data']);
        });
    }

    public searchContacts(item:BrandModel|number, query:string) : Observable<ResponseModel> {
        let brandId = (item instanceof BrandModel) ? item.brandId : item;
        let url:string = BrandService.URL + BrandService.PATH + '/' + brandId.toString() + '/searchcontacts?nameoremail=' + encodeURIComponent(query);
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (entity) => new EntityModel(entity));
        });
    }

    public getBrandsByEntity(entity:EntityModel, canEdit:boolean) : Observable<ResponseModel> {
        let url:string = BrandService.URL + BrandService.PATH;

        let params = new HttpParams();

        params = params.append('entityId', entity.entityId.toString());

        if(canEdit) {
            params = params.append('canEdit', 'true');
        }
        else {
            params = params.append('canEdit', 'false');
        }

        let options:{} = {
            params : params
        };

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (brand) => new BrandModel(brand));
        });
    }

    public postBrand(brand:BrandModel) : Observable<ResponseModel> {
        let url:string = BrandService.URL + BrandService.PATH;
        let body:any = brand;
        let options:{} = {};

        return this.http.post(url, body, options, (response:HttpResponse<any>) => {
            return new BrandModel(response['data']);
        });
    }

    public postSaveProfile(brand:BrandModel) : Observable<ResponseModel> {
        let url:string = BrandService.URL + BrandService.PATH + '/save_profile';
        let body:any = brand;
        let options:{} = {};

        return this.http.post(url, body, options, (response:HttpResponse<any>) => {
            return new BrandModel(response['data']);
        });
    }

    public claimBrand(tempBrandId:number, brand:BrandModel) : Observable<ResponseModel> {
        let url:string = BrandService.URL + BrandService.PATH + '/' + brand.brandId.toString() + '/claim?tempBrandId=' + tempBrandId;
        let body:any = null;
        let options:{} = {};

        return this.http.post(url, body, options, (response:HttpResponse<any>) => {
            return new BrandModel(response['data']);
        });
    }

    public toggleActivate(brand:BrandModel) : Observable<ResponseModel> {
        let url:string = BrandService.URL + BrandService.PATH + '/' + brand.brandId.toString() + '/activate';
        let body:any = brand;
        let options:{} = {};

        return this.http.post(url, body, options, (response:HttpResponse<any>) => {
            return new BrandModel(response['data']);
        });
    }

    public getQualitySchemeEligibility(brandId: number, attributes: AttributesModel) : Observable<ResponseModel> {
        let url:string = BrandService.URL + BrandService.PATH + '/' + brandId.toString() + '/qualityschemeeligibility';
        let body:any = attributes;
        let options:{} = {};

        return this.http.post(url, body, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (scheme) => new QualitySchemeEligibilityModel(scheme));
        });
    }
}
