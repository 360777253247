import { isNil as _isNil, isError as _isError, attempt as _attempt } from 'lodash';
import { UserPreferenceModel } from '~/models/userPreferenceModel';

export class UserPreferenceModelDataParser {
    public static parseNumberValue(userPreferenceModel:UserPreferenceModel<number>) : UserPreferenceModel<number> {
        userPreferenceModel.value = !_isNil(userPreferenceModel.value) ? +userPreferenceModel.value : null;
        return userPreferenceModel;
    }

    /**
     * Formats the userPreferenceModel value attribute as a boolean value
     * @param {} userPreferenceModel
     * @returns {}
     */
    public static parseBooleanValue(userPreferenceModel:UserPreferenceModel<boolean> ) : UserPreferenceModel<boolean> {
        let value = !_isNil(userPreferenceModel.value) ? +userPreferenceModel.value : null;

        if(value) {
            userPreferenceModel.value = value === 1 ? true : false;
        }
        return userPreferenceModel;
    }

    /**
     * Formats the userPreferenceModel value attribute as a JSON object value (from a JSON string)
     * @param {} userPreferenceModel
     * @returns {}
     */
    public static parseObjectValue(userPreferenceModel:UserPreferenceModel<Object>) : UserPreferenceModel<Object> {
        let value = userPreferenceModel.value;
        if(!_isNil(value) && !_isError(_attempt(JSON.parse, value.toString()))) {
            userPreferenceModel.value = JSON.parse(userPreferenceModel.value.toString());
        }
        else {
            userPreferenceModel.value = null;
        }
        return userPreferenceModel;
    }

    /**
     * Formats the userPreferenceModel value attribute as a string value
     * @param {} userPreferenceModel
     * @returns {}
     */
    public static parseStringValue(userPreferenceModel:UserPreferenceModel<string>) : UserPreferenceModel<string> {
        userPreferenceModel.value = !_isNil(userPreferenceModel.value) ? userPreferenceModel.value : null;
        return userPreferenceModel;
    }
}