import { Injectable } from '@angular/core';
import { GroupAbstractService } from '~/services/api/web/group/groupAbstractService';
import { HttpResponse, HttpParams } from '@angular/common/http';
import { HttpClientWrapper } from '~/services/api/httpClientWrapper';
import { BaseWebService } from '~/services/api/web/base/baseWebService';
import { ResponseModel } from '~/models/responseModel';
import { Observable } from 'rxjs';
import { EntityModel } from '~/models/entityModel';
import { map as _map } from 'lodash';
import { GroupSubscriberModel } from '~/models/groupSubscriberModel';

@Injectable()
export class GroupService extends BaseWebService implements GroupAbstractService {

    public static readonly PATH:string = '/groups';

    constructor(http:HttpClientWrapper) {
        super(http);
    }

    public postGroup(group:EntityModel) : Observable<ResponseModel> {
        let url:string = GroupService.URL + GroupService.PATH;
        let body:any = group;
        let options:{} = {};

        return this.http.post(url, body, options, (response:HttpResponse<any>) => {
            return new EntityModel(response['data']);
        });
    }

    public subscribeToGroup(groupEntity:EntityModel, subscriberEntity:EntityModel) : Observable<ResponseModel> {
        let url:string = GroupService.URL + GroupService.PATH + '/' + groupEntity.entityId.toString()+ '/subscribers/' + subscriberEntity.entityId.toString();
        let body:any = null;
        let options:{} = {};

        return this.http.post(url, body, options);
    }

    /**
     * Removes the subscriber entity from the group.
     * This is called when an admin wants to remove the subscribed entity from the group
     * @param {} groupEntity
     * @param {} subscriberEntity
     * @returns {Observable<>}
     */
    public removeFromSubscribedGroup(groupEntity:EntityModel, subscriberEntity:EntityModel) : Observable<ResponseModel> {
        let url:string = GroupService.URL + GroupService.PATH + '/' + groupEntity.entityId.toString() + '/subscribers/' + subscriberEntity.entityId.toString() + '/remove';
        let body:any = null;
        let options:{} = {};

        return this.http.post(url, body, options);
    }

    public getSubscribers(groupEntity:EntityModel, approveOnly:boolean = false) : Observable<ResponseModel> {
        let url:string = GroupService.URL + GroupService.PATH + '/' + groupEntity.entityId.toString() + '/subscribers';
        let params = new HttpParams();

        if(approveOnly) {
            params = params.append('approveOnly', 'true');
        }

        let options = {
            params : params
        };

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (groupSubscriber) => new GroupSubscriberModel(groupSubscriber));
        });
    }

    /**
     * Unsubscribes from group as a public facing user.
     * This is called when the public user wants to unsubscribe from a group
     * @param {} groupEntity
     * @param {} subscriberEntity
     * @returns {Observable<>}
     */
    public leaveFromSubscribedGroup(groupEntityId:number, subscriberEntity:EntityModel) : Observable<ResponseModel> {
        let url:string = GroupService.URL + GroupService.PATH + '/' + groupEntityId.toString() + '/subscribers/' + subscriberEntity.entityId.toString() + '/leave';
        let body:any = null;
        let options:{} = {};

        return this.http.post(url, body, options);
    }

    public acceptSubscriptionRequest(groupEntity:EntityModel, subscriberEntity:EntityModel) : Observable<ResponseModel> {
        let url:string = GroupService.URL + GroupService.PATH + '/' + groupEntity.entityId.toString() + '/subscribers/' + subscriberEntity.entityId.toString() + '/accept';
        let body:any = null;
        let options:{} = {};

        return this.http.post(url, body, options);
    }

    public rejectSubscriptionRequest(groupEntity:EntityModel, subscriberEntity:EntityModel) : Observable<ResponseModel> {
        let url:string = GroupService.URL + GroupService.PATH + '/' + groupEntity.entityId.toString() + '/subscribers/' + subscriberEntity.entityId.toString() + '/reject';
        let body:any = null;
        let options:{} = {};

        return this.http.post(url, body, options);
    }

    public cancelSubscriptionRequest(groupEntityId:number, subscriberEntity:EntityModel) : Observable<ResponseModel> {
        let url:string = GroupService.URL + GroupService.PATH + '/' + groupEntityId.toString() + '/subscribers/' + subscriberEntity.entityId.toString() + '/cancel';
        let body:any = null;
        let options:{} = {};

        return this.http.post(url, body, options);
    }

    public setSubscriberAsAdmin(groupEntity:EntityModel, subscriberEntity:EntityModel, newValue:boolean) : Observable<ResponseModel> {
        let url:string = GroupService.URL + GroupService.PATH + '/' + groupEntity.entityId.toString() + '/subscribers/'
            + subscriberEntity.entityId.toString() + '/isadmin/' + newValue;
        let body:any = null;
        let options:{} = {};

        return this.http.post(url, body, options);
    }
}