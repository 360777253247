import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

@Injectable()
export class XLSXExportService {

    constructor() {
    }

    /**
     * Returns an xlsx blob from a CSV string input
     * @param {string} content
     * @returns {Blob}
     */
    public getXLSXBlob(content:string) : Blob {
        let workbook = XLSX.read(content, {type: 'binary'});
        let xlsxContent = XLSX.write(workbook, {bookType: 'xlsx', type: 'base64'});

        return this.b64toBlob(xlsxContent, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    }

    private b64toBlob(b64Data, contentType) : Blob {
        let sliceSize = 512;

        let byteCharacters = atob(b64Data);
        let byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            let slice = byteCharacters.slice(offset, offset + sliceSize);

            let byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            let byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        let blob = new Blob(byteArrays, {type: contentType});
        return blob;
    }
}