import { Directive, Input, ElementRef, Renderer2, OnInit, OnChanges, SimpleChange, AfterViewInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { BaseMaskDirective, NumberMaskSettings } from '~/directives/masks/baseMask/baseMask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { isUndefined as _isUndefined, assign as _assign } from 'lodash';

@Directive({
    host: {
        '(input)': '_handleInput($event.target.value)',
        '(blur)': 'onTouched()'
    },
    selector: '[wepNumberMask]'
})

export class NumberMaskDirective extends BaseMaskDirective implements OnInit, OnChanges, AfterViewInit {

    protected model:NgControl;
    protected elementRef:ElementRef;
    protected defaultMaskSettings:NumberMaskSettings = {
        prefix: '',
        allowDecimal: false,
        includeThousandsSeparator: false,
        thousandsSeparatorSymbol: '',
        returnAsString: false,
        decimalLimit: 2,
        allowLeadingZeroes: true,
        suffix: ''
    };

    @Input('wepNumberMask')
    public settings:NumberMaskSettings = {};

    constructor(renderer2:Renderer2,
                elementRef:ElementRef,
                ngControl:NgControl) {
        super(renderer2, elementRef, ngControl);

        this.elementRef = elementRef;
        this.model = ngControl;
    }

    public ngOnInit() : void {
        //Converts the stringCurrency value to a number value
        this.model.control.valueChanges.subscribe((value) => {
            value = value ? value.toString().trim() : '';

            let viewValue:string;
            let modelValue:number;

            if(!this.settings.allowDecimal) {
                value = value.replace(/[^\d]/g, '');
                modelValue = parseInt(value, 0);
            }
            else {
                value = value.replace(/[^\d\.]/g, '');

                if(/^\d+\.\d?\.?\d?/.test(value)) {
                    value = value.replace(/[.](?=.*[.])/g, ''); //Trims excess decimal points and retains the last decimal point
                }

                let regexExcessDecimals = (excessDecimal) => {
                    return new RegExp('\\.\\d{' + excessDecimal + ',}$');
                };

                if(regexExcessDecimals(this.defaultMaskSettings.decimalLimit + 1).test(value)) {
                    value = value.slice(0, value.lastIndexOf('.') + (this.defaultMaskSettings.decimalLimit + 1)); //Truncates excess digits to the selected decimal point
                }

                let regexValidDecimal = (maxDecimalPlace) => {
                    return new RegExp('^\\d+$|^\\d+\\.$|^\\d+\\.\\d' + '{1,' + maxDecimalPlace + '}');
                };

                if(!!this.defaultMaskSettings.returnAsString === false && regexValidDecimal(this.defaultMaskSettings.decimalLimit).test(value)) {
                    modelValue = parseFloat(value);
                }
            }

            if(value === '' || _isUndefined(value)){
                modelValue = null;
            }

            viewValue = value;

            //Write to the ViewValue
            this.writeValue(viewValue);
            if(!!this.defaultMaskSettings.returnAsString && !_isUndefined(value)) {
                this.model.control.setValue(value, {
                    emitEvent: false,
                    emitModelToViewChange: false,
                    emitViewToModelChange: false
                });
            }
            else if(!_isUndefined(modelValue)) {
                this.model.control.setValue(modelValue, {
                    emitEvent: false,
                    emitModelToViewChange: false,
                    emitViewToModelChange: false
                });
            }
        });
    }

    public ngAfterViewInit() : void {
        this.updateMaskConfig();
    }

    public ngOnChanges(changes: {[propertyName: string]: SimpleChange}) : void {
        if (changes['settings']) {
            this.updateMaskConfig();
        }
    }
    public updateMaskConfig() : void {
        this.settings = _assign(this.defaultMaskSettings, this.settings);

        this.textMaskConfig = {
            mask : createNumberMask(this.settings)
        };

        super.ngOnChanges(null);
    }

}

