import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AgGridCellEditorInputCurrencyComponent } from '~/components/agGrid/cellEditors/input/inputCurrency/inputCurrency';
import { CURRENCY_MASK_SETTINGS_CENTS } from '~/directives/masks/currencyMask/currencyMask';

/**
 * A Cell Editor with a wepCurrencyMask using CURRENCY_MASK_SETTINGS_CENTS for its maskSettings
 */
@Component({
    selector: 'wep-ag-grid-cell-editor-input-currency-cents',
    templateUrl: './../inputCurrency.html',
    host: { 'class' : 'wep-ag-grid-cell-editor-input wep-ag-grid-cell-editor-input-currency-cents' },
    styleUrls: ['./../../input.scss']
})

export class AgGridCellEditorInputCurrencyCentsComponent extends AgGridCellEditorInputCurrencyComponent {

    constructor(formBuilder:FormBuilder) {
        super(formBuilder);
    }

    public agInit(params: any) : void {
        params.maskSettings = CURRENCY_MASK_SETTINGS_CENTS;
        super.agInit(params);
    }
}