import { Injectable } from '@angular/core';

//Defined and must be in sync to _settings.scss
export enum MediaQueryBreakpoints {
    Small = 'small',
    IPhone = 'iphone', //iPhone 6+
    Medium = 'medium',
    IPad = 'ipad', //iPad
    Large = 'large',
    ExtraLarge = 'xlarge'
}

/**
 * Wrapper for the Foundation media query object…
 */
@Injectable()
export class MediaQueryService {
    private mediaQuery:any;

    constructor() {
        this.mediaQuery = window['Foundation'].MediaQuery;
    }

    public atLeast(breakpoint:MediaQueryBreakpoints) : boolean {
        return this.mediaQuery.atLeast(breakpoint);
    }

    public is(breakpoint:MediaQueryBreakpoints) : boolean {
        return this.mediaQuery.atLeast(breakpoint + ' only');
    }
}