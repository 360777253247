import { BaseModel } from '~/models/baseModel';

export class SeasonModel extends BaseModel {
    public micron:string;
    public micronDiff:string;
    public micronAvg:string;
    public vm:string;
    public vmDiff:string;
    public vmAvg:string;
    public nkt:string;
    public nktDiff:string;
    public nktAvg:string;
    public yield:string;
    public yieldDiff:string;
    public yieldAvg:string;
    public indicators:number[];
}
