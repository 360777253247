import { BaseModel } from '~/models/baseModel';
import { EntityModel } from './entityModel';
import * as moment from 'moment';
import { Moment } from '~/decorators/moment/momentDecorator';


export class PartnerModel extends BaseModel {
    public brandPartnerId:number;
    public brandId:number;
    public partnerID:number;
    public partnerPrimaryContactID:number;
    public woolStoreLocationID:number;

    public partnerName:string;
    public brokerRef:string;
    public partnerEmail:string;
    public partnerPrimaryContactName:string;
    public partnerPrimaryContactEmail:string;
    public partnerLogo:string;
    public canAddPartner:boolean;
    public partnerConsent:boolean;
    public businessLogoStoredFileName:string;

    @Moment()
    public shearingDate:moment.Moment;
    @Moment()
    public lastContactedDate:moment.Moment;
    @Moment()
    public remindToContactDate:moment.Moment;

    public grower:EntityModel;
    public partner:EntityModel;

    public printPartnerEntityName() : string {
        let name = '-';

        if (typeof this.partnerName !== 'undefined' &&
            this.partnerName !== '') {
            name = this.partnerName;
        }

        return name;
    }

    public printPrimaryContactDetails() : string {
        let email = '-';

        if (typeof this.partnerEmail !== 'undefined' &&
            this.partnerEmail !== '') {
            email = this.partnerEmail;
        }

        if (typeof this.partnerPrimaryContactEmail !== 'undefined' &&
            this.partnerPrimaryContactEmail !== '') {
            email = this.partnerPrimaryContactEmail;
        }


        if (typeof this.partnerPrimaryContactName !== 'undefined' &&
            this.partnerPrimaryContactName !== '') {
            email = this.partnerPrimaryContactName;
        }

        return email;
    }
}
