<ng-container *ngIf="!isLink(); else linkTpl">
    <button [type]="type"
            class="wep-button__inner"       
            [disabled]="isDisabled"
            (click)="onClick()"
            [attr.title]="title"
            [attr.aria-label]="ariaLabel">
        <ng-container *ngTemplateOutlet="contentOutlet"></ng-container>
    </button>
</ng-container>
<ng-template #linkTpl>
    <a class="wep-button__inner"
       [href]="href"
       (click)="onClick()">
        <ng-container *ngTemplateOutlet="contentOutlet"></ng-container>
    </a>
</ng-template>
<ng-template #contentOutlet>
    <ng-content></ng-content>
</ng-template>
