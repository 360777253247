import { BaseModel } from '~/models/baseModel';
import { TraderModel } from '~/models/traderModel';

import { Moment } from '~/decorators/moment/momentDecorator';

import { map as _map } from 'lodash';

import * as moment from 'moment';

export enum TradingRegistationStatus {
    Awaiting = 'Awaiting',
    Approved = 'Approved',
    Rejected = 'Rejected',
    Cancelled = 'Cancelled',
    Revoked = 'Revoked',
    OnHold = 'OnHold'
}

export class TradingRegistrationModel extends BaseModel {
    public business:string;
    public tradingRequestId:number;
    public entityId:number;
    public contactId:number;
    public brandId:number;
    public tradingName:string;

    public name:string;
    public requestedBy:string;
    public entityType:string;
    public area:string;
    public phoneNumber:string;

    @Moment()
    public requestDate:moment.Moment;

    @Moment()
    public dateJoined:moment.Moment;

    public status:TradingRegistationStatus;

    public trader:TraderModel[];

    constructor(values:Object = {}) {
        super(values);
        if(values['trader']) {
            this.trader = _map(values['trader'], (traderItem) => {
                return new TraderModel(traderItem);
            });
        }

        if(values['traders']) {
            this.trader = _map(values['traders'], (traderItem) => {
                return new TraderModel(traderItem);
            });
        }
    }
}
