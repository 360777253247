import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { ContentEpiAbstractService } from '~/services/api/epi/content/contentEpiAbstractService';
import { HttpClientWrapper } from '~/services/api/httpClientWrapper';
import { BaseEpiService } from '~/services/api/epi/base/baseEpiService';
import { map as _map, find as _find, orderBy as _orderBy, filter as _filter } from 'lodash';

// Models
import { NewsModel } from '~/models/epi/newsModel';
import { TutorialModel } from '~/models/epi/tutorialModel';
import { TutorialDetailModel } from '~/models/epi/tutorialDetailModel';
import { CustomHeaderPageModel } from '~/models/epi/customHeaderPageModel';
import { ResponseModel, ResponseModelCode } from '~/models/responseModel';

// Rxjs
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class ContentEpiService extends BaseEpiService implements ContentEpiAbstractService {

    public static readonly PATH:string = '/Content';

    constructor(http:HttpClientWrapper) {
        super(http);
    }

    public getNewsFromWoolSite(numberOfNewsResults:string) : Observable<ResponseModel> {
        let url:string = `${ContentEpiService.ContentSearchAPIURL}${ContentEpiService.PATH}?filter=ContentType/any(t:t eq \'MediaItemPage\')&orderby=updatedDate desc&personalize=true&top=`+numberOfNewsResults;
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {

            let newsItems:NewsModel[]= _map(response['results'], (item) => new NewsModel(item));  
            if(newsItems!=null)
            {
                return newsItems;
            }         
            else
            {
                return new ResponseModel({
                    code: ResponseModelCode.Error
                });
            }
        });
    }

    public getNews() : Observable<ResponseModel> {
        let url:string = ContentEpiService.URL + ContentEpiService.PATH + '/NewsPages';
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {

            let newsItems:NewsModel[] = _map(response['data'], (item) => new NewsModel(item));

            return _orderBy(newsItems, (newsItem:NewsModel) => {
                return newsItem.publicationDate;
            }, 'desc');
        });
    }

    public getNewsItemById(id:number) : Observable<ResponseModel> {
        let url:string = ContentEpiService.URL + ContentEpiService.PATH + '/NewsPages/' + id.toString();
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return new NewsModel(response['data']);
        });
    }

    public getTutorials() : Observable<ResponseModel> {
        let url:string = ContentEpiService.URL + ContentEpiService.PATH + '/TutorialPages';
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (item) => new TutorialModel(item));
        });
    }

    public getTutorialsByGroupId(id:number) : Observable<ResponseModel> {
        let url:string = ContentEpiService.URL + ContentEpiService.PATH + '/TutorialPages/Group/' + id.toString();
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (item) => new TutorialModel(item));
        });
    }

    public getTutorialById(id:number) : Observable<ResponseModel> {
        let url:string = ContentEpiService.URL + ContentEpiService.PATH + '/TutorialPages/' + id.toString();
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return new TutorialDetailModel(response['data']);
        });
    }

    //@TODO remove this method in the future patch
    public getNewsItemBySegment(segment:string) : Observable<ResponseModel> {
        let url:string = ContentEpiService.URL + ContentEpiService.PATH + '/NewsPages';
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {

            let items:NewsModel[] = _map(response['data'], (item) => new NewsModel(item));

            let foundItem:NewsModel = _find(items, (item:NewsModel) => {
                return item.urlSegment === segment;
            });

            if(foundItem) {
                return foundItem;
            }
            return new ResponseModel({
                code: ResponseModelCode.Error
            });
        }).pipe(
            switchMap((response:ResponseModel) => {
                if(response.code === ResponseModelCode.Ok) {
                    let newsModel = response.data;
                    return this.getNewsItemById(newsModel.id);
                }
                return of(response);
            })
        );
    }

    //@TODO remove this method in the future patch
    public getTutorialItemBySegment(segment:string) : Observable<ResponseModel> {
        let url:string = ContentEpiService.URL + ContentEpiService.PATH + '/TutorialPages';
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {

            let items:TutorialModel[] = _map(response['data'], (item) => new TutorialModel(item));

            let foundItem:TutorialModel = _find(items, (item:TutorialModel) => {
                return item.urlSegment === segment;
            });

            if(foundItem) {
                return foundItem;
            }
            return new ResponseModel({
                code: ResponseModelCode.Error
            });
        }).pipe(
            switchMap((response:ResponseModel) => {
                if(response.code === ResponseModelCode.Ok) {
                    let tutorialItem = response.data;
                    return this.getTutorialById(tutorialItem.id);
                }
                return of(response);
            })
        );
    }

    //@TODO remove this method in the future patch
    public getTutorialsByGroupSegment(segment:string) : Observable<ResponseModel> {
        let url:string = ContentEpiService.URL + ContentEpiService.PATH + '/TutorialPages';
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {

            let items:TutorialModel[] = _map(response['data'], (item) => new TutorialModel(item));

            let foundItem:TutorialModel = _find(items, (item:TutorialModel) => {
                return item.urlSegment === segment;
            });

            if(foundItem) {
                return foundItem;
            }
            return new ResponseModel({
                code: ResponseModelCode.Error
            });
        }).pipe(
            switchMap((response:ResponseModel) => {
                if(response.code === ResponseModelCode.Ok) {
                    let tutorialItem = response.data;
                    return this.getTutorialsByGroupId(tutorialItem.id);
                }
                return of(response);
            })
        );
    }

    /**
     * Returns the custom header pages that match the path
     * @param {string} currentUrl
     * @returns {Observable<>}
     */
    public getCustomHeaderPages(currentUrl:string) : Observable<ResponseModel> {
        let url:string = ContentEpiService.URL + ContentEpiService.PATH + '/CustomHeaderPages';
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _filter(response['data'], (item) => {
                let customHeaderPageModel = new CustomHeaderPageModel(item);
                customHeaderPageModel.id = this.removeBackSlash(customHeaderPageModel.id);

                if(this.isPathMatching(currentUrl, customHeaderPageModel.id)) {
                    return true;
                }
                return false;
            });
        });
    }

    /**
     * Removes the backslash if it is the last character in the string
     * @param {string} value
     * @returns {string}
     */
    private removeBackSlash(value:string) : string {
        return value[value.length - 1] === '/' ? value.substring(0, value.length - 1) : value;
    }

    /**
     * Checks if the currentUrl and customHeadingPath matches
     * @param currentUrl
     * @param {string} customHeadingPath
     * @returns {boolean}
     */
    private isPathMatching(currentUrl, customHeadingPath:string) : boolean {
        let expression = new RegExp('^' + this.removeBackSlash(currentUrl) + '$'); //Ensure exact path match
        return expression.test(customHeadingPath);
    }
}
