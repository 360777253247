import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { OfferStatuses } from '~/models/offerModel';

@Component({
    selector: 'wep-ag-grid-cell-renderer-market-order-book-status',
    templateUrl: './status.html',
    host: { 'class' : 'wep-ag-grid-cell-renderer wep-ag-grid-cell-renderer-market wep-ag-grid-cell-renderer-market-order-book-status' },
    styleUrls: ['./status.scss']
})

export class AgGridCellRendererMarketOrderBookStatusComponent implements ICellRendererAngularComp {
    public params: any;

    public agInit(params: any) : void {
        this.params = params;
    }

    public refresh() : boolean {
        return false;
    }

    public getStatus(value:string) : string {
        if(OfferStatuses[value] === OfferStatuses.NearReserve || +value === OfferStatuses.NearReserve) {
            return 'status--orange';
        }
        if(OfferStatuses[value] === OfferStatuses.OnMarket || +value === OfferStatuses.OnMarket) {
            return 'status--green';
        }

        return 'status--blue';
    }

    public isDisplayableValue(value:string) : boolean {
        return(OfferStatuses[value] === OfferStatuses.NearReserve || +value === OfferStatuses.NearReserve ||
               OfferStatuses[value] === OfferStatuses.OnMarket || +value === OfferStatuses.OnMarket ||
               OfferStatuses[value] === OfferStatuses.Sold || +value === OfferStatuses.Sold ||
               OfferStatuses[value] === OfferStatuses.Unsold || +value === OfferStatuses.Unsold ||
               OfferStatuses[value] === OfferStatuses.SoldWithdraw || +value === OfferStatuses.SoldWithdraw);
    }
}
