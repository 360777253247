import { Directive, Input, ElementRef, Renderer2, OnInit, OnChanges, SimpleChange, AfterViewInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { BaseMaskDirective, NumberMaskSettings } from '~/directives/masks/baseMask/baseMask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { isUndefined as _isUndefined, assign as _assign, isNil as _isNil } from 'lodash';

export const CURRENCY_MASK_SETTINGS_DOLLARS = {
    prefix: '$',
    allowDecimal: true,
    decimalLimit: 2,
    suffix: '',
};

export const CURRENCY_MASK_SETTINGS_CENTS = {
    prefix: '',
    allowDecimal: false,
    suffix: '¢',
    decimalLimit: 0,
};

@Directive({
    host: {
        '(input)': '_handleInput($event.target.value)',
        '(blur)': 'onTouched()'
    },
    selector: '[wepCurrencyMask]'
})

export class CurrencyMaskDirective extends BaseMaskDirective implements OnInit, OnChanges, AfterViewInit {

    protected model:NgControl;
    protected elementRef:ElementRef;
    protected defaultMaskSettings:NumberMaskSettings = CURRENCY_MASK_SETTINGS_DOLLARS;

    @Input('wepCurrencyMask')
    public settings:NumberMaskSettings = {};

    constructor(renderer2:Renderer2,
                elementRef:ElementRef,
                ngControl:NgControl) {
        super(renderer2, elementRef, ngControl);

        this.elementRef = elementRef;
        this.model = ngControl;
    }

    public ngOnInit() : void {
        //Converts the stringCurrency value to a number value
        this.model.control.valueChanges.subscribe((value) => {
            let viewValue:string;
            let modelValue:number;
            let isNegative:boolean = false;

            value = !_isNil(value) ? value.toString().trim() : '';

            //Trim negative characters assuming allowNegative is enabled are enabled
            if(this.settings.allowNegative) {
                value = value.replace(/[^\d\.-]/g, '');
            }
            else {
                value = value.replace(/[^\d\.]/g, '');
            }

            //Remove excess negative character and mark as negative
            if(/^-/.test(value)) {
                isNegative = true;
                value = value.replace('-', '');
            }

            if(/^\d+\.\d?\.?\d?/.test(value)) {
                value = value.replace(/[.](?=.*[.])/g, '');  //Trims excess decimal points and retains the last decimal point
            }

            let regexExcessDecimals = (excessDecimal) => {
                return new RegExp('\\.\\d{' + excessDecimal + ',}$');
            };

            let excessDecimalPoint = this.settings.decimalLimit + 1;

            if(regexExcessDecimals(excessDecimalPoint).test(value)) {
                value = value.slice(0, value.lastIndexOf('.') + (excessDecimalPoint)); //Truncates excess digits to the selected decimal point
            }

            let regexValidDecimal = (maxDecimalPlace) => {
                if(maxDecimalPlace > 1) {
                    return new RegExp('^\\d+$|^\\d+\\.$|^\\d+\\.\\d' + '{1,' + maxDecimalPlace + '}');
                }
                else if(maxDecimalPlace === 1) {
                    return new RegExp('^\\d+$|^\\d+\\.$|^\\d+\\.\\d{1}');
                }
                return new RegExp('^\\d+$|^\\d+\\.$|^\\d+');
            };

            if(regexValidDecimal(this.settings.decimalLimit).test(value)) {
                modelValue = parseFloat(value);

                //Multiple absolute value as the value is a negative one
                if(isNegative) {
                    modelValue *= -1;
                }
            }
            if(value === '' || _isUndefined(value)){
                modelValue = null;
            }

            //Append negative symbol to viewValue
            if(isNegative) {
                viewValue = '-' + value;
            }
            else {
                viewValue = value;
            }

            //Write to the ViewValue
            this.writeValue(viewValue);

            //Write to the modelValue
            if(!_isUndefined(modelValue)) {
                this.model.control.setValue(modelValue, {
                    emitEvent: false,
                    emitModelToViewChange: false,
                    emitViewToModelChange: false
                });
            }
        });
    }

    public ngAfterViewInit() : void {
        this.updateMaskConfig();
    }

    public ngOnChanges(changes: {[propertyName: string]: SimpleChange}) : void {
        if (changes['settings']) {
            this.updateMaskConfig();
        }
    }

    public updateMaskConfig() : void {
        this.settings = _assign(this.defaultMaskSettings, this.settings);

        this.textMaskConfig = {
            mask : createNumberMask(this.settings)
        };

        super.ngOnChanges(null);
    }
}
