import { Injectable } from '@angular/core';
import { forEach as _forEach, map as _map } from 'lodash';
import { HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { BaseWebService } from '../base/baseWebService';
import { HttpClientWrapper } from '../../httpClientWrapper';

@Injectable()
export class RiemannService extends BaseWebService {

    public static readonly PATH: string = '/Riemann';

    constructor(http: HttpClientWrapper) {
        super(http);
    }

    public getScreenData(): Observable<any> {
        let url:string = RiemannService.URL + RiemannService.PATH  + '/screens?id=Wool';
        return this.http.get(url);
    }

    public getProducts() : Observable<any> {
        let url:string = RiemannService.URL + RiemannService.PATH  + '/products';
        return this.http.get(url);
    }

    public getMarketData(names:string) : Observable<any> {
        let url:string = RiemannService.URL + RiemannService.PATH  + '/marketdata';

        let params = new HttpParams();
        params = params.append('names', names);

        let options = {
            params : params
        };

        return this.http.get(url, options);
    }

}
