import { BaseModel } from '~/models/baseModel';

export class DefaultSpeciSettingModel extends BaseModel {
    public customBinCodeList: string;
    public customDescriptionList: string;

    public bins: string[];
    public descs: string[];

    constructor(values: {} = {}) {
        super(values);

        if (values) {
            if (typeof values['bins'] !== 'undefined' || typeof values['descs'] !== 'undefined') {
                this.syncStrings();
            } else {
                this.syncChips();
            }
        }
    }

    private syncChips(): void {
        this.bins = (this.customBinCodeList &&
            this.customBinCodeList.trim().length > 0) ? this.customBinCodeList.split(',') : [];

        this.descs = (this.customDescriptionList &&
            this.customDescriptionList.trim().length > 0) ? this.customDescriptionList.split(',') : [];
    }

    private syncStrings(): void {
        this.customDescriptionList = this.descs.join(',');
        this.customBinCodeList = this.bins.join(',');
    }

    public hasValues(): boolean {
        let hasValues = (this.bins.length > 0);
        hasValues = hasValues || (this.descs.length > 0);

        return hasValues;
    }
}
