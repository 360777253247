import { BaseModel } from '~/models/baseModel';
import { Moment } from '~/decorators/moment/momentDecorator';
import * as moment from 'moment';

export class ImportLiteModel extends BaseModel {
    public id:number;

    @Moment()
    public date:moment.Moment;
}

