import { Injectable } from '@angular/core';
//import { ScrollToService, ScrollToConfigOptions, ScrollToAnimationEasing } from '@akaustav/ngx-scroll-to';
import { assign as _assign } from 'lodash';

export interface SmoothScrollSettings {
    containerId:string;
    offset?:number;
    duration?:number;
    //easing?:ScrollToAnimationEasing;
}

// NOTE THIS CLASS IS COMMENTED OUT UNTIL TICKET https://awidigital.atlassian.net/browse/EP-2592 IS COMPLETED. 
// THE CLASS USES IMPORTS FROM THE DEFUNCT PACKAGE SO IT REMAINS COMMENTED OUT UNTIL REPLACEMENT IS FOUND

/**
 * Wrapper for the ngx-scroll-to library to have control over the smooth scroll
 */
@Injectable()
export class SmoothScrollService {
    //private scrollToService:ScrollToService;
    // private smoothScrollSettings:SmoothScrollSettings = {
    //     containerId: null,
    //     offset: 0,
    //     duration: 650,
    //     //easing: 'easeInQuad',
    // }

    // constructor(scrollToService: ScrollToService) {
    //     this.scrollToService = scrollToService;
    // }

     public setScrollProperties(_smoothScrollSettings:SmoothScrollSettings) {
    //     this.smoothScrollSettings = _assign(this.smoothScrollSettings, smoothScrollSettings);
     }

      public scrollTo(_elementId:string, _offset?: number) : void {
    //     let config: ScrollToConfigOptions = {
    //         container: document.getElementById(this.smoothScrollSettings.containerId),
    //         target: elementId,
    //         duration: this.smoothScrollSettings.duration,
    //         easing: this.smoothScrollSettings.easing,
    //         offset: offset ? offset : this.smoothScrollSettings.offset
    //     }

    //     this.scrollToService.scrollTo(config);
      }
}
