import { BaseModel } from '~/models/baseModel';
import { map as _map } from 'lodash';
import { ImportBrandModel } from '~/models/importBrandModel';
import { ImportClipModel } from './importClipModel';

export class ImportModel extends BaseModel {
    public import:ImportBrandModel;
    public clips:ImportClipModel[];

    constructor(values: Object = {}) {
        super(values);

        this.clips = _map(values['clips'], (clip) => {
            return new ImportClipModel(clip);
        });
    }
}