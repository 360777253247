import { PropertyMapper } from '~/decorators/propertyMapper/propertyMapper';
import { forEach as _forEach, includes as _includes } from 'lodash';

interface KeyValue {
    key:string;
    value:string;
}

/**
 * Passes in a list of keyValues that will create boolean properties
 * The boolean properties are set to true when they are in the array
 *
 * An example of this includes:
 *
 * Inputs:
 * objectContainerKey: 'myObject'
 * keyValues = [ { key : 'x', : value: '1' }, { key : 'y', value : '2' }, { key : 'z', value : '3' }]
 * this.value = ['2', '3']
 *
 * Outputs:
 * this.myObject.x = false;
 * this.myObject.y = true;
 * this.myObject.z = true;
 * this.value = ['2','3']
 *
 * @param {KeyValue[]} keyValues
 * @returns {(target: any, key: string) => void}
 * @constructor
 */
export function ArrayBooleanMapper(objectContainerKey:string, keyValues:KeyValue[]) {
    //Prevents recursive calls for setting array and Boolean Values
    let settingArrayValue:boolean = false;
    let settingBooleanValues:boolean = false;

    return function(target:any, key:string) {

        /**
         * Property mapper for the array value
         */
        PropertyMapper(target, key, (value: any, protoTarget:any) => {
            let valueAsArray = value ? value : [];

            if(!settingArrayValue) {
                settingBooleanValues = true;
                protoTarget[objectContainerKey] = {};
                _forEach(keyValues, (keyValue: KeyValue) => {

                    if (_includes(valueAsArray, keyValue.value)) {
                        protoTarget[objectContainerKey][keyValue.key] = true;
                    }
                    else {
                        protoTarget[objectContainerKey][keyValue.key] = false;
                    }
                });
                settingBooleanValues = false;
            }
            return value;
        });

        /**
         * Property mapper for the object value containing the booleans to toggle
         */
        PropertyMapper(target, objectContainerKey, (value: any, protoTarget:any) => {
            if(!settingBooleanValues) {
                let newValue = [];

                _forEach(keyValues, (keyValue: KeyValue) => {
                    if (value[keyValue.key]) {
                        newValue.push(keyValue.value);
                    }
                });

                settingArrayValue = true;
                protoTarget[key] = newValue;
                settingArrayValue = false;
            }
            return value;
        });
    };
}