import { BaseModel } from '~/models/baseModel';
import { MarketDashboardBidTableItemModel } from '~/models/marketDashboardBidTableItemModel';
import { map as _map } from 'lodash';

export class MarketDashboardModel extends BaseModel {
    public activeBids:MarketDashboardBidTableItemModel[];
    public closedBids:MarketDashboardBidTableItemModel[];
    public watchlist:MarketDashboardBidTableItemModel[];
    public purchasedOffer:MarketDashboardBidTableItemModel[];

    constructor(values: Object = {}) {
        super(values);

        this.activeBids = _map(values['activeBids'], (bidItem) => {
            return new MarketDashboardBidTableItemModel(bidItem);
        });

        this.closedBids = _map(values['closedBids'], (bidItem) => {
            return new MarketDashboardBidTableItemModel(bidItem);
        });

        this.watchlist = _map(values['watchlist'], (bidItem) => {
            return new MarketDashboardBidTableItemModel(bidItem);
        });

        this.purchasedOffer = _map(values['purchasedOffer'], (bidItem) => {
            return new MarketDashboardBidTableItemModel(bidItem);
        });
    }
}
