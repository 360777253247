import { Injectable } from '@angular/core';
import { HttpResponse, HttpParams } from '@angular/common/http';
import { HttpClientWrapper } from '~/services/api/httpClientWrapper';
import { Observable } from 'rxjs';
import { ResponseModel, ResponseModelCode } from '~/models/responseModel';
import { BitlyModel } from '~/models/bitlyModel';

@Injectable()
export class BitlyService {

    public static readonly URL:string = 'https://api-ssl.bitly.com';
    public static readonly BITLY_TOKEN = process.env['BITLY_TOKEN'];

    private http:HttpClientWrapper;

    constructor(http:HttpClientWrapper) {
        this.http = http;
    }

    public shorten(longUrl:string) : Observable<ResponseModel> {
        let url = BitlyService.URL + '/v3/shorten';

        let params = new HttpParams();

        params = params.append('access_token', BitlyService.BITLY_TOKEN);
        params = params.append('longUrl', longUrl);

        let options = {
            params : params
        };

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            let data = new BitlyModel(response['data']);

            return new ResponseModel({
                        code: ResponseModelCode.Ok,
                        data: data
                    });
        });
    }

    public shortenByDomain(longUrl:string) : Observable<ResponseModel> {
        let url = BitlyService.URL + '/v3/shorten';

        let params = new HttpParams();

        params = params.append('access_token', BitlyService.BITLY_TOKEN);
        params = params.append('domain', longUrl);
        params = params.append('longUrl', longUrl);

        let options = {
            params : params
        };

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            let data = new BitlyModel(response['data']);

            return new ResponseModel({
                        code: ResponseModelCode.Ok,
                        data: data
                    });
        });
    }
}
