import { Injectable } from '@angular/core';
import { CertificateAbstractService } from '~/services/api/web/certificate/certificateAbstractService';
import { HttpClient, HttpEvent, HttpRequest, HttpResponse, HttpParams } from '@angular/common/http';
import { HttpClientWrapper } from '~/services/api/httpClientWrapper';
import { BaseWebService } from '~/services/api/web/base/baseWebService';
import { ResponseModel } from '~/models/responseModel';
import { Observable } from 'rxjs';
import { EntityModel } from '~/models/entityModel';
import { CertificateModel } from '~/models/certificateModel';

@Injectable()
export class CertificateService extends BaseWebService implements CertificateAbstractService {

    public static readonly PATH:string = '/entities';

    //@TODO phase this out when upload functions are redone…
    private httpClientLegacy:HttpClient;

    constructor(http: HttpClientWrapper, httpClientLegacy:HttpClient) {
        super(http);
        this.httpClientLegacy = httpClientLegacy;
    }

    public createUploader(entity:EntityModel, certificateName:string) : Function {
        return (file:File) => {
            let _entity = entity;
            let _certificateName = certificateName;

            return this.uploadCertificate(_entity, file, _certificateName);
        };
    }

    public uploadCertificate(entity:EntityModel, file:File, certificateName:string) : Observable<HttpEvent<any>> {

        let url = CertificateService.URL + CertificateService.PATH;
        url += '/' + entity.entityId.toString() + '/upload_certificate';

        let formData = new FormData();
        formData.append('filePayload', file);
        formData.append('certificateName', certificateName);

        let params = new HttpParams();

        const options = {
            params: params,
            reportProgress: true,
        };

        const req = new HttpRequest('POST', url, formData, options);
        return this.httpClientLegacy.request(req);
    }

    public deleteCertificate(certificate:CertificateModel) : Observable<ResponseModel> {

        let url:string = CertificateService.URL + CertificateService.PATH + '/delete_certificate?certificateId=' + encodeURIComponent(certificate.certificateId.toString());
        let body:any = null;
        let options:{} = {};

        return this.http.post(url, body, options, (response:HttpResponse<any>) => {
            return new EntityModel(response['data']);
        });
    }
}
