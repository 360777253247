import { NgModule } from '@angular/core';

//Angular Material 2
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MAT_RIPPLE_GLOBAL_OPTIONS, MatOptionModule, RippleGlobalOptions } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';


const globalRippleConfig: RippleGlobalOptions = {
    disabled: true,
};

@NgModule({
    imports: [
        MatDatepickerModule,
        MatSelectModule,
        MatOptionModule,
        MatAutocompleteModule,
        MatInputModule,
        MatFormFieldModule,
        MatDialogModule,
        MatCheckboxModule,
        MatRadioModule,
        MatSnackBarModule,
        MatMomentDateModule,
        MatSlideToggleModule,
    ],
    providers: [
        {
            provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig
        },
        {provide: MAT_DATE_LOCALE, useValue: 'en-AU'},
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    ],
    exports: [
        MatDatepickerModule,
        MatSelectModule,
        MatOptionModule,
        MatAutocompleteModule,
        MatInputModule,
        MatFormFieldModule,
        MatDialogModule,
        MatCheckboxModule,
        MatRadioModule,
        MatSnackBarModule,
        MatMomentDateModule,
        MatSlideToggleModule,
    ]
})

export class AppMaterialModule {
}

