import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'wep-ag-grid-cell-renderer-link',
    templateUrl: './link.html',
    host: { 'class' : 'wep-ag-grid-cell-renderer-link' },
    styleUrls: ['./link.scss']
})

export class AgGridCellRendererLinkComponent implements ICellRendererAngularComp {
    public params:any;
    public linkUrl:string;
    public isValidLink:boolean;

    public agInit(params:any) : void {
        this.params = params;
        this.linkUrl = this.params.link(this.params);
    }

    public refresh() : boolean {
        return false;
    }
}
