import { BaseModel } from '~/models/baseModel';

export class BuyerModel extends BaseModel {
    public buyerId: number;

    public buyerServices: string[] = [];
    public buyerWoolTypes: string[] = [];

    constructor(values: Object = {}) {
        super(values);

        if (values && typeof values['buyerServices'] !== 'undefined') {
            this.buyerServices = values['buyerServices'];
        }

        if (values && typeof values['buyerWoolTypes'] !== 'undefined') {
            this.buyerWoolTypes = values['buyerWoolTypes'];
        }
    }

    public hasValues(): boolean {
        let hasBuyerId = (this.buyerId > 0);
        let hasServices = (this.buyerServices.length > 0);
        let hasWoolTypes = (this.buyerWoolTypes.length > 0);

        return (hasBuyerId || hasServices || hasWoolTypes);
    }
}
