import { BaseModel } from  '~/models/baseModel';
import { ArrayBooleanMapper } from '~/decorators/arrayBooleanMapper/arrayBooleanMapperDecorator';
import { ProcessorTypeModel } from './processorTypeModel';

export class ProcessorModel extends BaseModel {

    public entityId:number;
    public totalCapacity:number;
    public avgAnnualGreasyConsumption:number;
    public processorTypes:ProcessorTypeModel[];
    public woolTypes:string[];
    public micronMin:number;
    public micronMax:number;
    public vmMin:number;
    public vmMax:number;
    public lengthMin:number;
    public lengthMax:number;

}
