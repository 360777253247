import { Pipe, PipeTransform } from '@angular/core';
import { isNil as _isNil } from 'lodash';

@Pipe({
    name: 'wepSpaceInCapitalLetters',
    pure: true
})
export class SpaceInCapitalLettersPipe implements PipeTransform {
    public transform(value: string) : string {
        if(!_isNil(value)) {
            value = value.toString();
            value = value.replace(/([a-z])([A-Z])/g, '$1 $2');
            value = value.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');
        }

        return value;
    }
}