import { Component } from '@angular/core';

export interface SocialLinksViewModel {
    link:string;
    iconClass:string;
}

@Component({
    selector: 'wep-page-tpl-footer',
    templateUrl: './footer.html',
    host: {'class': 'wep-page-tpl-footer'},
    styleUrls: ['./footer.scss']
})

export class AdminPageTemplateFooterComponent  {

    public socialLinks:SocialLinksViewModel[];

    public trackBySocialLink(socialLink:SocialLinksViewModel) : string {
        return socialLink.link;
    }

    constructor() {
        this.socialLinks = [];
    }
}
