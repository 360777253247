import { PropertyMapper } from '~/decorators/propertyMapper/propertyMapper';
import { forEach as _forEach } from 'lodash';

interface KeyValue {
    key:string;
    value:string;
}

export function UserRoleMapper(keyValues:KeyValue[]) {
    let settingStringValue:boolean = false;

    return function(target:any, key:string) {

        PropertyMapper(target, key, (value: any, protoTarget:any) => {
             if(!settingStringValue) {
                let isValueSet = false;
                _forEach(keyValues, (keyValue: KeyValue) => {
                    if (value === keyValue.value) {
                        protoTarget[keyValue.key] = true;
                        isValueSet = true;
                    }
                    else if(isValueSet){
                        protoTarget[keyValue.key] = true;
                    }
                    else {
                        protoTarget[keyValue.key] = false;
                    }
                });
            }
            return value;
        }, (protoTarget:any) => {
            let newValue = '';
            _forEach(keyValues, (keyValue: KeyValue) => {
                if (protoTarget[keyValue.key]) {
                    newValue = keyValue.value;
                    return false;
                }
            });
            return newValue;
        });
    };
}