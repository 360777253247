import { Injectable } from '@angular/core';
import { BaleAbstractService } from '~/services/api/web/bale/baleAbstractService';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { HttpClientWrapper } from '~/services/api/httpClientWrapper';
import { BaseWebService } from '~/services/api/web/base/baseWebService';
import { ResponseModel } from '~/models/responseModel';
import { BaleModel } from '~/models/baleModel';
import { ClipModel } from '~/models/clipModel';
import { Observable } from 'rxjs';
import { map as _map } from 'lodash';

@Injectable()
export class BaleService extends BaseWebService implements BaleAbstractService {

    public static readonly PATH:string = '/bales';

    constructor(http: HttpClientWrapper) {
        super(http);
    }

    public getBales() : Observable<ResponseModel> {
        let url:string = BaleService.URL + BaleService.PATH;
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (bale) => new BaleModel(bale));
        });
    }

    public getBaleById(baleId:number) : Observable<ResponseModel> {
        let url:string = BaleService.URL + BaleService.PATH + '/' + baleId.toString();
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return new BaleModel(response['data']);
        });
    }

    public getBalesByClip(clip:ClipModel) : Observable<ResponseModel> {
        let url:string = BaleService.URL + BaleService.PATH;

        let params = new HttpParams();
        params = params.append('clipId', clip.clipId.toString());

        let options = {
            params : params
        };

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (bale) => new BaleModel(bale));
        });
    }

    public postBale(bale:BaleModel) : Observable<ResponseModel> {
        let url:string = BaleService.URL + BaleService.PATH;
        let body:any = bale;
        let options:{} = {};

        return this.http.post(url, body, options, (response:HttpResponse<any>) => {
             return new BaleModel(response['data']);
        });
    }

    public postMobsToBale(bale:BaleModel) : Observable<ResponseModel> {
        let url:string = BaleService.URL + BaleService.PATH + '/' + bale.baleId + '/mobs';
        let body:any = bale.mobIDs;
        let options:{} = {};

        return this.http.post(url, body, options);
    }

    public deleteBale(bale:BaleModel) : Observable<ResponseModel> {
        let url:string = BaleService.URL + BaleService.PATH + '/' + bale.baleId.toString() + '/delete';
        let body:any = null;
        let options:{} = {};

        return this.http.post(url, body, options);
    }
}
