import { BrandModel } from '~/models/brandModel';
import { ClipModel } from '~/models/clipModel';
import { MobModel } from '~/models/mobModel';
import { LineModel } from '~/models/lineModel';
import { BaleModel } from '~/models/baleModel';
import { SpecieModel } from '~/models/specieModel';
import { SettingsModel } from '~/models/settings/settingsModel';
import { filter as _filter,
    forEach as _forEach,
    find as _find,
    remove as _remove,
    findIndex as _findIndex,
    intersection as _intersection,
    flattenDeep as _flattenDeep,
    cloneDeep as _cloneDeep,
    concat as _conact } from 'lodash';

export class ESpeciPageRepository {

    //Current Brand and Clip
    public currentBrand:BrandModel;
    public currentClip:ClipModel;

    //Mobs, Bales, Lines and Species by Clip
    public mobs:MobModel[] = [];
    public bales:BaleModel[] = [];
    public lines:LineModel[] = [];
    public species:SpecieModel[] = [];

    constructor() {
    }

    /**
     * Sets the Brand localStorage value
     * @param brand
     */
    public setBrand(brand:BrandModel) : void {
        this.currentBrand = brand;
    }

    /**
     * Sets the Clip localStorage value
     * @param clip
     */
    public setClip(clip:ClipModel) : void {
        this.currentClip = clip;
    }

    /**
     * Sets the clip related items and its relationships
     * @param mobs
     * @param bales
     * @param lines
     * @param species
     */
    public setClipItems(mobs:MobModel[], bales:BaleModel[], lines:LineModel[], species:SpecieModel[]) {
        this.mobs = mobs;
        this.bales = bales;
        this.lines = lines;
        this.species = species;

        this.setRelationships();
    }

    /**
     * Sets the clip settings
     * @param binCodeSettings
     * @param woolDescriptionSettings
     */
    public setClipSettings(binCodeSettings:SettingsModel, woolDescriptionSettings:SettingsModel) {
        this.currentClip.setBinCodes(binCodeSettings.getValuesAsStringArray());
        this.currentClip.setWoolDescriptions(woolDescriptionSettings.getValuesAsStringArray());
    }

    /**
     * Retrieves the current brand
     * @returns {BrandModel}
     */
    public getCurrentBrand() : BrandModel {
        return this.currentBrand;
    }

    /**
     * Retrieves the current clip
     * @returns {ClipModel}
     */
    public getCurrentClip() : ClipModel {
        return this.currentClip;
    }

    public getMobs() : MobModel[] {
        return this.mobs;
    }

    public getBales() : BaleModel[] {
        return this.bales;
    }

    public getLines() : LineModel[] {
        return this.lines;
    }

    public getSpecies() : SpecieModel[] {
        return this.species;
    }

    public getSpecieById(specieId:number) : SpecieModel {
        return _find(this.species, (specie:SpecieModel) => {
            return specie.specieId === specieId;
        });
    }

    public getLineById(lineId:number) : LineModel {
        return _find(this.lines, (line:LineModel) => {
            return line.lineId === lineId;
        });
    }

    public getBaleById(baleId:number) : BaleModel {
        return _find(this.bales, (bale:BaleModel) => {
            return bale.baleId === baleId;
        });
    }

    public getMobById(mobId:number) : MobModel {
        return _find(this.mobs, (mob:MobModel) => {
            return mob.mobId === mobId;
        });
    }

    /**
     * Does the brand have a valid brand Id?
     */
    public isBrandNotEmpty() : boolean {
        return this.currentBrand && this.currentBrand.brandId !== 0;
    }

    /**
     * Does the clip have a valid brand Id?
     */
    public isClipNotEmpty() : boolean {
        return this.currentClip && this.currentClip.clipId !== 0;
    }

    private setRelationships() {

        //Set associated relationships
        this.setAssociatedLinesInSpecie();
        this.setAssociatedSpecieInLines();
        this.setAssociatedBalesInLines();
        this.setAssociatedLineInBales();
        this.setAssociatedMobsInBales();
        this.setAssociatedBalesInMobs();

        //@TODO a temporary loop to update the specie submitted states. This will be implemented by the API onload.
        _forEach(this.species, (specie:SpecieModel) => {
            this.updateSpecieIsSubmitted(specie);
        });
    }

    /**
     * Sets the associated line in the specie
     */
    private setAssociatedLinesInSpecie() : void {
        _forEach(this.species, (specie:SpecieModel) => {
            specie.lines = _filter(this.lines, (line:LineModel) => {
                return specie.specieId === line.specieID;
            });
        });
    }

    private setAssociatedSpecieInLines() : void {
        _forEach(this.lines, (line:LineModel) => {
            line.specie = _find(this.species, (specie:SpecieModel) => {
                return line.specieID === specie.specieId;
            });
        });
    }

    /**
     * Sets all the associated bales in all lines
     */
    private setAssociatedBalesInLines() : void {
        _forEach(this.lines, (line:LineModel) => {
            line.bales = _filter(this.bales, (bale:BaleModel) => {
                return bale.lineID === line.lineId;
            });
        });
    }

    /**
     * Sets the associated line for each bale
     */
    private setAssociatedLineInBales() : void {
        _forEach(this.bales, (bale:BaleModel) => {
            bale.line = _find(this.lines, (line:LineModel) => {
                return bale.lineID === line.lineId;
            });
        });
    }

    /**
     * Sets all the associated mobs in all bales
     */
    private setAssociatedMobsInBales() : void {
        _forEach(this.bales, (bale:BaleModel) => {
            _forEach(bale.mobIDs, (mobId:number) => {
                _forEach(this.mobs, (mob:MobModel) => {
                    if(mobId === mob.mobId) {
                        bale.mobs.push(mob);
                    }
                });
            });
        });
    }

    /**
     * Sets all the associated bales for each mob
     */
    private setAssociatedBalesInMobs() : void {
        _forEach(this.mobs, (mob:MobModel) => {
            _forEach(mob.baleIDs, (baleId:number) => {
                _forEach(this.bales, (bale:BaleModel) => {
                    if(baleId === bale.baleId) {
                        mob.bales.push(bale);
                    }
                });
            });
        });
    }

    public addMob(mob:MobModel) : void {
        this.mobs.push(mob);
    }

    public updateMob(updatedMob:MobModel) : void {
        let index = _findIndex(this.mobs, (mob:MobModel) => {
            return mob.mobId === updatedMob.mobId;
        });

        //Assign the new mob
        this.mobs[index] = updatedMob;

        this.updateMobRelationships(updatedMob);
    }

    private updateMobRelationships(updatedMob:MobModel) : void {
        _forEach(this.bales, (bale) => {
            let mobIndex = _findIndex(bale.mobs, (mob:MobModel) => {
                return mob.mobId === updatedMob.mobId;
            });
            //Assign mob to the bale
            bale.mobs[mobIndex] = updatedMob;
        });
    }

    public hasMobInItems(selectedMob:MobModel) : boolean {
        return (!!_find(this.bales, (bale:BaleModel) => {
            return (!!_find(bale.mobs, (mob:MobModel) => {
                return mob.mobId === selectedMob.mobId;
            }));
        }));
    }

    public deleteMob(mobToDelete:MobModel) {
        _remove(this.mobs, (mob: MobModel) => {
            return mob.mobId === mobToDelete.mobId;
        });
    }

    public addBale(bale:BaleModel) : void {
        this.bales.push(bale);
    }

    public updateBale(updatedBale:BaleModel) : void {
        let index = _findIndex(this.bales, (bale:BaleModel) => {
            return bale.baleId === updatedBale.baleId;
        });

        //Assign the new bale
        this.bales[index] = updatedBale;

        this.updateBaleRelationships(updatedBale);
    }

    public hasBaleInItems(selectedBale:BaleModel) : boolean {
        let baleInLines:boolean = (!!_find(this.lines, (line:LineModel) => {
            return (!!_find(line.bales, (bale:BaleModel) => {
                 return bale.baleId === selectedBale.baleId;
            }));
        }));

        let baleInMobs:boolean = (!!_find(this.mobs, (mob:MobModel) => {
            return (!!_find(mob.bales, (bale:BaleModel) => {
                return bale.baleId === selectedBale.baleId;
            }));
        }));

        return baleInLines || baleInMobs;
    }

    public deleteBale(baleToDelete:BaleModel) : void {
        _remove(this.bales, (bale: BaleModel) => {
            return bale.baleId === baleToDelete.baleId;
        });
    }

    private updateBaleRelationships(updatedBale:BaleModel) : void {
        updatedBale.mobs = [];

        //Set the associated mobs to this bale
        _forEach(updatedBale.mobIDs, (mobId:number) => {
            _forEach(this.mobs, (mob:MobModel) => {
                if(mobId === mob.mobId) {
                    updatedBale.mobs.push(mob);
                }
            });
        });

        //Set this bale for each line that contains it
        _forEach(this.lines, (line:LineModel) => {
            _forEach(line.bales, (baleInLine:BaleModel, index) => {
                if(baleInLine.baleId === updatedBale.baleId) {
                    line.bales[index] = updatedBale;
                }
            });
        });

        //Set this bale to contain the line associated to it
        updatedBale.line = _find(this.lines, (line:LineModel) => {
            return updatedBale.lineID === line.lineId;
        });

        //Update each mob in relation to this bale
        _forEach(this.mobs, (mob:MobModel) => {

            //Check if mob exists in the updated bale
            let foundMobInUpdatedBale = _find(updatedBale.mobs, (mobInBale) => {
                return mob.mobId === mobInBale.mobId;
            });

            //If the mob does not exist in the updated bale,
            //remove the reference of the bale from this mob
            if(!foundMobInUpdatedBale) {
                _remove(mob.bales, (baleInMob:BaleModel) => {
                    return baleInMob.baleId === updatedBale.baleId;
                });
            }
            //Add the bale reference to this mob
            else {
                let foundBaleInMobIndex = _findIndex(mob.bales, (baleInMob:BaleModel) => {
                    return baleInMob.baleId === updatedBale.baleId;
                });

                if(foundBaleInMobIndex === -1) {
                    //Push a bale to the mob
                    mob.bales.push(updatedBale);
                }
                else {
                    //Update the bale value for this mob
                    mob.bales[foundBaleInMobIndex] = updatedBale;
                }
            }
        });
    }

    public addLine(line:LineModel) : void {
        this.lines.push(line);
    }

    public updateLine(updatedLine:LineModel, updatedBales:BaleModel[]) : void {
        let index = _findIndex(this.lines, (line:LineModel) => {
            return line.lineId === updatedLine.lineId;
        });

        //Assign the new line
        this.lines[index] = updatedLine;

        this.updateLineRelationships(updatedLine, updatedBales);
    }

    private updateLineRelationships(newLine:LineModel, updatedBales:BaleModel[]) : void {

        //Empty the bales in this line
        newLine.bales = [];

        //Remove LineID’s and Line from each bale
        _forEach(this.bales, (bale:BaleModel) => {
            if(bale.lineID === newLine.lineId) {
                bale.lineID = null;
                bale.line = null;
            }
        });

        //Remove inactive Bales from lines
        _forEach(this.lines, (line:LineModel) => {
            _forEach(updatedBales, (updatedBale:BaleModel) => {
                _remove(line.bales, (baleInLine:BaleModel) => {
                    return (baleInLine.baleId === updatedBale.baleId && (!updatedBale.lineID));
                });
            });
        });

        //Add LineID’s and Line to each bale
        _forEach(this.bales, (bale:BaleModel) => {
            _forEach(updatedBales, (updatedBale:BaleModel) => {
                if((bale.baleId === updatedBale.baleId) &&
                    updatedBale.lineID === newLine.lineId) {
                    bale.lineID = updatedBale.lineID;
                    bale.line = newLine;
                    newLine.bales.push(bale);
                }
            });
        });

        //Set this line for each specie that contains it
        _forEach(this.species, (specie:SpecieModel) => {
            _forEach(specie.lines, (lineinSpecie:LineModel, index) => {
                if(lineinSpecie.lineId === newLine.lineId) {
                    specie.lines[index] = newLine;
                }
            });
        });

        //Set this line to retain its specie value
        _forEach(this.species, (specie:SpecieModel) => {
            if(specie.specieId === newLine.specieID) {
                newLine.specie = specie;
            }
        });
    }

    public hasLineInItems(selectedLine:LineModel) : boolean {
        let lineInSpecies:boolean = (!!_find(this.species, (specie:SpecieModel) => {
            return (!!_find(specie.lines, (line:LineModel) => {
                return line.lineId === selectedLine.lineId;
            }));
        }));

        let lineInBales:boolean = (!!_find(this.bales, (bale:BaleModel) => {
            return bale.line && bale.line.lineId === selectedLine.lineId;
        }));

        return lineInSpecies || lineInBales;
    }

    public deleteLine(lineToDelete:LineModel) : void {
        _remove(this.lines, (line: LineModel) => {
            return line.lineId === lineToDelete.lineId;
        });
    }

    public addSpecie(specie:SpecieModel) {
        this.species.push(specie);
    }

    public updateSpecie(updatedSpecie:SpecieModel, updatedLines?:LineModel[]) : void {
        let index = _findIndex(this.species, (specie:SpecieModel) => {
            return specie.specieId === updatedSpecie.specieId;
        });

        //Assign the new specie
        this.species[index] = updatedSpecie;

        if(updatedLines) {
            this.updateSpecieRelationships(updatedSpecie, updatedLines);
        }
    }

    private updateSpecieRelationships(specie:SpecieModel, updatedLines:LineModel[]) : void {
        //Empty the lines in this specie
        specie.lines = [];

        //Remove SpecieID’s and Specie from each line
        _forEach(this.lines, (line:LineModel) => {
            if(line.specieID === specie.specieId)  {
                line.specieID = null;
                line.specie = null;
            }
        });

        //Remove inactive Lines from Specie
        _forEach(this.species, (specie:SpecieModel) => {
            _forEach(updatedLines, (updatedLine:LineModel) => {
                _remove(specie.lines, (lineInSpecie:LineModel) => {
                    return (lineInSpecie.lineId === updatedLine.lineId && (!updatedLine.specieID));
                });
            });
        });

        //Add SpecieID’s and Specie to each line
        _forEach(this.lines, (line:LineModel) => {
            _forEach(updatedLines, (updatedLine:LineModel) => {
                if((line.lineId === updatedLine.lineId) &&
                    updatedLine.specieID === specie.specieId) {
                    line.specieID = updatedLine.specieID;
                    line.specie = specie;
                    specie.lines.push(line);
                }
            });
        });
    }

    /**
     * Updates the specie is submitted value
     * @param {SpecieModel}
     */
    public updateSpecieIsSubmitted(specie:SpecieModel) : void {
        _forEach(specie.lines, (line:LineModel) => {
            line.isSubmitted = specie.isSubmitted;

            _forEach(line.bales, (bale:BaleModel) => {
                bale.isSubmitted = specie.isSubmitted;
            });
        });

         let baleIds = _flattenDeep(specie.lines.map(x=> x.bales.map(b=> b.baleId)));
        if(baleIds.length > 0) {
             let updateMobs:MobModel[] = [];
            _forEach(this.mobs , (mob:MobModel) => {
                if((_intersection(baleIds,mob.bales.map(x=>x.baleId))).length > 0) {
                        mob.isSubmitted = true;
                        updateMobs.push(mob);
                }
            });
            if(updateMobs.length > 0) {
                updateMobs = _conact(updateMobs,
                                    _filter(this.mobs, (m:MobModel) =>{
                                                        return _findIndex(updateMobs,(mob:MobModel) => { return mob.mobId === m.mobId; }) < 0;
                                                    })
                                    );
                this.mobs = updateMobs.map(m => new MobModel(m));
            }
        }
    }

    public hasSpecieInItems(selectedSpecie:SpecieModel) : boolean {
        return (!!_find(this.lines, (line:LineModel) => {
            return line.specie && line.specie.specieId === selectedSpecie.specieId;
        }));
    }

    public deleteSpecie(specieToDelete:SpecieModel) : void {
        _remove(this.species, (specie: SpecieModel) => {
            return specie.specieId === specieToDelete.specieId;
        });
    }
}
