import { BaseModel } from '~/models/baseModel';

export class BankModel extends BaseModel {
    public accountNo:string;
    public accountName:string;
    public bsb:string;
    public iban:string;
    public swiftCode:string;

    set bankName(value:string) {
        this.accountName = value;
    }

    get bankName() : string {
        return this.accountName;
    }

    public hasValues() : boolean {
        let hasValues = (typeof this.accountNo !== 'undefined' && this.accountNo.length > 0);
        hasValues = hasValues || (typeof this.accountName !== 'undefined' && this.accountName.length > 0);
        hasValues = hasValues || (typeof this.bsb !== 'undefined' && this.bsb.length > 0);

        return hasValues;
    }
}
