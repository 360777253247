import { BaseModel } from '~/models/baseModel';

export class TraderModel extends BaseModel {
    public fullName:string;
    public readOnly:boolean;
    public firstName:string;
    public lastName:string;
    public contactId:number;
    public canTrade:boolean;
    public entityId:number;
    public email:string;
    public tempKey:string;
}
