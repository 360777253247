import { Injectable } from '@angular/core';
import { isNil as _isNil } from 'lodash';

@Injectable()
export class LocalService {

    public setNumber(key:string, value:number) : void {
        if(!_isNil(value)) {
            localStorage.setItem(key, value.toString());
        }
        else {
            localStorage.setItem(key, '');
        }
    }

    public getNumber(key:string) : number {
        let value = localStorage.getItem(key);
        if(!_isNil(value) && value !== '') {
            return +value;
        }
        return null;
    }

    /**
     * Stores a string boolean
     * @param key
     * @param value
     */
    public setBoolean(key:string, value:boolean) : void {
        let storedValue = value ? 1 : 0;
        localStorage.setItem(key, storedValue.toString());
    }

    /**
     * Gets a boolean formatted from the string
     * @param key
     * @returns {boolean}
     */
    public getBoolean(key:string) : boolean {
        let value = +localStorage.getItem(key) === 1 ? true : false;
        return value;
    }

    /**
     * Stores the object value as a JSON string
     * @param {string} key
     * @param object
     */
    public setObject(key:string, object:any) : void {
        let value = JSON.stringify(object);
        localStorage.setItem(key, value);
    }

    /**
     * Gets the JSON object from the stringified value
     * @param {string} key
     * @returns {any}
     */
    public getObject(key:string) : any {
        if(localStorage.getItem(key)) {
            let value = JSON.parse(localStorage.getItem(key));
            return value;
        }
        return null;
    }

    /**
     * Generic settting of localStorage
     * @param key
     * @param value
     */
    public set(key:string, value:any) {
        localStorage.setItem(key, value);
    }

    /**
     * Clears all the localStorage values
     */
    public clearAll() {
        localStorage.clear();
    }

    /**
     * Generic getting of localStorage
     * @param key
     * @param value
     */
    public get(key:string) : any {
        return localStorage.getItem(key);
    }

    /**
     * Removes the localStorage value
     * @param key
     */
    public remove(key:string) : void {
        localStorage.removeItem(key);
    }
}
