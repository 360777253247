import { BaseModel } from '~/models/baseModel';
import { Moment } from '~/decorators/moment/momentDecorator';
import * as moment from 'moment';

export class AuctionCountdownModel extends BaseModel {
    public isAuctionRunning:boolean;

    @Moment()
    public auctionCloseDate:moment.Moment;

    @Moment()
    public nextAuctionDate:moment.Moment;

    @Moment()
    public nextAuctionBlockStartDate:moment.Moment;

    @Moment()
    public currentAuctionBlockEndDate:moment.Moment;

    public auctionName:string;
    public businessLogo:string;

    public auctionCloseCountDown:number;
    public nextAuctionCountDown:number;
    public nextBlockStart:number;
    public currentBlockEnd:number;

    constructor(values: Object = {}) {
        super(values);

        if(values['auctionCloseCountDown']) {
            this.auctionCloseDate = moment().add(values['auctionCloseCountDown'], 'seconds');
        }
        if(values['nextAuctionCountDown']) {
            this.nextAuctionDate = moment().add(values['nextAuctionCountDown'], 'seconds');
        }
        if(values['nextBlockStart']) {
            this.nextAuctionBlockStartDate = moment().add(values['nextBlockStart'], 'seconds');
        }
        if(values['currentBlockEnd']) {
            this.currentAuctionBlockEndDate = moment().add(values['currentBlockEnd'], 'seconds');
        }

    }
}
