import { BaseModel } from '~/models/baseModel';
import { UserModel } from '~/models/userModel';

export class MarketDashboardBidTableItemModel extends BaseModel {
    public lotId:number;
    public bidder:UserModel;
    public vm:number;
    public yields:number;
    public micron:number;
    public kg:number;

    constructor(values: Object = {}) {
        super(values);

        if(values['bidder']) {
            this.bidder = new UserModel(values['bidder']);
        }
    }
}