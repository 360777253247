import { BaseModel } from '~/models/baseModel';

export class SustainabilityModel extends BaseModel {
    public nrm:string;

    public hasValues() : boolean {
        let hasValues = (typeof this.nrm !== 'undefined' && this.nrm.length > 0);

        return hasValues;
    }
}
