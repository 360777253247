import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Injectable()
export class TitleTemplateService {
    private title:string = process.env['WEBPAGE_TITLE'];
    private titleService:Title;

    constructor(router: Router, titleService: Title) {

        this.titleService = titleService;

        router.events.subscribe(event => {
            if(event instanceof NavigationEnd) {
                let suffix:string = this.getSuffix(router.routerState, router.routerState.root);
                this.setTitle(suffix);
            }
        });
    }

    /**
     * Retrives the data title from the app.routing data.title value
     * @param state
     * @param parent
     * @returns {string}
     */
    private getSuffix(state, parent) : string {
        if(parent && parent.snapshot.data && parent.snapshot.data.title) {
            return parent.snapshot.data.title;
        }
        if(state && parent) {
            return this.getSuffix(state, state.firstChild(parent));
        }
        return null;
    }

    /**
     * Sets the title with a prefix otherwise just set a normal title
     * @param {string} suffix
     */
    private setTitle(suffix:string) : void {
        if(suffix) {
            this.titleService.setTitle(this.title + ' | ' + suffix);
        }
        else {
            this.titleService.setTitle(this.title);
        }
    }
}