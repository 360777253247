import { BaseModel } from '~/models/baseModel';
import { SettingModel, SettingScope } from '~/models/settings/settingModel';
import { forEach as _forEach } from 'lodash';

export class SettingsModel extends BaseModel {
    private values:SettingModel[];

    public setValues(values: SettingModel[]) {
        this.values = values;
    }

    public getValuesAsStringArray() : string[] {
        let stringValues:string[] = [];
        let specieScopedValues:string[] = [];
        let globalScopedValues:string[] = [];

        _forEach(this.values, (settingModel:SettingModel) => {
            if(settingModel.scope === SettingScope.Specie) {
                specieScopedValues.push(settingModel.value);
            }
            else if(settingModel.scope === SettingScope.Global) {
                globalScopedValues.push(settingModel.value);
            }
        });

        stringValues.push.apply(stringValues, specieScopedValues);
        stringValues.push.apply(stringValues, globalScopedValues);

        return stringValues;
    }
}
