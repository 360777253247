import { Injectable } from '@angular/core';
import { TempBrandAbstractService } from '~/services/api/web/tempBrand/tempBrandAbstractService';
import { BaseWebService } from '~/services/api/web/base/baseWebService';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { HttpClientWrapper } from '~/services/api/httpClientWrapper';
import { ResponseModel } from '~/models/responseModel';
import { BrandModel } from '~/models/brandModel';
import { Observable } from 'rxjs';
import { map as _map } from 'lodash';
import { EntityModel } from '~/models/entityModel';

@Injectable()
export class TempBrandService extends BaseWebService implements TempBrandAbstractService {

    public static readonly PATH:string = '/tempbrands';

    constructor(http:HttpClientWrapper) {
        super(http);
    }

    public getBrands(entity:EntityModel) : Observable<ResponseModel> {
        let url:string = TempBrandService.URL + TempBrandService.PATH;
        let params = new HttpParams();
        params = params.append('entityId', entity.entityId.toString());

        let options:{} = {
            params : params
        };

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (brand) => new BrandModel(brand));
        });
    }
}