import { BaseModel } from '~/models/baseModel';
import { CommentModel } from '~/models/commentModel';
import { LinkModel } from '~/models/linkModel';
import { UserModel } from '~/models/userModel';
import { EntityModel } from '~/models/entityModel';
import { Moment } from '~/decorators/moment/momentDecorator';
import { forEach as _forEach } from 'lodash';
import * as moment from 'moment';

export class PostModel extends BaseModel {
    @Moment()
    public date:moment.Moment;

    public id:number;
    public entityId:number;
    public author:number;
    public contact:UserModel;
    public entity:EntityModel;
    public text:string;
    public canDelete:boolean;
    public allowComments:boolean;
    public groupMemberOnly:boolean;
    public comments:CommentModel[] = [];
    public links:LinkModel[] = [];

    public images:string[] = [];
    public liked:boolean;
    public numOfLikes:number;

    constructor(values?:{}) {
        super(values);

        if(typeof values !== 'undefined' &&
           typeof values['comments'] !== 'undefined' &&
           values['comments'].length > 0) {
            let comments:CommentModel[] = [];

            _forEach(values['comments'], (commentData:{}) => {
                let comment:CommentModel = new CommentModel(commentData);

                comment.postId = values['id'];
                comments.push(comment);
            });

            this.comments = comments;
        }

        if(typeof values !== 'undefined' &&
           typeof values['images'] !== 'undefined' &&
           values['images'].length > 0) {
            let images:string[] = [];

            _forEach(values['images'], (image:string) => {
                images.push(image);
            });

            this.images = images;
        }

        if(typeof values !== 'undefined' &&
           typeof values['contact'] !== 'undefined') {
            this.contact = new UserModel(values['contact']);
        }

        if(typeof values !== 'undefined' &&
           typeof values['entity'] !== 'undefined') {
            this.entity = new EntityModel(values['entity']);
        }

        if(this.links) {
            let links:LinkModel[] = [];

            _forEach(this.links, (link:{}) => {
                links.push(new LinkModel(link));
            });

            this.links = links;
        }
    }
}
