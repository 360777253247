import { BaseModel } from '~/models/baseModel';
import { UserModel } from '~/models/userModel';
import { Moment } from '~/decorators/moment/momentDecorator';
import * as moment from 'moment';

export class MessageModel extends BaseModel {
    public id:number;
    public userId:number;
    public isSent:boolean;
    public contact:UserModel;

    @Moment()
    public date:moment.Moment;
    public message:string;

    public isRead:boolean;
    constructor(values:{}) {
        super(values);
        this.contact = new UserModel(values['contact']);
    }
}
