import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'wep-dialog',
    templateUrl: './dialog.html',
    host: {'class': 'wep-dialog'},
    styleUrls: ['./dialog.scss']
})

export class Dialog {
    public dialogRef: MatDialogRef<any>;

    constructor(@Inject(MAT_DIALOG_DATA) public data, dialogRef:MatDialogRef<any>) {
        this.dialogRef = dialogRef;
    }

    public close(value:any = null) : void {
        this.dialogRef.close(value);
    }
}