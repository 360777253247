import { BaseModel } from '~/models/baseModel';
import { SearchModel } from '~/models/searchModel';

export class SavedSearchModel extends BaseModel {
    public savedSearchId:number;
    public name:string;
    public entityId:number;
    public searchModel:SearchModel;

    constructor(values:Object = {}) {
        super(values);
        if(values['searchModel']) {
            this.searchModel = new SearchModel(values['searchModel']);
        }
    }
}
