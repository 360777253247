import { Component, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ContentEpiService } from '~/services/api/epi/content/contentEpiService';
import { SnackBarService } from '~/components/snackBar/services/snackBarService';
import { UserPreferenceKeyNames, UserPreferenceLocalService } from '~/services/api/web/userPreference/userPreferenceLocalService';
import { HeroCarouselService } from '~/pageTemplates/admin/shared/components/heroCarousel/service/heroCarouselService';

// Models
import { CustomHeaderPageModel } from '~/models/epi/customHeaderPageModel';
import { ResponseModel, ResponseModelCode } from '~/models/responseModel';
import { UserPreferenceModel } from '~/models/userPreferenceModel';

// Rxjs
import { Subscription, Observable, forkJoin } from 'rxjs';
import { filter, distinctUntilChanged, skip } from 'rxjs/operators';

import {
    forEach as _forEach,
    filter as _filter,
    findIndex as _findIndex,
    isUndefined as _isUndefined,
    camelCase as _camelCase,
    isNil as _isNil,
} from 'lodash';

function isVisible(item:CustomHeaderPageModel) : boolean {
    if (_isNil(item['hidden'])) {
        return true;
    }

    return !item['hidden'];
}

@Component({
    selector: 'wep-hero-carousel',
    templateUrl: './heroCarousel.html',
    host: {'class': 'wep-hero-carousel'},
    styleUrls: ['./heroCarousel.scss'],
})

export class HeroCarouselComponent implements OnDestroy {

    private router:Router;
    private activatedRoute:ActivatedRoute;
    private contentEpiService:ContentEpiService;
    private userPreferenceLocalService:UserPreferenceLocalService;
    private snackBarService:SnackBarService;
    private heroCarouselShowCarouselSubscription:Subscription;
    private heroCarouselService:HeroCarouselService;

    private items:CustomHeaderPageModel[] = [];
    public display:boolean = false;
    public visible:boolean = false;

    private subscription:Subscription;

    constructor(router:Router,
                activatedRoute:ActivatedRoute,
                contentEpiService:ContentEpiService,
                heroCarouselService:HeroCarouselService,
                userPreferenceLocalService:UserPreferenceLocalService,
                snackBarService:SnackBarService) {
        this.router = router;
        this.heroCarouselService = heroCarouselService;
        this.activatedRoute = activatedRoute;
        this.contentEpiService = contentEpiService;
        this.userPreferenceLocalService = userPreferenceLocalService;
        this.snackBarService = snackBarService;

        this.subscription = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            distinctUntilChanged(),
        ).subscribe(() => {
            this.getHeaderPages(this.router.url);
        });

        this.heroCarouselShowCarouselSubscription = this.heroCarouselService.showCarouselObservable().pipe(
            skip(1)
        ).subscribe((url:string) => {
            if(!_isNil(url)) {
                this.showCarousel(url);
            }
        });
    }

    public ngOnDestroy() : void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if(this.heroCarouselShowCarouselSubscription) {
            this.heroCarouselShowCarouselSubscription.unsubscribe();
        }

    }

    public trackByItemId(item:any) : any {
        return item.id;
    }

    /**
     * Retrieves the custom header pages when the page routes and reroutes based on the path
     * @param {string} url
     */
    private getHeaderPages(url:string) : void {
        this.display = false;

        this.contentEpiService.getCustomHeaderPages(url).subscribe((response:ResponseModel) => {
            if(response.code === ResponseModelCode.Ok) {
                let itemObservables:Observable<CustomHeaderPageModel>[] = [];

                _forEach(response.data, (item:CustomHeaderPageModel) => {
                    itemObservables.push(this.getCarouselItem(item));
                });

                forkJoin(itemObservables).subscribe((items:CustomHeaderPageModel[]) => {
                    this.items = _filter(items, (item) => !_isNil(item) && isVisible(item));
                    this.display = (this.items.length > 0);

                    setTimeout(() => { this.visible = this.display; }, 350);
                });
            }
        });
    }

    /**
     * Returns an observable that returns the carouselItem or null if the carouselItem is hidden
     * This is then used to add items that are not hidden
     * @param {} item
     * @returns {Observable<>}
     */
    private getCarouselItem(item:CustomHeaderPageModel) : Observable<CustomHeaderPageModel> {
        let path:string = item.id;

        return Observable.create((observer) => {
            this.userPreferenceLocalService
                    .getBooleanValue(UserPreferenceKeyNames.App.PageTemplate.Admin.HeroCarousel(_camelCase(path)))
                        .subscribe((userPref:UserPreferenceModel<boolean>) => {
                            if (_isNil(userPref)) {
                                return null;
                            }

                            let hidden = (!_isUndefined(userPref.value))
                                                ? userPref.value
                                                : false;

                            if(!hidden) {
                                observer.next(item);
                            }

                            observer.complete();
                        });
        });
    }

    /**
     * Hides the carousel that have the same id
     * @param {string} path
     */
    public hideCarousel(item:CustomHeaderPageModel) : void {
        let path:string = item.id;

        this.userPreferenceLocalService.postBooleanValue(UserPreferenceKeyNames.App.PageTemplate.Admin.HeroCarousel(_camelCase(path)), true)
            .subscribe((userPreference:UserPreferenceModel<boolean>) => {
                if(!_isNil(userPreference) && !_isNil(userPreference.value)) {
                    this.items = [];
                    this.display = false;
                }
            }
        );
    }

    public showCarousel(url:string) : void {
        this.userPreferenceLocalService.postBooleanValue(UserPreferenceKeyNames.App.PageTemplate.Admin.HeroCarousel(_camelCase(url)), false)
            .subscribe((userPreference:UserPreferenceModel<boolean>) => {
                if(!_isNil(userPreference) && !_isNil(userPreference.value)) {
                    this.contentEpiService.getCustomHeaderPages(url).subscribe((response:ResponseModel) => {
                        if(response.code === ResponseModelCode.Ok) {
                            _forEach(response.data, (item:CustomHeaderPageModel) => {
                                let index = _findIndex(this.items,(i:CustomHeaderPageModel)=>{
                                    return i.id === item.id && i.heading === item.heading;
                                });
                                if(index < 0) {
                                    this.items.push(item);
                                }
                            });
                            this.display = (this.items.length > 0);
                            this.visible = this.display;
                        }
                    });
                }
            }
        );
    }
}
