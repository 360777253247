import { LineModel } from '~/models/lineModel';
import { MarketValueItemModel } from '~/models/marketValueItemModel';
import { round as _round, isNil as _isNil } from 'lodash';
import { map as  _map } from 'lodash';

export class ReadyReckonerModel extends LineModel {
    public awexId:string;
    public rowId:string;
    public description:string;
    public region:string;
    public grsy:number;
    public cln:number;
    public grsyValue:number;
    public wsa:string;
    public data:MarketValueItemModel[];
    public history:MarketValueItemModel[];
    public highestGrossValue:number;
    public lowestGrossValue: number;
    public accuracy:number;
    public canEditTestData:boolean;
    public isReadOnlyAwexId:boolean;
    public isReadOnlyDescription:boolean;
    public isReadOnlyNoBales:boolean;
    public isReadOnlyWeight:boolean;
    public isReadOnlyMicron:boolean;
    public isReadOnlyYield:boolean;
    public isReadOnlyVM:boolean;
    public isReadOnlySS:boolean;
    public isReadOnlyLength:boolean;
    public isReadOnlyWSA:boolean;
    public isReadOnlyPOBM:boolean;
    public isReadOnlyMules:boolean;
    public soldPrice:number;
    public mules:string;
    public lotno:string;
    public refno:string;
    public brand:string;
    public id:number;
    public indicatorIds:number[];

    constructor(values?:{}) {
        super(values);

        this.data = _map(this.data, (item) => {
            return new MarketValueItemModel(item);
        });
        this.history = _map(this.history, (item) => {
            return new MarketValueItemModel(item);
        });

        if (!_isNil(values) && !_isNil(values['cln'])) {
            this.cln = _round(values['cln']);
        }
    }
}
