import { BaseModel } from '~/models/baseModel';

export class OfferSaleModel extends BaseModel {
    public transactionId:number;
    public soldPrice:number;
    public buyerFee:number;
    public buyerEDI:string;
    public sellerFee:number;
    public transactionFee:number;
    public total:number;
    public account:string;
}
