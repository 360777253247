import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { isFunction as _isFunction } from 'lodash';

export interface AgGridCellRendererImgParams extends ICellEditorParams {
    class?:((params) => void) | string;
}

@Component({
    selector: 'wep-ag-grid-cell-renderer-img',
    templateUrl: './img.html',
    host: { 'class' : 'wep-ag-grid-cell-renderer-img' },
    styleUrls: ['./img.scss']
})

export class AgGridCellRendererImgComponent implements ICellRendererAngularComp {
    public params: any;

    public agInit(params: any) : void {
        this.params = params;
    }

    /**
     * Retrieves the CSS class to be displayed, can also use a callback for dynamic classes
     * @returns {string}
     */
    public getClass() : string {
        if(_isFunction(this.params.class)) {
            return this.params.class(this.params);
        }
        return this.params.class;
    }

    public getImgUrls() : string[] {
        if(_isFunction(this.params.urls)) {
            return this.params.urls(this.params);
        }
        return this.params.urls;
    }

    public onClick() : void {
        if(_isFunction(this.params.clickEvent)) {
            this.params.clickEvent(this.params, this);
        }
    }

    public refresh() : boolean {
        return false;
    }
}
