import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'wep-option',
    templateUrl: './option.html',
    host: {'class': 'wep-option'}
})

export class OptionComponent {

    @Input()
    public value:any;

    @Input()
    public selected:boolean = false;

    @Input('displayText')
    public displayText:string = '';

    @Output()
    public clicked: EventEmitter<any> = new EventEmitter();

    @Input()
    public disabled:boolean = false;

    @Input()
    public image:string;

    constructor() {
    }
}
