import { BaseModel } from '~/models/baseModel';
import { Moment } from '~/decorators/moment/momentDecorator';
import * as moment from 'moment';
import { isUndefined as _isUndefined } from 'lodash';


export class RecentSaleModel extends BaseModel {
    public salesCenter:string;
    public region:string;

    @Moment()
    public saleDate:moment.Moment;
    public broker:string;
    public vn:number;
    public mm:number;
    public cert:string;
    public length:number;
    public yield:number;
    public awexId:string;
    public lotIds:number[];

    @Moment()
    public date:moment.Moment;

    public micron:number;
    public year:number;
    public bales:number;
    public balesPerLine:number;
    public salePrice:number;
    public vmb:number;
    public stapleStrength:number;
    public pob:number;
    public total:number;
    public storageCenter:string;
    public wsa:string;
    public cln:number;
    public grsy:number;
    public netWeight:number;
    public grsyValue:number;

    get formatSaleData() : string {
        if (_isUndefined(this.saleDate)) {
            return '';
        }

        return this.saleDate.format('YYYY-MM-DD');
    }
}
