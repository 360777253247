import { OrderModel } from './orderModel';

export class OrderSummaryModel extends OrderModel {
	public comboNumber: number;
	public comboDate: string;
	public destination: string;
	public portOfDischarge: string;
	public deliveryDate: string;
	public traceabilityStatus: string;

    constructor(values: Object = {}) {
        super(values);
    }
}
