import { Component, AfterViewChecked } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { isFunction as _isFunction } from 'lodash';

@Component({
    selector: 'wep-ag-grid-cell-renderer-market-auction-button',
    templateUrl: './button.html',
    host: { 'class' : 'wep-ag-grid-cell-renderer wep-ag-grid-cell-renderer-market wep-ag-grid-cell-renderer-market-auction-button' },
    styleUrls: ['./button.scss']
})

export class AgGridCellRendererMarketAuctionButtonComponent implements ICellRendererAngularComp, AfterViewChecked {
    public params: any;

    public agInit(params: any) : void {
        this.params = params;
    }

    public onClick() : void {
        if(_isFunction(this.params.clickEvent)) {
            this.params.clickEvent(this.params);
        }
    }

    public ngAfterViewChecked() : void {
        this.params.eGridCell.style.display = 'flex';
        this.params.eGridCell.style.alignItems = 'center';
    }

    public refresh() : boolean {
        return false;
    }
}
