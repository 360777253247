import { BaseModel } from '~/models/baseModel';

export class ImportBaleModel extends BaseModel {
    public baleId:number;
    public baleNo:number;
    public display:string;
    public weight:number;
    public tareKg:number;
    public brokerRef:string;

    constructor(values: Object = {}) {
        super(values);
    }
}