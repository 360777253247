import { BaseModel } from '~/models/baseModel';
import { CSVBooleanMapper } from '~/decorators/csvBooleanMapper/csvBooleanMapperDecorator';

export class WoolStoreLocationModel extends BaseModel {
    public woolStoreLocationId: number;
    public locationName: string;
    public siteCode: string;
    public emailAddress: string;
    public streetAddress1: string;
    public streetAddress2: string;
    public suburb: string;
    public state: string;
    public postcode: string;
    public phone:string;
    public isDefault:boolean;

    @CSVBooleanMapper('woolStoreLocationTypesObject', [
        {
            key: 'isBranch',
            value: 'Branch'
        },
        {
            key: 'isWoolStore',
            value: 'WoolStore'
        }
    ])
    public woolStoreLocationTypeList:string;
    public managerName:string;
}
