import { Injectable } from '@angular/core';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { ContactPermissionsAbstractService } from '~/services/api/web/contactPermission/contactPermissionsAbstractService';
import { Observable } from 'rxjs';
import { HttpClientWrapper } from '~/services/api/httpClientWrapper';
import { BaseWebService } from '~/services/api/web/base/baseWebService';
import { ContactPermissionModel } from '~/models/contactPermissionModel';
import { ResponseModel } from '~/models/responseModel';
import { ClipModel } from '~/models/clipModel';
import { map as _map } from 'lodash';

@Injectable()
export class ContactPermissionsService extends BaseWebService implements ContactPermissionsAbstractService {

    constructor(http:HttpClientWrapper) {
        super(http);
    }

    public getContactPermissionsByClip(clipModel:ClipModel) : Observable<ResponseModel> {

        let url:string = ContactPermissionsService.URL + '/clips/' + clipModel.clipId.toString() + '/permissions';
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (contactPermissionModel) => new ContactPermissionModel(contactPermissionModel));
        });
    }

    public getContactPermissions(clipModel:ClipModel, query:string) : Observable<ResponseModel> {
        let url:string = ContactPermissionsService.URL + '/permissions';

        let clipId:string = clipModel && clipModel.clipId ? clipModel.clipId.toString() : null;

        let params = new HttpParams();

        if(clipId) {
            params = params.append('excludeClipId', clipId);
        }
        params = params.append('searchNameOrEmail', query);

        let options:{} = {
            params : params
        };

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (contactPermissionModel) => new ContactPermissionModel(contactPermissionModel));
        });
    }

    public postContactPermissions(clipModel:ClipModel, contactPermissionsModel:ContactPermissionModel[]) : Observable<ResponseModel> {
        let url:string = ContactPermissionsService.URL + '/clips/' + clipModel.clipId.toString() + '/permissions';
        let body:any = contactPermissionsModel;
        let options:{} = {};

        return this.http.post(url, body, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (contactPermissionModel) => new ContactPermissionModel(contactPermissionModel));
        });
    }
}