import { PropertyMapper } from '~/decorators/propertyMapper/propertyMapper';
import * as moment from 'moment';

export function Moment() {
    return function(target:any, key:string) {
        PropertyMapper(target, key, (value: moment.Moment | Date | string) => {
            return toMoment(value);
        });
    };
}

/**
 * Returns a moment.Moment object (from moment.Moment, Date or a string date value)
 * Otherwise it returns null
 * @param {moment.Moment | Date | string} value
 * @returns {any}
 */
export function toMoment(value: moment.Moment | Date | string) {
    if(moment.isMoment(value)) {
        return value;
    }
    else if(value instanceof Date) {
        return moment(value);
    }
    else if(typeof value === 'string') {
        let newMoment = moment(value, moment.ISO_8601);

        if(newMoment.isValid()) {
            return newMoment;
        }
    }
    return null;
}