import { Injectable } from '@angular/core';
import { MyWoolAbstractService } from '~/services/api/web/myWool/myWoolAbstractService';
import { BaseWebService } from '~/services/api/web/base/baseWebService';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { HttpClientWrapper } from '~/services/api/httpClientWrapper';
import { Observable } from 'rxjs';

// Models
import { SeasonModel } from '~/models/seasonModel';
import { EntityModel } from '~/models/entityModel';
import { ResponseModel } from '~/models/responseModel';

@Injectable()
export class MyWoolService extends BaseWebService implements MyWoolAbstractService {

    public static readonly PATH:string = '/mywool';

    //@TODO phase this out when upload functions are redone…
    private httpClientLegacy:HttpClient;

    constructor(http:HttpClientWrapper, httpClientLegacy:HttpClient) {
        super(http);

        this.httpClientLegacy = httpClientLegacy;
    }

    public getSeasonOverview(entity:EntityModel) : Observable<ResponseModel> {
        let url:string = MyWoolService.URL + MyWoolService.PATH + '/seasonoverview/' + entity.entityId.toString();
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return new SeasonModel(response['data']);
        });
    }
}
