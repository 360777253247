import { Injectable } from '@angular/core';
import { OfferAbstractService } from '~/services/api/web/offer/offerAbstractService';
import { HttpResponse, HttpParams } from '@angular/common/http';

import { BaseWebService } from '~/services/api/web/base/baseWebService';

import { ResponseModel } from '~/models/responseModel';
import { OfferModel } from '~/models/offerModel';
import { MarketDashboardModel } from '~/models/marketDashboardModel';
import { AuctionLotModel } from '~/models/auctionLotModel';
import { MyBidModel } from '~/models/myBidModel';
import { OfferLotModel } from '~/models/offerLotModel';
import { OfferCreateModel } from '~/models/offerCreateModel';
import { MultiBidInputModel } from '~/models/multiBidInputModel';
import { BidOutputModel } from '~/models/bidOutputModel';
import { ServerPaginationModel } from '~/models/serverPagination/serverPaginationModel';
import { UpdateOfferAccountInputModel } from '~/models/updateOfferAccountInputModel';
import { BidInputModel } from '~/models/bidInputModel';
import { Observable } from 'rxjs';

import { map as _map, isNil as _isNil } from 'lodash';
import { BulletinBoardSummaryModel } from '~/models/bulletinBoardSummaryModel';

@Injectable()
export class OfferService extends BaseWebService implements OfferAbstractService {

    public static readonly PATH: string = '/offers';

    public getLots(auctionId:number) : Observable<ResponseModel> {
        let url:string = OfferService.URL + OfferService.PATH + '/' + auctionId.toString() + '/lots';
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (auctionLot) => new AuctionLotModel(auctionLot));
        });
    }

    public getDashboardValues() : Observable<ResponseModel> {
        let url:string = OfferService.URL + OfferService.PATH + '/dashboard';
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return new MarketDashboardModel(response['data']);
        });
    }

    public getRecentOffers(page:number = null, pageSize:number = null) : Observable<ResponseModel> {
        let url:string = OfferService.URL + OfferService.PATH + '/recentoffers';
        let params = new HttpParams();

        if(!_isNil(page)) {
            params = params.append('page', page.toString());
        }
        if(!_isNil(pageSize)) {
            params = params.append('pageSize', pageSize.toString());
        }

        let options = {
            params : params
        };

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return new ServerPaginationModel<OfferModel>(OfferModel, response['data']);
        });
    }

    public getRecentBids() : Observable<ResponseModel> {
        let url:string = OfferService.URL + OfferService.PATH + '/recentbids';
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (offer) => new OfferModel(offer));
        });
    }

    public getSoldOffers(auctionCatalogId?:number, savedSearchId?:number, page:number = null, pageSize:number = null) : Observable<ResponseModel> {
        let url:string = OfferService.URL + OfferService.PATH + '/sold';
        let params = new HttpParams();

        if(!_isNil(auctionCatalogId)) {
            params = params.append('auctionCatalogId', auctionCatalogId.toString());
        }
        if(!_isNil(savedSearchId)) {
            params = params.append('savedSearchId', savedSearchId.toString());
        }
        if(!_isNil(page)) {
            params = params.append('page', page.toString());
        }
        if(!_isNil(pageSize)) {
            params = params.append('pageSize', pageSize.toString());
        }

        let options = {
            params : params
        };

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return new ServerPaginationModel<OfferModel>(OfferModel, response['data']);
        });
    }

    public getOffersByEntityId(entityId:number, page:number = null, pageSize:number = null) : Observable<ResponseModel> {
        let url:string = OfferService.URL + OfferService.PATH + '/entity/' + entityId;
        let params = new HttpParams();
        if(!_isNil(page)) {
            params = params.append('page', page.toString());
        }
        if(!_isNil(pageSize)) {
            params = params.append('pageSize', pageSize.toString());
        }

        let options = {
            params : params
        };
        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return new ServerPaginationModel<OfferModel>(OfferModel, response['data']);
        });
    }

    public getAuctionOffers(savedSearchId?:number, auctionCatalogId?:number, page:number = null, pageSize:number = null) : Observable<ResponseModel> {
        let savedSearchIdString:string = !_isNil(savedSearchId) ? '/' + savedSearchId.toString() : '';
        let url:string = OfferService.URL + OfferService.PATH + '/auctions/filter' + savedSearchIdString;
        let params = new HttpParams();

        if(!_isNil(auctionCatalogId)) {
            params = params.append('auctionCatalogId', auctionCatalogId.toString());
        }
        if(!_isNil(page)) {
            params = params.append('page', page.toString());
        }
        if(!_isNil(pageSize)) {
            params = params.append('pageSize', pageSize.toString());
        }

        let options = {
            params : params
        };

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return new ServerPaginationModel<OfferModel>(OfferModel, response['data']);
        });
    }

    public getBulletinBoardDashboard() : Observable<ResponseModel> {
        let url:string = OfferService.URL +  '/bulletinboard/dashboard';
        let options:{} = {}
        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return new BulletinBoardSummaryModel(response['data']);
        });
    }


    //TODO: create new service for bulletinboard
    public getFixedOffers(savedSearchId?:number, page:number = null, pageSize:number = null) : Observable<ResponseModel> {
        let savedSearchIdString:string = !_isNil(savedSearchId) ? '/' + savedSearchId.toString() : '';

        let url:string = OfferService.URL +  '/bulletinboard/filter' + savedSearchIdString;
        let params = new HttpParams();

        if(!_isNil(page)) {
            params = params.append('page', page.toString());
        }
        if(!_isNil(pageSize)) {
            params = params.append('pageSize', pageSize.toString());
        }

        let options = {
            params : params
        };

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return new ServerPaginationModel<OfferModel>(OfferModel, response['data']);
        });
    }

    public getMyBids(auctionCatalogId:number) : Observable<ResponseModel> {
        let url:string = OfferService.URL + OfferService.PATH + '/mybids';
        let params = new HttpParams();

        if(!_isNil(auctionCatalogId)) {
            params = params.append('auctionCatalogId', auctionCatalogId.toString());
        }

        let options = {
            params : params
        };

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return new MyBidModel(response['data']);
        });
    }

    public getAuctionWatchlist(auctionCatalogId:number, savedSearchId?:number) : Observable<ResponseModel> {
        let url:string = OfferService.URL + OfferService.PATH + '/watchlist/auctions';
        let params = new HttpParams();

        if(!_isNil(auctionCatalogId)) {
            params = params.append('auctionCatalogId', auctionCatalogId.toString());
        }
        if(!_isNil(savedSearchId)) {
            params = params.append('savedSearchId', savedSearchId.toString());
        }

        let options = {
            params : params
        };

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (offer) => new OfferModel(offer));
        });
    }

    public getAuctionQueue(auctionCatalogId:number, savedSearchId:number) : Observable<ResponseModel> {
        let url:string = OfferService.URL + OfferService.PATH + '/queue/auctions';
        let params = new HttpParams();

        if(!_isNil(auctionCatalogId)) {
            params = params.append('auctionCatalogId', auctionCatalogId.toString());
        }

        if(!_isNil(savedSearchId)) {
            params = params.append('savedSearchId', savedSearchId.toString());
        }

        let options = {
            params : params
        };

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (offer) => new OfferModel(offer));
        });
    }

    public getPassedInAuction(auctionCatalogId:number, savedSearchId:number) : Observable<ResponseModel> {
        let url:string = OfferService.URL + OfferService.PATH + '/auctions/passedin';
        let params = new HttpParams();

        if(!_isNil(auctionCatalogId)) {
            params = params.append('auctionCatalogId', auctionCatalogId.toString());
        }

        if(!_isNil(savedSearchId)) {
            params = params.append('savedSearchId', savedSearchId.toString());
        }

        let options = {
            params : params
        };

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (offer) => new OfferModel(offer));
        });
    }

    public getFixedWatchlist(savedSearchId?:number) : Observable<ResponseModel> {
        let url:string = OfferService.URL + OfferService.PATH + '/watchlist/fixed';
        let params = new HttpParams();
        if(!_isNil(savedSearchId)) {
            params = params.append('savedSearchId', savedSearchId.toString());
        }
        let options = {
            params : params
        };
        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (offer) => new OfferModel(offer));
        });
    }

    public getAvilableLots(lotIds:number[]) : Observable<ResponseModel> {
        let url:string = OfferService.URL + OfferService.PATH + '/availablelots';
        let body:number[] = lotIds ? lotIds : null;
        let options:{} = {};

        return this.http.post(url, body, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (offer) => new OfferLotModel(offer));
        });
    }

    public postOffers(offers:OfferCreateModel[]) : Observable<ResponseModel> {
        let url:string = OfferService.URL + OfferService.PATH;
        let body:any = offers;
        let options:{} = {};

        return this.http.post(url, body, options);
    }

    public postBid(offer:OfferModel, inputBid:BidInputModel) : Observable<ResponseModel> {
        let url:string = OfferService.URL + OfferService.PATH + '/' + offer.offerId.toString() + '/bid/';
        let body:any = inputBid;
        let options:{} = {};

        return this.http.post(url, body, options);
    }



    public meetMarket(offerId:number) : Observable<ResponseModel> {
        let url:string = OfferService.URL + OfferService.PATH + '/' + offerId.toString() + '/meetmarket';
        let body:any = {};
        let options:{} = {};
        return this.http.post(url,body,options);
    }

    public meetReserve(offerId:number) : Observable<ResponseModel> {
        let url:string = OfferService.URL + OfferService.PATH + '/' + offerId.toString() + '/meetreserve';
        let body:any = {};
        let options:{} = {};
        return this.http.post(url,body,options);
    }

    public reauctionOffer(offerId:number) : Observable<ResponseModel> {
        let url:string = OfferService.URL + OfferService.PATH + '/' + offerId.toString() + '/reauction';
        let body:any = {};
        let options:{} = {};
        return this.http.post(url,body,options);
    }

    public postBulkBid(multiBidInput:MultiBidInputModel) : Observable<ResponseModel> {
        let url:string = OfferService.URL + OfferService.PATH + '/bulkbid';
        let body:any = multiBidInput;
        let options:{} = {};

        return this.http.post(url, body, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (bidOutput) => new BidOutputModel(bidOutput));
        });
    }

    public postCancelOffer(offer:OfferModel) : Observable<ResponseModel> {
        let url:string = OfferService.URL + OfferService.PATH + '/' + offer.offerId.toString() + '/cancel';
        let body:any = null;
        let options:{} = {};

        return this.http.post(url, body, options);
    }

    public postWatchList(offer:OfferModel, addToWatchlist:boolean = true) : Observable<ResponseModel> {
        let url:string = OfferService.URL + OfferService.PATH + '/' + offer.offerId.toString() + '/watchlist';
        let body:any = null;
        let options:{} = {};

        if(!addToWatchlist) {
            url += '/remove';
        }
        return this.http.post(url, body, options);
    }

    //TODO : new service for bulletinboard
    public holdFixedLots(offerIds:number[]) : Observable<ResponseModel> {
        let url:string = OfferService.URL + '/bulletinboard/hold';
        let body:any = offerIds;
        let options = {};

        return this.http.post(url, body, options);
    }

    //TODO : new service for bulletinboard
    public releaseFixedLots(offerIds:number[]) : Observable<ResponseModel> {
        let url:string = OfferService.URL + '/bulletinboard/release';
        let body:any = offerIds;
        let options = {};

        return this.http.post(url, body, options);
    }

    public getOfferAvg(offerIds:number[]) : Observable<ResponseModel> {
        let url:string = OfferService.URL + '/bulletinboard/averages';
        let body:any = offerIds;
        let options = {};

        return this.http.post(url, body, options);
    }

    //TODO : new service for bulletinboard
    public postBuy(offerIds:number[]) : Observable<ResponseModel> {
        let url:string = OfferService.URL + '/bulletinboard/bulkbuy';
        let body:any = offerIds;
        let options = {};

        return this.http.post(url, body, options);
    }

    public submitBulletinBoardOffer(offerId:number, offerPrice:number) : Observable<ResponseModel> {
        let url:string = OfferService.URL + '/bulletinboard/' + offerId.toString() + '/buyerOffer';
        let body:any = offerPrice ;
        let options:{} = {};

        return this.http.post(url, body, options);
    }

    public updateBulletinBoardOffer(offerId:number, newPrice:number) : Observable<ResponseModel> {
        let url:string = OfferService.URL + '/bulletinboard/' + offerId.toString() + '/updateprice';
        let body:any = newPrice;
        let options:{} = {};

        return this.http.post(url, body, options);
    }

    public reOfferLot(offerId:number, newPrice:number) : Observable<ResponseModel> {
        let url:string = OfferService.URL + '/bulletinboard/' + offerId.toString() + '/reoffer  ';
        let body:any = newPrice;
        let options:{} = {};

        return this.http.post(url, body, options);
    }

    public withdrawBulletinBoardOffer(offerId:number) : Observable<ResponseModel> {
        let url:string = OfferService.URL + '/bulletinboard/' + offerId.toString() + '/soldwithdraw';
        let body:any = {};
        let options:{} = {};

        return this.http.post(url, body, options);
    }

    public acceptBuyerOffer(offerId:number, buyerEntityId:number) : Observable<ResponseModel> {
        let url:string = OfferService.URL + '/bulletinboard/' + offerId.toString() + '/accept/' + buyerEntityId.toString();
        let body:any = {};
        let options:{} = {};

        return this.http.post(url, body, options);
    }

}
