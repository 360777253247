import { BaseModel } from '~/models/baseModel';
import { UserModel } from '~/models/userModel';
import { EntityCreditCardModel } from '~/models/entityCreditCardModel';
import { map as _map } from 'lodash';

export class EntityTradingOnboardModel extends BaseModel {
    public entityId:number;
    public entityName:string;
    public edi:string;
    public abn:string;
    public paymentMethodId:number;
    public users:UserModel[];
    public creditCard:EntityCreditCardModel;

    constructor(values:Object = {}) {
        super(values);

        if(values['users']) {
            this.users = _map(values['users'], (user) => {
                return new UserModel(user);
            });
        }

        if(values['creditCard']) {
            this.creditCard = new EntityCreditCardModel(values['creditCard']);
        }
    }
}
