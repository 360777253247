import { Injectable } from '@angular/core';
import { HttpResponse, HttpParams } from '@angular/common/http';
import { BaseWebService } from '~/services/api/web/base/baseWebService';
import { Observable, of } from 'rxjs';
import { map as _map, filter as _filter } from 'lodash';
import { map } from 'rxjs/operators';

// Models
import { AuctionCountdownModel } from '~/models/auctionCountdownModel';
import { EntityModel } from '~/models/entityModel';
import { MarketSummaryModel } from '~/models/marketSummaryModel';
import { ResponseModel, ResponseModelCode } from '~/models/responseModel';
import * as moment from 'moment';
import { TenderCatalogModel, TenderCatalogModelStatuses } from '~/models/tenderCatalogModel';
import { TenderCatalogEditModel } from '~/models/tenderCatalogEditModel';

enum TenderCatalogIntStatus
{
    Draft = 1,
    Open = 2,
    Closed = 9
}


@Injectable()
export class TenderCatalogService extends BaseWebService {

    public static readonly PATH:string = '/tenderCatalogs';

    public getTenderCatalogs() : Observable<ResponseModel> {
        let url:string = TenderCatalogService.URL + TenderCatalogService.PATH;
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (data) => new TenderCatalogModel(data));
        });
    }

    public getBrokerTenderCatalogs() : Observable<ResponseModel> {
        let url:string = TenderCatalogService.URL + TenderCatalogService.PATH + '/broker';
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (data) => new TenderCatalogModel(data));
        });
    }

    public getPastTenderCatalogs() : Observable<ResponseModel> {
        let url:string = TenderCatalogService.URL + TenderCatalogService.PATH + '/' + TenderCatalogIntStatus.Closed;
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (data) => new TenderCatalogModel(data));
        });
    }

    public getOpenTenderCatalogs() : Observable<ResponseModel> {
        let url:string = TenderCatalogService.URL + TenderCatalogService.PATH + '/open';
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (data) => new TenderCatalogModel(data));
        });
    }

    public getTenderCatalogsByDate(entityId:number, selectedDate:moment.Moment) : Observable<ResponseModel> {
        let url:string = TenderCatalogService.URL + TenderCatalogService.PATH + '/' + entityId + '/bydate';
        let params = new HttpParams();
        if(selectedDate) {
            params = params.append('selectedDate', selectedDate.format('YYYY-MM-DD'));
        }

        let options = {
            params : params
        };

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return (response && response['data']) ? _map(response['data'], (data) => new TenderCatalogModel(data)) : [];
        });

    }

    public getCurrentAndFutureCatalog() : Observable<ResponseModel> {
        return this.getTenderCatalogs().pipe(
            map((response:ResponseModel) => {
                if(response.code === ResponseModelCode.Ok) {
                    response.data = _filter(response.data, (catalog:TenderCatalogModel) => {
                        return  catalog.status !== TenderCatalogModelStatuses.Closed && catalog.startDate >= moment().startOf('day');
                    })
                }
                return response;
            })
        );
    }

    //@TODO create an API for retriving liveAuctionCatalogs
    public getLiveTenderCatalogs() : Observable<ResponseModel> {
        return this.getTenderCatalogs().pipe(
            map((response:ResponseModel) => {
                if(response.code === ResponseModelCode.Ok) {
                    response.data = _filter(response.data, (auctionCatalog:TenderCatalogModel) => {
                        return auctionCatalog.status === TenderCatalogModelStatuses.Open;
                    })
                }
                return response;
            })
        );
    }

    //@TODO create an API for retriving closedAuctionCatalogs
    public getClosedTebderCatalogs() : Observable<ResponseModel> {
        return this.getTenderCatalogs().pipe(
            map((response:ResponseModel) => {
                if(response.code === ResponseModelCode.Ok) {
                    response.data = _filter(response.data, (auctionCatalog:TenderCatalogModel) => {
                        return auctionCatalog.status === TenderCatalogModelStatuses.Closed;
                    })
                }
                return response;
            })
        );
    }
    public getClosedAndUpcomingTenderCatalogs() : Observable<ResponseModel> {
        return this.getTenderCatalogs().pipe(
            map((response:ResponseModel) => {
                if(response.code === ResponseModelCode.Ok) {
                    response.data = _filter(response.data, (auctionCatalog:TenderCatalogModel) => {
                        return auctionCatalog.status === TenderCatalogModelStatuses.Closed || auctionCatalog.status === TenderCatalogModelStatuses.Draft;
                    })
                }
                return response;
            })
        );
    }

    public getCountdown(auctionCatalogId:number = undefined) : Observable<ResponseModel> {
        let url:string = TenderCatalogService.URL + TenderCatalogService.PATH + '/countdown';
        if(auctionCatalogId) {
            url += '/' + auctionCatalogId.toString();
        }
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return new AuctionCountdownModel(response['data']);
        });
    }

    public createTenderCatalog(auctionCatalogEdit:TenderCatalogEditModel) : Observable<ResponseModel> {
        let url:string = TenderCatalogService.URL + TenderCatalogService.PATH;
        let body:any = auctionCatalogEdit;
        let options:{} = {};

        return this.http.post(url, body, options, (response:HttpResponse<any>) => {
            return new TenderCatalogEditModel(response['data']);
        });
    }

    public finaliseTender(tenderCatalogId:number) : Observable<ResponseModel> {
        let url:string = TenderCatalogService.URL + TenderCatalogService.PATH + '/' + tenderCatalogId.toString() + '/finalise';
        let body:any = {};
        let options:{} = {};

        return this.http.post(url, body, options);
    }

    public getMarketSummary(item:EntityModel|number, months:number = 6) : Observable<ResponseModel> {
        let entityId = (item instanceof EntityModel) ? item.entityId : item;

        let url:string = TenderCatalogService.URL + TenderCatalogService.PATH + '/marketsummary?months=' + months.toString() + '&entityId=' + entityId.toString();
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return new MarketSummaryModel(response['data']);
        });
    }

    public postTenderCatalog(auctionCatalogEdit:TenderCatalogEditModel) : Observable<ResponseModel> {
        let url:string = TenderCatalogService.URL + TenderCatalogService.PATH  + '/' + auctionCatalogEdit.id.toString();
        let body:any = auctionCatalogEdit;
        let options:{} = {};

        return this.http.post(url, body, options, (response:HttpResponse<any>) => {
            return new TenderCatalogEditModel(response['data']);
        });
    }

    public deleteCatalogue(catalogueId:number) : Observable<ResponseModel> {
        let url:string = TenderCatalogService.URL + TenderCatalogService.PATH  + '/' + catalogueId.toString() + '/delete';
        let body:any = {};
        let options:{} = {};

        return this.http.post(url, body, options);
    }

    public exportCatalogPDF(catalogueId:number) : Observable<ResponseModel> {
        let url:string = TenderCatalogService.URL + TenderCatalogService.PATH + '/pdf/' + catalogueId ;

        let params = new HttpParams();
        let options:{} = {
            params : params,
            responseType: 'blob' as 'blob'
        };

        return this.http.get(url, options, (response:HttpResponse<any>) => {
             return response;
        });
    }

}
