import { Component, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { isFunction as _isFunction } from 'lodash';

@Component({
    selector: 'wep-ag-grid-header-renderer-checkbox',
    templateUrl: './checkbox-header.html',
    host: { 'class' : 'wep-ag-grid-header-renderer-checkbox' },
    styleUrls: ['./checkbox-header.scss']
})

export class AgGridHeaderCheckboxComponent implements IHeaderAngularComp, OnDestroy {
    public params: any;
    private changeDetectorRef:ChangeDetectorRef;
    private selected:boolean = false;

    constructor(changeDetectorRef:ChangeDetectorRef) {
        this.changeDetectorRef = changeDetectorRef;
    }

    public agInit(params: any) : void {
        this.params = params;
    }

    public onClick() : void {
        this.selected = !this.selected;
        this.changeDetectorRef.detectChanges();
        if(_isFunction(this.params.clickEvent)) {
            this.params.clickEvent(this.params, this.selected);
        }
    }

    public isDisabled() : boolean {
        if(_isFunction(this.params.isDisabled)) {
            return this.params.isDisabled(this.params);
        }
        return false;
    }

    public refresh() : boolean {
        return false;
    }

    public ngOnDestroy() : void {
        this.changeDetectorRef.detach();
    }
}
