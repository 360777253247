import { PermissionModel } from '~/models/permissionModel';
import { BaleModel } from '~/models/baleModel';
import { MobModel } from '~/models/mobModel';
import { SpecieModel } from '~/models/specieModel';
import { Moment } from '~/decorators/moment/momentDecorator';
import * as moment from 'moment';
import { forEach as _forEach, orderBy as _orderBy, sortedUniqBy as _sortedUniqBy, sumBy as _sumBy, map as _map } from 'lodash';
import { BaseModel } from './baseModel';

export class InvoiceModel extends BaseModel {

    @Moment()
    public invoiceDate:moment.Moment;

    public amount:number;
    public invoiceId:number;
    public entityId:number;
    public blobReference:string;
}
