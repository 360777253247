import { BaseModel } from '~/models/baseModel';
import { Moment } from '~/decorators/moment/momentDecorator';
import * as moment from 'moment';

export class TenderOfferBidderModel extends BaseModel {
    public id:number;
    public bidPrice:number;
    public maxPrice:number;
    public bidderEdi:string;
    public bidderEntityId:number;
    public bidderName:string;
    @Moment()
    public date:moment.Moment | string;
}
