import { NgModule } from '@angular/core';

import { BaseMaskDirective } from '~/directives/masks/baseMask/baseMask';
import { TextAreaDirective } from '~/directives/textarea/textarea';
import { CurrencyMaskDirective } from '~/directives/masks/currencyMask/currencyMask';
import { NumberMaskDirective } from '~/directives/masks//numberMask/numberMask';
import { TimeMaskDirective } from '~/directives/masks/timeMask/timeMask';
import { CVVMaskDirective } from '~/directives/masks/creditCardMasks/cvvMask/cvvMask';
import { MonthYearMaskDirective } from '~/directives/masks/creditCardMasks/monthYearMask/monthYearMask';
import { CreditCardMaskDirective } from '~/directives/masks/creditCardMasks/creditCardMask/creditCardMask';

@NgModule({
    declarations: [
        BaseMaskDirective,
        TextAreaDirective,
        CurrencyMaskDirective,
        NumberMaskDirective,
        TimeMaskDirective,
        CVVMaskDirective,
        MonthYearMaskDirective,
        CreditCardMaskDirective,
    ],
    exports: [
        BaseMaskDirective,
        TextAreaDirective,
        NumberMaskDirective,
        CurrencyMaskDirective,
        TimeMaskDirective,
        CVVMaskDirective,
        MonthYearMaskDirective,
        CreditCardMaskDirective
    ]
})

export class AppDirectivesModule {
}
