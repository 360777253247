import { Injectable } from '@angular/core';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { MobAbstractService } from '~/services/api/web/mobs/mobAbstractService';
import { HttpClientWrapper } from '~/services/api/httpClientWrapper';
import { BaseWebService } from '~/services/api/web/base/baseWebService';
import { ResponseModel } from '~/models/responseModel';
import { MobModel } from '~/models/mobModel';
import { Observable } from 'rxjs';
import { ClipModel } from '~/models/clipModel';
import { map as _map } from 'lodash';

@Injectable()
export class MobService extends BaseWebService implements MobAbstractService {

    public static readonly PATH:string = '/mobs';

    constructor(http: HttpClientWrapper) {
        super(http);
    }

    public getMobs() : Observable<ResponseModel> {
        let url:string = MobService.URL + MobService.PATH;
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (mob) => new MobModel(mob));
        });
    }

    public getMobById(mobId:number) : Observable<ResponseModel> {
        let url:string = MobService.URL + MobService.PATH + '/' + mobId.toString();
        let options:{} = {};


        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return new MobModel(response['data']);
        });
    }

    public getMobsByClip(clip:ClipModel) : Observable<ResponseModel> {
        let url:string  = MobService.URL + MobService.PATH;

        let params = new HttpParams();
        params = params.append('clipId', clip.clipId.toString());

        let options = {
            params : params
        };

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (mob) => new MobModel(mob));
        });
    }

    public postMob(mob : MobModel) : Observable<ResponseModel> {
        let url:string = MobService.URL + MobService.PATH;
        let body:any = mob;
        let options:{} = {};

        return this.http.post(url, body, options, (response:HttpResponse<any>) => {
            return new MobModel(response['data']);
        });
    }

    public saveMobGroup(mobGroup:any):Observable<ResponseModel>{
        let url:string = MobService.URL + MobService.PATH +'/mobgroup';
        let body:any = mobGroup;
        let options:{} = {};

        return this.http.post(url, body, options, (response:HttpResponse<any>) => {
            return new MobModel(response['data']);
        });
    }

    public deleteMob(mob : MobModel) : Observable<ResponseModel> {
        let url:string = MobService.URL + MobService.PATH + '/' + mob.mobId.toString() + '/delete';
        let body:any = null;
        let options:{} = {};

        return this.http.post(url, body, options);
    }

    public deleteMobGroup(mobGroupId:number):Observable<ResponseModel> {
        let url:string = MobService.URL + MobService.PATH + '/mobgroup/' +mobGroupId + '/delete';
        let body:any = {};
        let options:{} = {};

        return this.http.post(url, body, options, (response:HttpResponse<any>) => {
            return new MobModel(response['data']);
        });
    }
}
