import { BaseModel } from '~/models/baseModel';
import { Moment } from '~/decorators/moment/momentDecorator';
import * as moment from 'moment';

export class SalesResultModel extends BaseModel {
    public soldprice:number;
    get getSoldprice():number {
       return Math.round(this.soldprice);
    }

    @Moment()
    public datesold:moment.Moment;
    private _grossvalue:number;
    get grossvalue():number {
       return Math.round(this._grossvalue);
    }
    set grossvalue(value:number) {
        this._grossvalue = value;
    }

    @Moment()
    public proceedsda:moment.Moment;
    private _cleanweight:number;
    get cleanweight():number {
       return Math.round(this._cleanweight);
    }
    set cleanweight(value:number) {
        this._cleanweight = value;
    }

    public _netweight:number;
    get netweight():number {
       return Math.round(this._netweight);
    }
    set netweight(value:number) {
        this._netweight = value;
    }
}
