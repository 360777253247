import { Component, AfterViewInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AgGridCellEditorAbstractComponent } from '~/components/agGrid/cellEditors/abstract/abstract';

@Component({
    selector: 'wep-ag-grid-cell-editor-input',
    templateUrl: './input.html',
    host: { 'class' : 'wep-ag-grid-cell-editor-input' },
    styleUrls: ['./input.scss']
})

export class AgGridCellEditorInputComponent extends AgGridCellEditorAbstractComponent implements AfterViewInit {
    @ViewChild('input', {read: ViewContainerRef, static: false})
    public input:ViewContainerRef;

    constructor(formBuilder:FormBuilder) {
        super(formBuilder);
    }

    public ngAfterViewInit() : void  {
        setTimeout(() => {
            this.input.element.nativeElement.focus();
        });
    }
}