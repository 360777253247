import { BaseModel } from './baseModel';

export class TraceabilitySummaryModel extends BaseModel {

	public value:string;
	public title:string;
	public headers:string;
	public data:string;

    constructor(values: Object = {}) {
        super(values);
    }
}
