import { Component } from '@angular/core';
import { INoRowsOverlayAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'wep-ag-grid-no-rows-overlay',
    host: {
        'class': 'wep-ag-grid-no-rows-overlay'
    },
    templateUrl: './noRowsOverlay.html',
    styleUrls: ['./noRowsOverlay.scss']
})

export class AgGridNoRowsOverlayComponent implements INoRowsOverlayAngularComp {
    public params: any;

    public agInit(params) : void {
        this.params = params;
    }
}