import { BaseModel } from '~/models/baseModel';

export class PermissionModel extends BaseModel {
    public userCanEdit?:boolean;
    public userCanAdminister?:boolean;

    constructor(values?:{}) {
        super(values);

        if(typeof this.userCanAdminister === 'undefined') {
            this.userCanAdminister = false;
        }

        if(typeof this.userCanEdit === 'undefined') {
            this.userCanEdit = false;
        }
    }

    //Permissions
    public isReadOnly() : boolean {
        return !this.userCanAdminister && !this.userCanEdit;
    }
}