import { BaseModel } from '~/models/baseModel';
import { Moment } from '~/decorators/moment/momentDecorator';
import { UserModel } from '~/models/userModel';
import * as moment from 'moment';

export class CommentModel extends BaseModel {
    @Moment()
    public date:moment.Moment;

    public id:number;
    public postId:number;
    public activityStreamId:number;
    public author:number;
    public parentId:number;
    public canDelete:boolean;

    public contact:UserModel;
    public text:string;
}
