import { BaseModel } from '~/models/baseModel';

export enum ResponseModelCode {
    Ok = 'OK',
    Error = 'ERROR'
}

export enum ResponseModelErrorMessages {
    Internal = 'Internal Server Error.',
    Unknown = 'Unknown Error.'
}

export class ResponseModel extends BaseModel {

    public data:any;
    public code:string;
    public errorMessage:string;
    public error:string;

    constructor(values) {
        super(values);
    }
}
