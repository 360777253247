import { MobModel } from '~/models/mobModel';
import { Moment } from '~/decorators/moment/momentDecorator';
import * as moment from 'moment';

export class InventoryMobModel extends MobModel {
    public within3Months:boolean;

    @Moment()
    public createdDate:moment.Moment;
    public description:string;
    public weight:number;
    public overs:number;
    public unders:number;

    constructor(values: Object = {}) {
        super(values);
    }
}