import { BaseModel } from '~/models/baseModel';
import { Moment } from '~/decorators/moment/momentDecorator';
import * as moment from 'moment';

export class WhatIfModel extends BaseModel {
    @Moment()
    public date:moment.Moment;

    public micron:number;
    public year:number;
    public bales:number;
    public grsyValue:number;

    public you:boolean;
    public vmb:number;
    public cln:number;
    public grsy:number;
    public pob:number;
    public balesPerLine:number;
    public total:number;
    public lotIds:number[];
    public salePrice:number;
    public stapleStrength:number;
    public storageCenter:string;
    public wsa:string;
    public netWeight:number;

    get dateFormatted() : string {
        return this.date.format('YYYY/MM/DD');
    }
}
