import { BaseModel } from '~/models/baseModel';
import { SoldLotModel } from '~/models/soldLotModel';
import { BalesByRegionModel } from '~/models/balesByRegionModel';
import { BalesByWoolTypeModel } from '~/models/balesByWoolTypeModel';

import { map as _map } from 'lodash';

export class MarketSummaryModel extends BaseModel {
    public recentTrades:number;
    public woolKg:number;
    public activeBidsOffers:number;

    public soldLots:SoldLotModel[] = [];
    public balesByRegion:BalesByRegionModel[] = [];
    public balesByWoolType:BalesByWoolTypeModel[] = [];

    constructor(values: Object = {}) {
        super(values);

        if ((typeof values['soldLots'] !== 'undefined')) {
            this.soldLots = _map(values['soldLots'], (data:{}) => new SoldLotModel(data));
        }

        if ((typeof values['balesByRegion'] !== 'undefined')) {
            this.balesByRegion = _map(values['balesByRegion'], (data:{}) => new BalesByRegionModel(data));
        }

        if ((typeof values['balesByWoolType'] !== 'undefined')) {
            this.balesByWoolType = _map(values['balesByWoolType'], (data:{}) => new BalesByWoolTypeModel(data));
        }
    }
}
