import { Injectable } from '@angular/core';
import { ClipAbstractService } from '~/services/api/web/clip/clipAbstractService';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { HttpClientWrapper } from '~/services/api/httpClientWrapper';
import { BaseWebService } from '~/services/api/web/base/baseWebService';
import { ResponseModel } from '~/models/responseModel';
import { ClipModel } from '~/models/clipModel';
import { BrandModel } from '~/models/brandModel';
import { Observable } from 'rxjs';
import { SettingsModel } from '~/models/settings/settingsModel';
import { SettingModel } from '~/models/settings/settingModel';
import { map as _map, forEach as _forEach } from 'lodash';

@Injectable()
export class ClipService extends BaseWebService implements ClipAbstractService {

    public static readonly PATH:string = '/clips';

    constructor(http: HttpClientWrapper) {
        super(http);
    }

    public getClips() : Observable<ResponseModel> {
        let url:string = ClipService.URL + ClipService.PATH;
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (clip) => new ClipModel(clip));
        });
    }

    public getClipById(clipId:number) : Observable<ResponseModel> {
        let url:string = ClipService.URL + ClipService.PATH + '/' + clipId.toString();
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return new ClipModel(response['data']);
        });
    }

    public getRecentClip(entityId:number) : Observable<ResponseModel> {
        let url:string = ClipService.URL + ClipService.PATH + '/' + entityId.toString() + '/recent';
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return new ClipModel(response['data']);
        });
    }

    public getClipsByBrand(brand:BrandModel) : Observable<ResponseModel> {
        let url:string = ClipService.URL + ClipService.PATH;

        let params = new HttpParams();
        params = params.append('brandId', brand.brandId.toString());

        let options:{} = {
            params : params
        };

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (clip) => new ClipModel(clip));
        });
    }

    public getClipWoolDescriptions(clip:ClipModel) : Observable<ResponseModel> {
        let url:string = ClipService.URL + ClipService.PATH + '/' + clip.clipId.toString() + '/get_settings';
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            let data:SettingsModel = new SettingsModel();
            let values:SettingModel[] = [];

            _forEach(response['data']['descriptions'], (value) => {
                values.push(new SettingModel({
                    value: value['description'],
                    scope: value['scope']
                }));
            });

            data.setValues(values);

            return data;
        });
    }

    public getClipBinCodes(clip:ClipModel) : Observable<ResponseModel> {
        let url:string = ClipService.URL + ClipService.PATH + '/' + clip.clipId.toString() + '/get_settings';
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            let data:SettingsModel = new SettingsModel();
            let values:SettingModel[] = [];

            _forEach(response['data']['binCodes'], (value) => {
                values.push(new SettingModel({
                    value: value['binCode'],
                    scope: value['scope']
                }));
            });

            data.setValues(values);

            return data;
        });
    }

    public getNextMobNumber(clip:ClipModel) : Observable<ResponseModel> {
        let url:string = ClipService.URL + ClipService.PATH + '/' + clip.clipId.toString() + '/nextmobno';
        let options:{} = {};

        return this.http.get(url, options);
    }

    public getNextBaleNumber(clip:ClipModel) : Observable<ResponseModel> {
        let url:string = ClipService.URL + ClipService.PATH + '/' + clip.clipId.toString() + '/nextbaleno';
        let options:{} = {};

        return this.http.get(url, options);
    }

    public getNextLineNumber(clip:ClipModel) : Observable<ResponseModel> {
        let url:string = ClipService.URL + ClipService.PATH + '/' + clip.clipId.toString() + '/nextlineno';
        let options:{} = {};

        return this.http.get(url, options);
    }

    public getNextSpecieNumber(clip:ClipModel) : Observable<ResponseModel> {
        let url:string = ClipService.URL + ClipService.PATH + '/' + clip.clipId.toString() + '/nextspecieno';
        let options:{} = {};

        return this.http.get(url, options);
    }

    public postClip(clip:ClipModel) : Observable<ResponseModel> {
        let url:string = ClipService.URL + ClipService.PATH;
        let body:any = clip;
        let options:{} = {};

        return this.http.post(url, body, options, (response:HttpResponse<any>) => {
            return new ClipModel(response['data']);
        });
    }

    public deleteClip(clip:ClipModel) : Observable<ResponseModel>{
        let url:string = ClipService.URL + ClipService.PATH + '/' + clip.clipId.toString() + '/delete';
        let body:any = null;
        let options:{} = {};

        return this.http.post(url, body, options);
    }
}
