import { PermissionModel } from '~/models/permissionModel';
import { ClipSalesResultModel } from '~/models/clipSalesResultModel';
import { Moment } from '~/decorators/moment/momentDecorator';
import * as moment from 'moment';
import { uniq as _uniq, concat as _concat } from 'lodash';

export class ClipModel extends PermissionModel {
    public clipId:number;
    public brandID:number;
    //@TODO this is a caveat (of the variable naming convention) being a foreign key with a lowercase “d”
    public entityId:number;
    public salesResult:ClipSalesResultModel;

    @Moment()
    public clipStartDate:moment.Moment;
    public clipReference:string;

    //Settings
    public baleCountSetting:string;
    public customBaleCountStart:number;
    public customBinCodeList:string;
    public customDescriptionList:string;
    public entityDescriptionList:string;
    public entityBinCodeList:string;
    public noScales:boolean;
    public disableWeightDialog?:boolean;

    public classerName:string;
    public broker:string;

    //Statuses
    public status:string;
    public testStatus:string;

    //List of all the bin codes and descriptions
    private binCodes:string[];
    private woolDescriptions:string[];

    /**
     * Sets the bin codes as an array from the get_settings API
     * @param binCodes
     */
    public setBinCodes(binCodes:string[]) {
        this.binCodes = binCodes;
    }

    /**
     * Sets the wool descriptions as an array from the get_settings API
     * @param woolDescriptions
     */
    public setWoolDescriptions(woolDescriptions:string[]) {
        this.woolDescriptions = woolDescriptions;
    }

    /**
     * Retrieves all the bin codes + the custom bin codes
     * @returns {any}
     */
    public getBinCodes() : string[] {
        let binCodes:string[] = [];

        if(this.customBinCodeList) {
            binCodes.push.apply(binCodes, this.customBinCodeList.split(','));
        }

        if(this.entityBinCodeList) {
            binCodes = _uniq(_concat(binCodes,this.entityBinCodeList.split(',')));
        }

        //Add the initial binCodes at the end
        binCodes.push.apply(binCodes, this.binCodes);

        return binCodes;
    }

    /**
     * Retrieves all the wool descriptions + the custom descriptions
     * @returns {any}
     */
    public getWoolDescriptions() : string[] {
        let woolDescriptions:string[] = [];

        if(this.customDescriptionList) {
            woolDescriptions.push.apply(woolDescriptions, this.customDescriptionList.split(','));
        }

        //Add the initial woolDescriptions at the end
        woolDescriptions.push.apply(woolDescriptions, this.woolDescriptions);

        return woolDescriptions;
    }

    public getClipAndEntityWoolDescriptions() : string[] {
        let woolDescriptions:string[] = [];

        if(this.customDescriptionList) {
            woolDescriptions.push.apply(woolDescriptions, this.customDescriptionList.split(','));
        }

        if(this.entityDescriptionList) {
            woolDescriptions = _uniq(_concat(woolDescriptions,this.entityDescriptionList.split(',')));
        }

        //Add the initial woolDescriptions at the end
        woolDescriptions.push.apply(woolDescriptions, this.woolDescriptions);

        return woolDescriptions;
    }

    /**
     * Returns the classerNames seperated by commas
     * @returns {string}
     */
    public getClasserNameDisplay() : string {
        if(this.classerName) {
            let classerNames:string[] = this.classerName.split(',');

            if(classerNames.length > 1) {
                return classerNames.join(', ');
            }
            else if(classerNames.length === 1) {
                return classerNames[0];
            }
        }
        return null;
    }

    /**
     * Returns the brokers seperated by commas
     * @returns {string}
     */
    public getBrokerDisplay() : string {
        if(this.broker) {
            let brokers:string[] = this.broker.split(',');

            if(brokers.length > 1) {
                return brokers.join(', ');
            }
            else if(brokers.length === 1) {
                return brokers[0];
            }
        }
        return null;
    }
}
