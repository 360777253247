import { Pipe, PipeTransform } from '@angular/core';
import { isNil as _isNil } from 'lodash';

@Pipe({
    name: 'wepLimitTo',
    pure: true
})
export class LimitToPipe implements PipeTransform {
   public transform(value: string, length: number) : string {
        let limit = length ? length : 10;
        let trail = '…';

        if(_isNil(value)) {
            return '';
        }

        if(value.length <= limit) {
            return value;
        }

        return value.substr(0, value.lastIndexOf(' ', length)) + trail;
    }
}
