import { BaseModel } from '~/models/baseModel';

export class NabTokenModel extends BaseModel {
    public epS_MERCHANT:string;
    public epS_STORE:string;
    public epS_STORETYPE:string;
    public epS_TXNTYPE:string;
    public epS_TIMESTAMP:string;
    public epS_REFERENCEID:string;
    public epS_EXPIRYMONTH:string;
    public epS_EXPIRYYEAR:string;
    public epS_CCV:string;
    public epS_CARDNUMBER:string;
    public epS_RESULTURL:string;
    public epS_FINGERPRINT:string;
}