import { BaseModel } from '~/models/baseModel';
import { OfferLotModel } from '~/models/offerLotModel';
import { OfferSaleModel } from '~/models/offerSaleModel';
import { Moment } from '~/decorators/moment/momentDecorator';
import * as moment from 'moment';
import { BidSummaryModel } from './bidSummaryModel';

export enum OfferStatuses {
    Open = 10,
    PreAuction = 15,
    AuctionQueue = 17,
    ReAuction = 18,
    Auction = 20,
    NearReserve = 30,
    OnMarket = 40,
    Sold = 60,
    SoldWithdraw = 61,
    Unsold = 70,
    Cancelled = 80
}

export class OfferModel extends BaseModel {
    public offerId:number;
    public transactionId:number;
    public type:string;
    public buynow:number;
    public reserve:number;
    public startPrice:number;
    public currentBid:number;
    public currentBidUserEmail:string;
    public maxBid:number;
    public seller:string;
    public sellerEDI:string;
    public sellerId:number;
    public brand:string;
    public brandId:number;
    public auction:string;
    public heldBy:string;
    public heldById:number;
    public myBid:number;
    public status:OfferStatuses;
    public lot:OfferLotModel;
    public sale:OfferSaleModel;
    public inWatchList:boolean;
    public canCancel:boolean;
    public canPurchase:boolean;
    public lotNo:string;
    public mySaleAccount:string;
    public canReauction:boolean;
    public reauctionTillSec:number;
    public canMeetMarket:boolean;
    public readonly isTender:boolean;
    public holdStatus:boolean;
    public canReoffer:boolean;
    public bids:BidSummaryModel[];
    public qScheme1:string;
    public qScheme2:string;
    public qScheme3:string;

    @Moment()
    public auctionEnd:moment.Moment;

    @Moment()
    public reauctionEnd:moment.Moment;

    @Moment()
    public expiry:moment.Moment;

    constructor(values:Object = {}) {
        if(values['status'] in OfferStatuses) {
            values['status'] = OfferStatuses[values['status']];
        }
        super(values);
        this.isTender = false;
        if(values['lot']) {
            this.lot = new OfferLotModel(values['lot']);
            this.lot.lotNo = this.lotNo;
        }

        if(values['sale']) {
            this.sale = new OfferSaleModel(values['sale']);
        }

        if(values['reauctionTillSec']) {
            this.reauctionEnd = moment().add(values['reauctionTillSec'], 'seconds').clone();
        }
    }

    public isAuctionRunning() : boolean {
        return  this.status === OfferStatuses.Auction || OfferStatuses[this.status.toString()] >= OfferStatuses.Auction ||
                this.status === OfferStatuses.NearReserve || OfferStatuses[this.status.toString()] >= OfferStatuses.NearReserve ||
                this.status === OfferStatuses.OnMarket || OfferStatuses[this.status.toString()] >= OfferStatuses.OnMarket;
    }

    public isAuction() : boolean {
        return !!this.startPrice;
    }

    public isOffer() : boolean {
        return !!this.buynow;
    }

    public isOfferOpen() : boolean {
        return this.status === OfferStatuses.Open ||  OfferStatuses[this.status.toString()] === OfferStatuses.Open;
    }

    public isOfferUnsold() : boolean {
        return this.status === OfferStatuses.Unsold ||  OfferStatuses[this.status.toString()] === OfferStatuses.Unsold;
    }

    public isNearReserve() : boolean {
        return this.status === OfferStatuses.NearReserve ||  OfferStatuses[this.status.toString()] === OfferStatuses.NearReserve;
    }

    public isOfferReauction() : boolean {
        return this.status === OfferStatuses.ReAuction ||  OfferStatuses[this.status.toString()] === OfferStatuses.ReAuction;
    }

    public isOfferSold() : boolean {
        return this.status === OfferStatuses.Sold ||  OfferStatuses[this.status.toString()] === OfferStatuses.Sold;
    }

    public isOfferClosed() : boolean {
        return this.status >= OfferStatuses.Sold || OfferStatuses[this.status.toString()] >= OfferStatuses.Sold;
    }

    public isOfferSoldWithdraw() : boolean {
        return this.status === OfferStatuses.SoldWithdraw ||  OfferStatuses[this.status.toString()] === OfferStatuses.SoldWithdraw;
    }
}
