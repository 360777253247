import { BaseModel } from '~/models/baseModel';
import { OfferStatuses } from './offerModel';

export class BidOutputModel extends BaseModel {
    public offerId:number;
    public myBid:number;
    public currentBid:number;
    public heldBy:string;
    public message:number;
    public won:boolean;
    public price:number;
    public status:OfferStatuses;
    public account:string;
    public offerLotNo:string;

    constructor(values:Object = {}) {
        if(values['status'] in OfferStatuses) {
            values['status'] = OfferStatuses[values['status']];
        }
        super(values);
    }
}
