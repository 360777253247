import { PicModel } from '~/models/picModel';
import { MulesingModel } from '~/models/constants/mulesingModel';
import { BankModel } from '~/models/bankModel';
import { PartnerModel } from '~/models/partnerModel';
import { QualitySchemeModel } from '~/models/qualitySchemeModel';
import { UserModel } from '~/models/userModel';
import { PermissionModel } from '~/models/permissionModel';
import { forEach as _forEach } from 'lodash';
import { SustainabilityModel } from './sustainabilityModel';
import { QualitySchemeEligibilityModel } from './qualitySchemeEligibilityModel';
import { AttributesModel } from './attributesModel';

export class BrandModel extends PermissionModel {

    public brandId:number;
    public parentEntityID:number;
    public hobbyNatureID:number;
    public brandName:string;
    public wsaid:number;
    public isHobby:boolean;
    public brandLogoURL:string;
    public brandDescription:string;
    public yearEstablished:string;
    public mulesing:MulesingModel;
    public mobCrutched:boolean;
    public entityName:string;
    public entityEmail:string;
    public growerBrokerId:string;

    public streetAddress:string;
    public streetAddress2:string;
    public phone:string;
    public mobile:string;
    public email:string;
    public abn:string;
    public ownerManagerName:string;
    public pic:string;

    public suburb:string;
    public state:string;
    public country:string;
    public postcode:string;

    public chemicalsUse:boolean;

    public partners:PartnerModel[] = [];
    public qualitySchemas:QualitySchemeModel[] = [];
    public eligibleSchemes:QualitySchemeEligibilityModel[] = [];
    public users:UserModel[] = [];
    public pics:PicModel[] = [];
    public sustainability:SustainabilityModel;
    public attributes:AttributesModel;


    // Bank details
    public bsb:string;
    public bankName:string;
    public swiftCode:string;
    public iban:string;

    //Misc
    public entityIDs:number[];
    public gstRegistered:boolean;
    public averageClipSize:number;
    public averageSaleValue:number;
    public woolProduced:string;
    public sheepNumbers:number;
    public bloodLines:string;
    public awards:string;
    public accountNo:string;
    public linkingCode:string;
    public woolClipLinked:string;

    public isActive?:boolean;

    constructor(values: Object = {}) {
        super(values);

        let init : {}[] = [
            {'prop' : 'mulesing', 'model': 'MulesingModel'},
        ];

        _forEach(init, (item:any) => {
            let prop = item.prop;
            let model = item.model;
            values[prop] = (typeof values[prop] !== 'undefined') ? values[prop] : {};
            this[prop] =  this.instantiate(model, values[prop]);
        });

        if (typeof values['partners'] !== 'undefined') {
            this.partners = values['partners'].map((partner) => new PartnerModel(partner));
        }

        if (typeof values['qualitySchemas'] !== 'undefined') {
            this.qualitySchemas = values['qualitySchemas'].map((scheme) => new QualitySchemeModel(scheme));
        }

        if (typeof values['eligibleSchemes'] !== 'undefined') {
            this.eligibleSchemes = values['eligibleSchemes'].map((scheme) => new QualitySchemeEligibilityModel(scheme));
        }

        if (typeof values['users'] !== 'undefined') {
            this.users = values['users'].map((user) => new UserModel(user));
        }

        if (typeof values['piCs'] !== 'undefined') {
            this.pics = values['piCs'].map((pic) => new PicModel(pic));
        }

        if(typeof values['sustainability'] !== 'undefined') {
            this.sustainability = new SustainabilityModel(values['sustainability']);
        }
    }

    private instantiate(model:string, data:any) : any {
        let obj = {};

        if (model === 'MulesingModel') {
            obj = new MulesingModel(data);
        }

        return obj;
    }

    get accountName() {
        return this.bankName;
    }

    set accountName(value) {
        this.bankName = value;
    }

    set bank(bank:BankModel){
        this.bankName = bank.bankName;
        this.bsb = bank.bsb;
        this.accountNo = bank.accountNo;
        this.iban = bank.iban;
        this.swiftCode = bank.swiftCode;
    }

    get bank() : BankModel {
        return new BankModel({
            'bsb': this.bsb,
            'accountNo': this.accountNo,
            'accountName': this.accountName,
            'iban': this.iban,
            'swiftCode': this.swiftCode,
        });
    }

    get picDetails() {
        return new PicModel({
            pic : this.pic
        });
    }

    set picDetails(value:PicModel) {
        this.pic = value.pic;
    }

    set entityId(value) {
        this.parentEntityID = value;
    }

    set telephone(value) {
        this.phone = value;
    }

    get entityId() : number {
        return this.parentEntityID;
    }

    get brandNameLabel() : string {
        return (this.brandName && this.brandName.length > 0) ? this.brandName : '-';
    }

    get ownerManagerNameLabel() : string {
        return (this.ownerManagerName && this.ownerManagerName.length > 0) ? this.ownerManagerName : '-';
    }
}
