import { ServerPaginationModel } from '~/models/serverPagination/serverPaginationModel';
import { OfferStatsModel } from '~/models/offerStatsModel';

export class OrderBookServerPaginationModel<T> extends ServerPaginationModel<T> {
    public stats:OfferStatsModel;

    constructor(type:{ new (values:Object): T }, values: Object = {}) {
        super(type, values);

        if(values['stats']) {
            this.stats = new OfferStatsModel(values['stats']);
        }
    }
}