<input type="hidden"
       [formControl]="formGroup.controls['cell']"
       [matDatepicker]="datePicker"
       [min]="params?.datepickerSettings?.min">
<mat-datepicker [touchUi]="true"
                #datePicker
                (closed)="onDatepickerClosed()">
</mat-datepicker>
<div class="datepicker"
     (click)="datePicker.open()"
     tabindex="0">
    <div class="datepicker__text" *ngIf="formGroup.controls['cell'].value">{{formGroup.controls['cell'].value | amDateFormat:'YYYY-MM-DD'}}</div>
    <div class="datepicker__text datepicker__text--empty" *ngIf="!formGroup.controls['cell'].value">____-__-__</div>
</div>