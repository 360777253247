import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpCancelService } from '~/services/util/httpCancelService';
import { takeUntil } from 'rxjs/operators';

/**
 * Util interceptor that is used to cancel requests.
 * This cancels the request using httpCancelService (and is used when a user redirects to another route).
 */
@Injectable()
export class HttpCancelInterceptor implements HttpInterceptor {
    private httpCancelService:HttpCancelService;

    constructor(httpCancelService: HttpCancelService) {
        this.httpCancelService = httpCancelService;
    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        return next.handle(request).pipe(
            takeUntil(this.httpCancelService.onCancelPendingRequests())
        );
    }
}