import { BaseModel } from '~/models/baseModel';
import { Moment } from '~/decorators/moment/momentDecorator';
import * as moment from 'moment';

export class NewsModel extends BaseModel {
    public title:string;
    public summary:string;
    public newsCategory:number;
    public rssReadMoreUrl:string;
    public id:number;
    public thumbnailUrl:string;
    public category:string;
    public name:string;
    public url:string;
    public content:any;
    public urlSegment:string;

    @Moment()
    public updatedDate:moment.Moment;
    public publicationDate:moment.Moment;

    get isFeature() : boolean {
        return (this.thumbnailUrl && this.thumbnailUrl.length > 0);
    }

    get getExternalUrl() : string {
        return `${new URL(process.env['API_WOOL_CONTENT_API']).origin}${this.url}`
       }
}
