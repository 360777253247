import { BaseModel } from '~/models/baseModel';
import { OfferStatModel } from '~/models/offerStatModel';

export class OfferStatsModel extends BaseModel {
    public micron:OfferStatModel;
    public vm:OfferStatModel;
    public sdr1:OfferStatModel;
    public sdr16:OfferStatModel;
    public sdr17:OfferStatModel;
    public acy:OfferStatModel;
    public jcsy:OfferStatModel;
    public mm:OfferStatModel;
    public nkt:OfferStatModel;
    public numOfBales:OfferStatModel;
    public soldPrice:OfferStatModel;

    constructor(values: Object = {}) {
        super(values);
        this.micron = new OfferStatModel(values['micron']);
        this.vm = new OfferStatModel(values['vm']);
        this.sdr1 = new OfferStatModel(values['sdr1']);
        this.sdr16 = new OfferStatModel(values['sdr16']);
        this.sdr17 = new OfferStatModel(values['sdr17']);
        this.acy = new OfferStatModel(values['acy']);
        this.jcsy = new OfferStatModel(values['jcsy']);
        this.mm = new OfferStatModel(values['mm']);
        this.nkt = new OfferStatModel(values['nkt']);
        this.numOfBales = new OfferStatModel(values['numOfBales']);
        this.soldPrice = new OfferStatModel(values['soldPrice']);
    }
}