import { Component, AfterViewInit } from '@angular/core';
import { Router, ActivationEnd } from '@angular/router';
import { TitleTemplateService } from '~/templates/services/titleService';
import { Angulartics2GoogleAnalytics } from 'angulartics2';
import { HttpCancelService } from '~/services/util/httpCancelService';

let objectFitImages = require('object-fit-images');

import './style/app.scss';

@Component({
    selector: 'wep-app',
    templateUrl: './app.html',
    styleUrls: ['./app.scss']
})

export class AppComponent implements AfterViewInit {
    private titleService:TitleTemplateService;
    private angulartics2GoogleAnalytics:Angulartics2GoogleAnalytics;
    private httpCancelService:HttpCancelService;
    private router:Router;

    constructor(titleService:TitleTemplateService,
                angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
                httpCancelService:HttpCancelService,
                router:Router) {
        this.titleService = titleService;
        this.angulartics2GoogleAnalytics = angulartics2GoogleAnalytics;
        // this.angulartics2GoogleTagManager.startTracking();
        // this.angulartics2GoogleAnalytics.startTracking();

        this.httpCancelService = httpCancelService;
        this.router = router;

        this.router.events.subscribe(event => {
            if (event instanceof ActivationEnd) {
                this.httpCancelService.cancelPendingRequests();
            }
        });
    }

    public ngAfterViewInit() : void {
        objectFitImages();
    }
}
