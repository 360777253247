import { BaseModel } from '~/models/baseModel';

export class BrokerFeesModel extends BaseModel {
    public transportUnit:string;
    public transportAmount:number;
    public insuranceUnit:string;
    public insuranceAmount:number;
    public storageUnit:string;
    public storageAmount:number;
    public lottingUnit:string;
    public lottingAmount:number;
    public miscellaneousUnit:string;
    public miscellaneousAmount:number;

    public hasValues() : boolean {
        let hasValues = (typeof this.transportUnit !== 'undefined' && this.transportUnit.length > 0);
        hasValues = hasValues || (typeof this.insuranceUnit !== 'undefined' && this.insuranceUnit.length > 0);
        hasValues = hasValues || (typeof this.storageUnit !== 'undefined' && this.storageUnit.length > 0);
        hasValues = hasValues || (typeof this.lottingUnit !== 'undefined' && this.lottingUnit.length > 0);
        hasValues = hasValues || (typeof this.miscellaneousUnit !== 'undefined' && this.miscellaneousUnit.length > 0);

        hasValues = hasValues || (this.transportAmount > 0);
        hasValues = hasValues || (this.insuranceAmount > 0);
        hasValues = hasValues || (this.storageAmount > 0);
        hasValues = hasValues || (this.lottingAmount > 0);
        hasValues = hasValues || (this.miscellaneousAmount > 0);

        return hasValues;
    }
}
