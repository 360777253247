import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class HeroCarouselService {

    private showCarouselSubject:BehaviorSubject<string> = new BehaviorSubject<string>(null);

    public showCarousel(url:string) : void {
        this.showCarouselSubject.next(url);
    }

    public showCarouselObservable() : Observable<string> {
        return this.showCarouselSubject.asObservable();
    }
}
