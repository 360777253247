import { BaseModel } from  '~/models/baseModel';
import { ArrayBooleanMapper } from '~/decorators/arrayBooleanMapper/arrayBooleanMapperDecorator';

export class ClasserModel extends BaseModel {

    public entityId:number;
    public classerID:string;
    public allowFeedback:boolean;
    public regionsServiced:string;
    public averageNumberOfStands:string;
    public averageNumberOfShearsInShed:string;
    public experienceYears:number;

    @ArrayBooleanMapper('certificationsObject', [
        { key : 'certificateIII', value: 'CertificateIII' },
        { key : 'certificateIV', value: 'CertificateIV' },
        { key : 'masterclasser', value: 'Masterclasser' },
    ])
    public certifications:string[];

    public hasValues() : boolean {
        let hasValues = (typeof this.classerID !== 'undefined' && this.classerID.length > 0);
        hasValues = hasValues || (typeof this.allowFeedback !== 'undefined' && this.allowFeedback);
        hasValues = hasValues || (typeof this.regionsServiced !== 'undefined' && this.regionsServiced.length > 0);
        hasValues = hasValues || (typeof this.averageNumberOfStands !== 'undefined' && parseInt(this.averageNumberOfStands, 10) > 0);
        hasValues = hasValues || (typeof this.averageNumberOfShearsInShed !== 'undefined' && parseInt(this.averageNumberOfShearsInShed, 10) > 0);
        hasValues = hasValues || (this.experienceYears > 0);
        hasValues = hasValues || (this.certifications.length > 0);

        return hasValues;
    }
}
