import { BaseModel } from '~/models/baseModel';

export class LineTestResultModel extends BaseModel {
    public lineId:number;
    public micron:string;
    public vm:string;
    public ss:string;
    public yield:string;
    public lineNo:number;
    public specieId?:number;

    constructor(values: Object = {}) {
        super(values);
    }
}
