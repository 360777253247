import { Injectable } from '@angular/core';
import { HttpResponse, HttpParams } from '@angular/common/http';
import { AuctionCatalogAbstractService } from '~/services/api/web/auctionCatalog/auctionCatalogAbstractService';
import { BaseWebService } from '~/services/api/web/base/baseWebService';
import { Observable, of } from 'rxjs';
import { map as _map, filter as _filter } from 'lodash';
import { map } from 'rxjs/operators';

// Models
import { AuctionCatalogEditModel } from '~/models/auctionCatalogEditModel';
import { AuctionCatalogModel, AuctionCatalogModelStatuses } from '~/models/auctionCatalogModel';
import { AuctionCountdownModel } from '~/models/auctionCountdownModel';
import { EntityModel } from '~/models/entityModel';
import { MarketSummaryModel } from '~/models/marketSummaryModel';
import { ResponseModel, ResponseModelCode } from '~/models/responseModel';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import * as moment from 'moment';

enum AuctionCatalogIntStatus
{
    Upcoming = 1,
    Live = 2,
    Closed = 3,
    Cancelled = 4
}

@Injectable()
export class AuctionCatalogService extends BaseWebService implements AuctionCatalogAbstractService {

    public static readonly PATH:string = '/auctioncatalogs';

    public getAuctionCatalogs() : Observable<ResponseModel> {
        let url:string = AuctionCatalogService.URL + AuctionCatalogService.PATH;
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (data) => new AuctionCatalogModel(data));
        });
    }

    public getPastAuctionCatalogs() : Observable<ResponseModel> {
        let url:string = AuctionCatalogService.URL + AuctionCatalogService.PATH + '/pastcatalogs';
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (data) => new AuctionCatalogModel(data));
        });
    }

    public getOpenAuctionCatalogs() : Observable<ResponseModel> {
        let url:string = AuctionCatalogService.URL + AuctionCatalogService.PATH + '/open';
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (data) => new AuctionCatalogModel(data));
        });
    }

    public getAuctionCatalogsByDate(brokerId:number, selectedDate:moment.Moment) : Observable<ResponseModel> {

        let url:string = AuctionCatalogService.URL + AuctionCatalogService.PATH + '/' + brokerId.toString() + '/bydate';
        let params = new HttpParams();
        if(selectedDate) {
            params = params.append('selectedDate', selectedDate.format('YYYY-MM-DD'));
        }

        let options = {
            params : params
        };

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return (response && response['data']) ? _map(response['data'], (data) => new AuctionCatalogModel(data)) : [];
        });

    }

    public getCurrentAndFutureCatalog() : Observable<ResponseModel> {
        return this.getAuctionCatalogs().pipe(
            map((response:ResponseModel) => {
                if(response.code === ResponseModelCode.Ok) {
                    response.data = _filter(response.data, (catalog:AuctionCatalogModel) => {
                        return  catalog.status !== AuctionCatalogModelStatuses.Closed && catalog.open && catalog.date >= moment().startOf('day');
                    })
                }
                return response;
            })
        );
    }

    //@TODO create an API for retriving liveAuctionCatalogs
    public getLiveAuctionCatalogs() : Observable<ResponseModel> {
        return this.getAuctionCatalogs().pipe(
            map((response:ResponseModel) => {
                if(response.code === ResponseModelCode.Ok) {
                    response.data = _filter(response.data, (auctionCatalog:AuctionCatalogModel) => {
                        return auctionCatalog.status === AuctionCatalogModelStatuses.Live;
                    })
                }
                return response;
            })
        );
    }

    //@TODO create an API for retriving closedAuctionCatalogs
    public getClosedAuctionCatalogs() : Observable<ResponseModel> {
        return this.getAuctionCatalogs().pipe(
            map((response:ResponseModel) => {
                if(response.code === ResponseModelCode.Ok) {
                    response.data = _filter(response.data, (auctionCatalog:AuctionCatalogModel) => {
                        return auctionCatalog.status === AuctionCatalogModelStatuses.Closed;
                    })
                }
                return response;
            })
        );
    }
    public getClosedAndUpcomingAuctionCatalogs() : Observable<ResponseModel> {
        return this.getAuctionCatalogs().pipe(
            map((response:ResponseModel) => {
                if(response.code === ResponseModelCode.Ok) {
                    response.data = _filter(response.data, (auctionCatalog:AuctionCatalogModel) => {
                        return auctionCatalog.status === AuctionCatalogModelStatuses.Closed || auctionCatalog.status === AuctionCatalogModelStatuses.Upcoming;
                    })
                }
                return response;
            })
        );
    }

    public getCountdown(auctionCatalogId:number = undefined) : Observable<ResponseModel> {
        let url:string = AuctionCatalogService.URL + AuctionCatalogService.PATH + '/countdown';
        if(auctionCatalogId) {
            url += '/' + auctionCatalogId.toString();
        }
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return new AuctionCountdownModel(response['data']);
        });
    }

    public createAuctionCatalog(auctionCatalogEdit:AuctionCatalogEditModel) : Observable<ResponseModel> {
        let url:string = AuctionCatalogService.URL + AuctionCatalogService.PATH;
        let body:any = auctionCatalogEdit;
        let options:{} = {};

        return this.http.post(url, body, options, (response:HttpResponse<any>) => {
            return new AuctionCatalogEditModel(response['data']);
        });
    }

    public getMarketSummary(item:EntityModel|number, months:number = 6) : Observable<ResponseModel> {
        let entityId = (item instanceof EntityModel) ? item.entityId : item;

        let url:string = AuctionCatalogService.URL + AuctionCatalogService.PATH + '/marketsummary?months=' + months.toString() + '&entityId=' + entityId.toString();
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return new MarketSummaryModel(response['data']);
        });
    }

    public postAuctionCatalog(auctionCatalogEdit:AuctionCatalogEditModel) : Observable<ResponseModel> {
        let url:string = AuctionCatalogService.URL + AuctionCatalogService.PATH  + '/' + auctionCatalogEdit.id.toString();
        let body:any = auctionCatalogEdit;
        let options:{} = {};

        return this.http.post(url, body, options, (response:HttpResponse<any>) => {
            return new AuctionCatalogEditModel(response['data']);
        });
    }

    public deleteCatalogue(catalogueId:number) : Observable<ResponseModel> {
        let url:string = AuctionCatalogService.URL + AuctionCatalogService.PATH  + '/' + catalogueId.toString() + '/delete';
        let body:any = {};
        let options:{} = {};

        return this.http.post(url, body, options);
    }

    public exportCatalogPDF(catalogueId:number) : Observable<ResponseModel> {
        let url:string = AuctionCatalogService.URL + AuctionCatalogService.PATH + '/pdf/' + catalogueId ;

        let params = new HttpParams();
        let options:{} = {
            params : params,
            responseType: 'blob' as 'blob'
        };

        return this.http.get(url, options, (response:HttpResponse<any>) => {
             return response;
        });
    }

}
