import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class HttpCancelService {
    private cancelPendingRequestsSubject = new Subject<void>();

    constructor() { }

    public cancelPendingRequests() : void {
        this.cancelPendingRequestsSubject.next();
    }

    public onCancelPendingRequests() : Observable<void> {
        return this.cancelPendingRequestsSubject.asObservable();
    }
}