import { Injectable } from '@angular/core';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { map as _map } from 'lodash';

// Model
import { ResponseModel } from '~/models/responseModel';
import { UserPreferenceModel } from '~/models/userPreferenceModel';

// Rxjs
import { Observable } from 'rxjs';

// Services
import { BaseWebService } from '~/services/api/web/base/baseWebService';
import { UserPreferenceAbstractService } from '~/services/api/web/userPreference/userPreferenceAbstractService';
import { HttpClientWrapper } from '~/services/api/httpClientWrapper';
import { UserPreferenceModelDataParser } from './userPreferenceModelDataParser';

import { isNil as _isNil } from 'lodash';

@Injectable()
export class UserPreferenceService extends BaseWebService implements UserPreferenceAbstractService {

    public static readonly PATH:string = '/userpreference';

    constructor(http:HttpClientWrapper) {
        super(http);
    }

    public getPreferences() : Observable<ResponseModel> {
        let url:string = UserPreferenceService.URL + UserPreferenceService.PATH;
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (userPreference) => new UserPreferenceModel(userPreference));
        });
    }

    public getUserPreferenceStringValue(key:string) : Observable<ResponseModel> {
        return this.getUserPreference<string>(key, UserPreferenceModelDataParser.parseStringValue);
    }

    public getUserPreferenceNumberValue(key:string) : Observable<ResponseModel> {
        return this.getUserPreference<number>(key, UserPreferenceModelDataParser.parseNumberValue);
    }

    public getUserPreferenceBooleanValue(key:string) : Observable<ResponseModel> {
        return this.getUserPreference<boolean>(key, UserPreferenceModelDataParser.parseBooleanValue);
    }

    public getUserPreferenceObjectValue(key:string) : Observable<ResponseModel> {
        return this.getUserPreference<Object>(key, UserPreferenceModelDataParser.parseObjectValue);
    }

    private getUserPreference<T>(key:string, parseValue:(userPreferenceModel:UserPreferenceModel<T>) => UserPreferenceModel<T>) : Observable<ResponseModel> {
        let url:string = UserPreferenceService.URL + UserPreferenceService.PATH;
        let params = new HttpParams();
        params = params.append('key', key);

        let options:{} = {
            params : params
        };

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            let userPreferences:UserPreferenceModel<T>[] = _map(response['data'], (userPreference) => {
                return new UserPreferenceModel<T>(userPreference);
            });

            let userPreferenceModel:UserPreferenceModel<T> = new UserPreferenceModel<T>({
                key: key,
                value: null
            });

            if(userPreferences.length > 0) {
                userPreferenceModel = userPreferences[0];
            }

            return parseValue(userPreferenceModel);
        });
    }

    public postUserPreferenceStringValue(key:string, value:string) : Observable<ResponseModel> {
        return this.postUserPreference<string>(new UserPreferenceModel<string>({
            key: key,
            value: value
        }), UserPreferenceModelDataParser.parseStringValue);
    }

    /**
     * postUserPreferenceBooleanValue
     *
     *    In the db the value get stored as string and the two possible values are 1 or 0
     *
     * @param string key
     * @param boolean value
     * @returns {}
     */
    public postUserPreferenceBooleanValue(key:string, value:boolean) : Observable<ResponseModel> {
        let stringBoolean:string = value ? '1' : '0';
        return this.postUserPreference<boolean>(new UserPreferenceModel<string>({
            key: key,
            value: stringBoolean
        }), UserPreferenceModelDataParser.parseBooleanValue);
    }

    public postUserPreferenceNumberValue(key:string, value:number) : Observable<ResponseModel> {
        let stringNumber = !_isNil(value) ? value.toString() : '';
        return this.postUserPreference<number>(new UserPreferenceModel<string>({
            key: key,
            value: stringNumber
        }), UserPreferenceModelDataParser.parseNumberValue);
    }

    public postUserPreferenceObjectValue(key:string, value:{}) : Observable<ResponseModel> {
        return this.postUserPreference<Object>(new UserPreferenceModel<string>({
            key: key,
            value: JSON.stringify(value)
        }), UserPreferenceModelDataParser.parseObjectValue);
    }

    private postUserPreference<T>(userPreference:UserPreferenceModel<any>, parseValue:((string) => any)) : Observable<ResponseModel> {
        let url:string = UserPreferenceService.URL + UserPreferenceService.PATH;
        let body:any = userPreference;

        let options:{} = {};

        return this.http.post(url, body, options, (response:HttpResponse<any>) => {
            let userPreference = new UserPreferenceModel<T>(response['data']);
            return parseValue(userPreference);
        });
    }

    public deleteUserPreference(key:string) : Observable<ResponseModel> {
        let url:string = UserPreferenceService.URL + UserPreferenceService.PATH + '/key/' + key + '/delete';
        let body:any = null;
        let options:{} = {};

        return this.http.post(url, body, options);
    }
}
