import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';

//Services
import { HttpClientWrapper } from '~/services/api/httpClientWrapper';
import { BaseWebService } from '~/services/api/web/base/baseWebService';
import { TradingAbstractService } from '~/services/api/web/trading/tradingAbstractService';

// Models
import { ResponseModel } from '~/models/responseModel';
import { MarketOnBoardingModel } from '~/models/marketOnBoardingModel';
import { TradingRegistrationModel } from '~/models/tradingRegistrationModel';

// RxJS
import { Observable } from 'rxjs';

// Lodash
import { map as _map } from 'lodash';

@Injectable()
export class TradingService extends BaseWebService implements TradingAbstractService {

    public static readonly PATH:string = '/trading';

    constructor(http: HttpClientWrapper) {
        super(http);
    }

    public postRegister(onBoarding:MarketOnBoardingModel) : Observable<ResponseModel> {
        let url:string = TradingService.URL + TradingService.PATH + '/register';
        let body:any = onBoarding;
        let options:{} = {};

        return this.http.post(url, body, options, (response:HttpResponse<any>) => {
            return response;
        });
    }

    public postStatus(businessTrader:TradingRegistrationModel[]) : Observable<ResponseModel> {
        let url:string = TradingService.URL + TradingService.PATH + '/statuses';
        let body:any = businessTrader;
        let options:{} = {};

        return this.http.post(url, body, options, (response:HttpResponse<any>) => {
            return response;
        });
    }

    public postAccept(tradingRegistration:TradingRegistrationModel) : Observable<ResponseModel> {
        let url:string = TradingService.URL + TradingService.PATH + '/' + tradingRegistration.tradingRequestId.toString() + '/approve';
        let body:any = null;
        let options:{} = {};

        return this.http.post(url, body, options);
    }

    public postReject(tradingRegistration:TradingRegistrationModel) : Observable<ResponseModel> {
        let url:string = TradingService.URL + TradingService.PATH + '/' + tradingRegistration.tradingRequestId.toString() + '/decline';
        let body:any = null;
        let options:{} = {};

        return this.http.post(url, body, options);
    }

    public postRevoke(tradingRegistration:TradingRegistrationModel) : Observable<ResponseModel> {
        let url:string = TradingService.URL + TradingService.PATH + '/' + tradingRegistration.tradingRequestId.toString() + '/revoke';
        let body:any = null;
        let options:{} = {};

        return this.http.post(url, body, options);
    }

    public postCancel(tradingRegistration:TradingRegistrationModel) : Observable<ResponseModel> {
        let url:string = TradingService.URL + TradingService.PATH + '/' + tradingRegistration.tradingRequestId.toString() + '/cancel';
        let body:any = null;
        let options:{} = {};

        return this.http.post(url, body, options);
    }

    public postOnHold(tradingRegistration:TradingRegistrationModel) : Observable<ResponseModel> {
        let url:string = TradingService.URL + TradingService.PATH + '/' + tradingRegistration.tradingRequestId.toString() + '/hold';
        let body:any = null;
        let options:{} = {};

        return this.http.post(url, body, options);
    }

    public getTradingRegistration(entityId:number) : Observable<ResponseModel> {
        let url:string = TradingService.URL + TradingService.PATH + '/entity/' + entityId.toString() + '/registration';
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return new TradingRegistrationModel(response['data']);
        });
    }

    public getRegistrationList() : Observable<ResponseModel> {
        let url:string = TradingService.URL + TradingService.PATH;
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (registration) => new TradingRegistrationModel(registration));
        });
    }
}
