import { LineModel } from '~/models/lineModel';
import { MarketValueItemModel } from '~/models/marketValueItemModel';
import { round as _round, isNil as _isNil } from 'lodash';
import { map as  _map } from 'lodash';
import { BaseModel } from './baseModel';
import { ReadyReckonerModel } from './readyReckonerModel';

export class IndicativeMarketValueModel extends BaseModel {
    public marketValues:ReadyReckonerModel[];
    public indicativeGrsyMarketValue:number;
    public marketGrsyPrice:number;
    public indicativeGrsyEstWeight:number;

    constructor(values?:{}) {
        super(values);

        if(values['marketValues'])
        this.marketValues = _map(values['marketValues'], (item) => new ReadyReckonerModel(item));
    }
}
