export function PropertyMapper<T>(prototype: any, key: string, mapper: (value: any, instance?:any) => T,
                                  getMapper?: (instance?:any) => T) {
    const values = new Map<any, T>();
    Object.defineProperty(prototype, key, {
        set(firstValue: any) {
            Object.defineProperty(this, key, {
                get() {
                    return values.get(this);
                },
                set(value: any) {
                    values.set(this, mapper(value, this));
                },
                enumerable: true
            });
            this[key] = firstValue;
        },
        get() {
            if(getMapper) {
                return getMapper(this);
            }
            return undefined;
        },
        enumerable: true,
        configurable: true,
    });
}