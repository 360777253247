import { BaseModel } from '~/models/baseModel';

export class BreakDownModel extends BaseModel {
    public yield:number;
    public yieldChanged:number;
    public volume:number;
    public volumeChanged:number;
    public stapleStrength:number;
    public stapleStrengthChanged:number;
    public cutPerHead:number;
    public cutPerHeadChagned:number;
    public overtime:number;
    public overtimeChanged:number;
    public micron:number;
    public micronChanged:number;
    public avgWeight:number;
    public avgWeightChanged:number;
    public avgLength:number;
    public avgLengthChanged:number;
    public totalWeight:number;
    public totalWeightChanged:number;
    public production:number;
    public productionChanged:number;
    public woolType:number;
    public bales:number;
    public year:number;
    public mobs:number;
    public byWoolTypes:WoolType[];
    public clipReference:string;
    public woolMajorType:string;
    public woolSubType:string;
}

export class WoolType extends BaseModel {
    public woolMajorType:string;
    public woolSubType:string;
    public bales:number;
}

export class MobBreakDownModel extends BreakDownModel {
    public mobNo:number;
    public mobId:number;
    public lotId:number;
    public ageCode: string;
    public name: string;
    public mobs: number;
}

export class ClipBreakDown extends BreakDownModel {
    public totalClipWeight:number;
    public totalClipWeightChanged:number;
    public vm:number;
}
