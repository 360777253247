import { Component, Input, EventEmitter, Output, HostBinding } from '@angular/core';
import { AbstractThemeableComponent } from '~/components/abstractThemable/abstractThemeable';
import { ComponentThemes } from '~/components/abstractThemable/abstractThemeable';

enum ButtonTypes {
    Button = 'button',
    Submit = 'submit'
}

@Component({
    selector: 'wep-button',
    templateUrl: './button.html',
    host: {'class': 'wep-button'},
    styleUrls: ['./button.scss'],
})

export class ButtonComponent extends AbstractThemeableComponent {

    constructor() {
        super();
    }

    @Input()
    public type:ButtonTypes | string = 'button';

    @HostBinding('class.wep-button--theme--dark')
    public get isDarkTheme() {
        return this.theme === ComponentThemes.DARK.toString();
    }

    @HostBinding('class.wep-button--theme--light')
    public get isLightTheme() {
        return this.theme === ComponentThemes.LIGHT.toString();
    }

    @Input()
    public disabled:boolean;

    @HostBinding('class.wep-button--disabled')
    public get isDisabled() {
        return this.disabled || this.loading;
    }

    @Input()
    @HostBinding('class.wep-button--expanded')
    public expanded:boolean;

    @Input()
    @HostBinding('class.wep-button--style-2')
    public style2:boolean;

    @Input()
    @HostBinding('class.wep-button--secondary')
    public secondary:boolean;

    @Input()
    @HostBinding('class.wep-button--no-border')
    public noBorder:boolean;

    @Input('aria-label')
    public ariaLabel:string = 'Button';

    @Input('title')
    public title:string;

    @Input()
    @HostBinding('class.wep-button--loading')
    public loading:boolean;

    @Input()
    public href:string = null;

    @Output()
    public clicked:EventEmitter<any> = new EventEmitter();

    public onClick() : void {
        this.clicked.emit();
    }

    public isLink() : boolean {
        return !!this.href;
    }
}
