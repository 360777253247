import { ClipModel } from '~/models/clipModel';
import { Moment } from '~/decorators/moment/momentDecorator';
import { MobModel } from '~/models/mobModel';
import { map as _map } from 'lodash';
import * as moment from 'moment';

export class InventoryClipModel extends ClipModel {
    public noBales:number;
    public weight:number;
    public comments:string;
    public mobs:MobModel[];
    public avgMicron:number;
    public avgYield:number;
    public avgBaleWeight:number;
    public avgStapleStrength:number;
    public species:number;
    public specieIds:string;

    @Moment()
    public createdDate:moment.Moment;

    constructor(values: Object = {}) {
        super(values);

        if(values['mobs']) {
            this.mobs = _map(values['mobs'], (mob) => {
                return new MobModel(mob);
            });
        }

        if(values['specieIds']) {
            this.specieIds = '\'' + values['specieIds'] + '\'';
        }
    }
}
