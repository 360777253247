import { Injectable } from '@angular/core';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { HttpClientWrapper } from '~/services/api/httpClientWrapper';
import { BaseWebService } from '~/services/api/web/base/baseWebService';
import { ResponseModel } from '~/models/responseModel';
import { Observable } from 'rxjs';
import { map as _map } from 'lodash';
import { WoolClipModel } from '~/models/woolClipModel';


@Injectable()
export class IntegrationService extends BaseWebService {

    public static readonly PATH: string = '/integration';

    constructor(http: HttpClientWrapper) {
        super(http);
    }

    public createtLinkingCode(brandId: number, regenerate:boolean): Observable<ResponseModel> {
        let url:string = IntegrationService.URL + IntegrationService.PATH + '/generate' ;

        let params = new HttpParams();
        params = params.append('brandId', brandId.toString());
        if(regenerate === true) {
            params = params.append('regenerate', 'true');
        }

        let options:{} = {
            params: params
        };

        return this.http.get(url, options);
    }

    public getLinkingCode(brandId: number): Observable<ResponseModel> {
        let url:string = IntegrationService.URL + IntegrationService.PATH  ;

        let params = new HttpParams();
        params = params.append('brandId', brandId.toString());

        let options:{} = {
            params: params
        };

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return new WoolClipModel(response['data']);
        });
    }

}
