import { Injectable } from '@angular/core';
import { HttpResponse, HttpParams, HttpEvent, HttpRequest, HttpClient } from '@angular/common/http';
import { ReadyReckonerAbstractService } from '~/services/api/web/readyReckoner/readyReckonerAbstract';
import { HttpClientWrapper } from '~/services/api/httpClientWrapper';
import { BaseWebService } from '~/services/api/web/base/baseWebService';
import { ResponseModel } from '~/models/responseModel';
import { Observable } from 'rxjs';
import { BrokerFeesModel } from '~/models/brokerFeesModel';
import { EntityModel } from '~/models/entityModel';
import { WhatIfModel } from '~/models/whatIfModel';
import { ForwardMarketModel } from '~/models/forwardMarketModel';
import { map as _map } from 'lodash';
import { ReadyReckonerModel } from '~/models/readyReckonerModel';
import { TestingFeeInputModel } from '~/models/testingFeeInputModel';
import { IndicativeMarketValueModel } from '~/models/indicativeMarketValueModel';

export interface WhatIfQuery {
    micron:number;
    stapleStrength?:number;
    balesPerLine?:number;
    vmb?:number;
    pob?:number;
}

@Injectable()
export class ReadyReckonerService extends BaseWebService implements ReadyReckonerAbstractService {

    public static readonly PATH:string = '/readyreckoner';
    private httpClientLegacy:HttpClient;

    constructor(http: HttpClientWrapper, httpClientLegacy:HttpClient) {
        super(http);
        this.httpClientLegacy = httpClientLegacy;
    }

    public getSellingCost(entity:EntityModel) : Observable<ResponseModel> {
        let url:string = ReadyReckonerService.URL + ReadyReckonerService.PATH + '/sellingcost';
        let params = new HttpParams();
        params = params.append('entityId', entity.entityId.toString());

        let options = {
            params : params
        };

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return new BrokerFeesModel(response['data']);
        });
    }

    public getTestingFee(entity:EntityModel, testingFeeInputs:TestingFeeInputModel[]) : Observable<ResponseModel> {
        let url:string = ReadyReckonerService.URL + ReadyReckonerService.PATH + '/entities/' + entity.entityId.toString() + '/testingfee';
        let options:{} = {};
        let body = testingFeeInputs;

        return this.http.post(url, body, options);
    }

    public getLevyPercentage() : Observable<ResponseModel> {
        let url:string = ReadyReckonerService.URL + ReadyReckonerService.PATH + '/levy';
        let options = { };

        return this.http.get(url, options);
    }

    public getMarketValue(marketValues:ReadyReckonerModel[]) : Observable<ResponseModel> {
        let url:string = ReadyReckonerService.URL + ReadyReckonerService.PATH + '/marketvalue';
        let options:{} = {};
        let body = marketValues;

        return this.http.post(url, body, options, (response:HttpResponse<any>) => {
            return  new IndicativeMarketValueModel(response['data']);
        });
    }

    public saveAndGetMarketValue(marketValues:ReadyReckonerModel[]) : Observable<ResponseModel> {
        let url:string = ReadyReckonerService.URL + ReadyReckonerService.PATH + '/savemarketvalue';
        let options:{} = {};
        let body = marketValues;

        return this.http.post(url, body, options, (response:HttpResponse<any>) => {
            return  new IndicativeMarketValueModel(response['data']);
        });
    }

    public getEstimatedMarketValue(lot:ReadyReckonerModel[]) : Observable<ResponseModel> {
        let url:string = ReadyReckonerService.URL + ReadyReckonerService.PATH + '/estimatedvalue';
        let options:{} = {};
        let body = lot;

        return this.http.post(url, body, options, (response:HttpResponse<any>) => {
            return new ReadyReckonerModel(response['data']);
        });
    }

    public getForwardMarketValue(micron:number) : Observable<ResponseModel> {
        let url:string = ReadyReckonerService.URL + ReadyReckonerService.PATH + '/forwardmarketvalue';
        let params = new HttpParams();
        params = params.append('micron', micron.toString());

        let options = {
            params : params
        };

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return new ForwardMarketModel(response['data']);
        });
    }

    public createFileUploader() : Function {
        return (file:File) => {
            return this.uploadFile(file);
        };
    }

    public uploadFile(file:File) : Observable<HttpEvent<any>> {

        let url = ReadyReckonerService.URL + ReadyReckonerService.PATH;
        url += '/importlots';

        let formData = new FormData();
        formData.append('filePayload', file);

        let params = new HttpParams();

        const options = {
            params: params,
            reportProgress: true,
        };

        const req = new HttpRequest('POST', url, formData, options);
        return this.httpClientLegacy.request(req);
    }

    public getWhatIf(params:WhatIfQuery) : Observable<ResponseModel> {
        let url:string = ReadyReckonerService.URL + ReadyReckonerService.PATH + '/whatif';
        let body:{} = params;
        let options:{} = {};

        return this.http.post(url, body, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (data) => new WhatIfModel(data));
        });
    }
}
