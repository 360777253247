import { Injectable } from '@angular/core';
import { HttpClientWrapper } from '~/services/api/httpClientWrapper';
import { BaseService } from '~/services/api/base/baseService';

@Injectable()
export class BaseEpiService extends BaseService {

    public static readonly URL:string = process.env['API_EPI_URL'];
    public static readonly ContentSearchAPIURL:string = process.env['API_WOOL_CONTENT_API'];

    constructor(http:HttpClientWrapper) {
        super(http);
    }
}
