import { BaseModel } from '~/models/baseModel';
import { PriceBoxModel } from '~/models/priceBoxModel';
import { forEach as _forEach } from 'lodash';

export interface PriceSettings {
    label:string;
    color:string;
}

export class PricesModel extends BaseModel {
    public form1Price:number;
    public form1PriceChange:number;
    public form2Price:number;
    public form2PriceChange:number;
    public form3Price:number;
    public form3PriceChange:number;

    public getBoxes(settings:PriceSettings[]) : any[] {
        let items = [];

        _forEach(settings, (setting:PriceSettings, key:number) => {
            let propIndex = key + 1;
            let price = new PriceBoxModel(setting);

            price.price = this['form' + propIndex + 'Price'];
            price.variation = this['form' + propIndex + 'PriceChange'];

            items.push(price);
        });

        return items;
    }
}
