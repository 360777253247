import { Component, AfterViewChecked } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { OfferStatuses } from '~/models/offerModel';

@Component({
    selector: 'wep-ag-grid-cell-renderer-market-auction-status',
    templateUrl: './status.html',
    host: { 'class' : 'wep-ag-grid-cell-renderer wep-ag-grid-cell-renderer-market wep-ag-grid-cell-renderer-market-auction-status' },
    styleUrls: ['./status.scss']
})

export class AgGridCellRendererMarketAuctionStatusComponent implements ICellRendererAngularComp, AfterViewChecked {
    public params: any;

    public agInit(params: any) : void {
        this.params = params;
    }

    public refresh() : boolean {
        return false;
    }

    public getStatus(value:string) : string {
        if(OfferStatuses[value] === OfferStatuses.Open) {
            return 'status--gold';
        }
        if(OfferStatuses[value] === OfferStatuses.NearReserve) {
            return 'status--orange';
        }
        if(OfferStatuses[value] === OfferStatuses.OnMarket) {
            return 'status--green';
        }
        if(OfferStatuses[value] === OfferStatuses.Sold || value === 'OffMarket' ) {
            return 'status--blue';
        }
        if(OfferStatuses[value] === OfferStatuses.ReAuction || value === 'ReAuction' ) {
            return 'status--orange';
        }
        if(OfferStatuses[value] === OfferStatuses.Unsold || value === 'PassedIn') {
            return 'status--grey';
        }

    }

    public isDisplayableValue(value:string) : boolean {
        return(OfferStatuses[value] === OfferStatuses.Open ||
               OfferStatuses[value] === OfferStatuses.NearReserve ||
               OfferStatuses[value] === OfferStatuses.OnMarket ||
               OfferStatuses[value] === OfferStatuses.Unsold ||
               OfferStatuses[value] === OfferStatuses.Sold ||
               OfferStatuses[value] === OfferStatuses.ReAuction ||
               value === 'OffMarket' ||
               value === 'PassedIn' ||
               value === 'ReAuction'
               );
    }

    public ngAfterViewChecked() : void {
        if(this.params && this.params.eGridCell && this.params.eGridCell.style) {
            this.params.eGridCell.style.minHeight = '56px';
        }
    }
}
