import { Directive, Input } from '@angular/core';

export enum ComponentThemes {
    LIGHT = 'light',
    DARK = 'dark',
    NONE = 'none'
}
@Directive()
export abstract class AbstractThemeableComponent {
    @Input()
    public theme:ComponentThemes | string = ComponentThemes.LIGHT.toString();

    public abstract get isLightTheme() : boolean;
    public abstract get isDarkTheme() : boolean;
}