import { BaseModel } from '~/models/baseModel';

export class QualitySchemeModel extends BaseModel {
    public qualitySchemaCode:string;
    public qualitySchemas:string;

    public reference:string;


    public hasValues() : boolean {
        let hasValues = (typeof this.qualitySchemaCode !== 'undefined' && this.qualitySchemaCode.length > 0);

        return hasValues;
    }

    get referenceLabel() : string {
        let ref = (typeof this.reference !== 'undefined' && this.reference.length > 0) ? this.reference : '-';

        return ref;
    }
}

