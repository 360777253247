import { NgModule } from '@angular/core';
import { LimitToPipe } from '~/pipes/limitToPipe/limitToPipe';
import { SpaceInCapitalLettersPipe } from '~/pipes/spaceInCapitalLettersPipe/spaceInCapitalLettersPipe';
import { IfEmptyPipe } from '~/pipes/ifEmptyPipe/ifEmptyPipe';
import { BooleanDisplayPipe } from '~/pipes/booleanDisplayPipe/booleanDisplayPipe';
import { SafeHTMLPipe } from '~/pipes/safeHTMLPipe/safeHTMLPipe';
import { HttpPrefixPipe } from '~/pipes/httpPrefixPipe/httpPrefixPipe';
import { NumberPipe } from '~/pipes/numberPipe/numberPipe';
import { CurrencyPipe } from '~/pipes/currencyPipe/currencyPipe';

@NgModule({
    declarations: [
        LimitToPipe,
        SpaceInCapitalLettersPipe,
        IfEmptyPipe,
        BooleanDisplayPipe,
        SafeHTMLPipe,
        HttpPrefixPipe,
        NumberPipe,
        CurrencyPipe
    ],
    exports: [
        LimitToPipe,
        SpaceInCapitalLettersPipe,
        IfEmptyPipe,
        BooleanDisplayPipe,
        SafeHTMLPipe,
        HttpPrefixPipe,
        NumberPipe,
        CurrencyPipe
    ]
})

export class AppPipesModule {
}
