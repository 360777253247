<wep-select *ngIf="options && options.length > 1"
            class="wep-ag-grid-cell-renderer-market-auction-limit-select__select"
            [theme]="'light'"
            [ngModel]="params.value"
            (changed)="onChanged($event)">
    <wep-option *ngFor="let option of options"
                [value]="option"
                [displayText]="getDisplayText(option)">
    </wep-option>
</wep-select>
<span class="wep-ag-grid-cell-renderer-market-auction-limit-select__text"
      *ngIf="!options || options.length === 0">
    -
</span>
 <span class="wep-ag-grid-cell-renderer-market-auction-limit-select__text"
    *ngIf="options && options.length === 1">
        {{getDisplayText(options[0])}}
</span>
