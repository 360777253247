import { BaseModel } from '~/models/baseModel';
import { SavedSearchModel } from '~/models/savedSearchModel';

import { Moment } from '~/decorators/moment/momentDecorator';
import * as moment from 'moment';

export class SearchStateModel extends BaseModel {
    public filter:SavedSearchModel;
    public highChange:number;
    public lowChange:number;
    public lastChange:number;
    public avgChange:number;
    public high:number;
    public low:number;
    public last:number;
    public bales:number;
    public offers:number;
    public bids:number;
    public query:string;
    public name:string;
    public sales:SoldLot[];
    public errorMessage:string;
    public avgMic:number;
    public avgVm:number;
    public avgLen:number;
    public avgStr:number;
    public clnKgs:number;
}

export class SoldLot extends BaseModel {
    @Moment()
    public date:moment.Moment;

    public cleanPrice:number;
    public price:number;
    public id:number;
    public saleType:string;
}
