import { BaseModel } from '~/models/baseModel';
import { EntityModel } from '~/models/entityModel';
import { Moment } from '~/decorators/moment/momentDecorator';

export enum GroupSubscriberRequestStatuses {
    Awaiting = 'Awaiting',
    Approved = 'Approved',
    Rejected = 'Rejected',
    Cancelled = 'Cancelled'
}

export class GroupSubscriberModel extends BaseModel {
    public groupEntityId:number;
    public groupName:string;
    public subscriberEntityId:number;
    public requestStatus:GroupSubscriberRequestStatuses;

    @Moment()
    public modifiedOn:number;
    @Moment()
    public createdOn:number;
    public isAdmin:boolean;
    public subscriber:EntityModel;

    constructor(values?:{}) {
        super(values);
        if(values['subscriber']) {
            this.subscriber = new EntityModel(values['subscriber']);
        }
    }
}