import { Component, Input, HostBinding, OnChanges, SimpleChange, EventEmitter, Output } from '@angular/core';
import { isNil as _isNil } from 'lodash';

@Component({
    selector: 'wep-avatar',
    templateUrl: './avatar.html',
    host: {'class': 'wep-avatar'},
    styleUrls: ['./avatar.scss'],
})

export class AvatarComponent implements OnChanges {

    @Input()
    public src:string;

    @Input()
    public alt:string;

    @Input()
    public fallbackText:string = '';

    @Input()
    public maxTicks = 0;

    @Input()
    @HostBinding('style.width.px')
    @HostBinding('style.height.px')
    @HostBinding('style.minWidth.px')
    @HostBinding('style.minWidth.px')
    @HostBinding('style.lineHeight.px')
    public dimension:number = 35;

    @Input()
    public fontSize:number = null;

    @Input()
    public objectFitContain:boolean;

    public displayFallback:boolean = false; //Used as a flag and toggled in wep-image (error) emitter.

    @Input()
    public shouldDisplayFallback:boolean = true;

    @Output()
    public error:EventEmitter<boolean> = new EventEmitter<boolean>();

    public getStyle() : {} {
        let fontSize:number = this.fontSize ? this.fontSize : Math.ceil((this.dimension / 2.2));
        return {
            fontSize: fontSize  + 'px',
            lineHeight: this.dimension + 'px',
            height: this.dimension + 'px'
        };
    }

    public ngOnChanges(changes: {[propertyName: string]: SimpleChange}) : void {
        if (changes['src']) {
            //If src has changed, then re-create the wep-image component (by toggling the ngIf attribute)
            if(!_isNil(this.src)) {
                this.displayFallback = false;
            }
        }
    }

    public onError() : void {
        this.displayFallback = true;
        this.error.emit(true);
    }
}