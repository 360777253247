import { Injectable } from '@angular/core';
import { SocialAbstractService } from '~/services/api/web/social/socialAbstractService';
import { HttpResponse } from '@angular/common/http';
import { HttpClientWrapper } from '~/services/api/httpClientWrapper';
import { BaseWebService } from '~/services/api/web/base/baseWebService';
import { ResponseModel } from '~/models/responseModel';
import { UserModel } from '~/models/userModel';
import { MessagePostModel } from '~/models/messagePostModel';
import { Observable } from 'rxjs';
import { MessageModel } from '~/models/messageModel';
import { map as _map } from 'lodash';
import { ConversationNotificationModel } from '~/models/conversationNotificationModel';

@Injectable()
export class SocialService extends BaseWebService implements SocialAbstractService {
    public static readonly PATH:string = '/social';

    constructor(http: HttpClientWrapper) {
        super(http);
    }

    public getMessages(receiverUser:UserModel) : Observable<ResponseModel> {
        let url:string = SocialService.URL + SocialService.PATH + '/p2pmessages/' + receiverUser.contactId.toString();
        let options = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (message) => new MessageModel(message));
        });
    }

    public getConversationNotifications() : Observable<ResponseModel> {
        let url:string = SocialService.URL + SocialService.PATH + '/p2pmessages/my';
        let options = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (conversationNotification) => new ConversationNotificationModel(conversationNotification));
        });
    }

    public postMessage(messagePost:MessagePostModel) : Observable<ResponseModel>  {
        let url:string = SocialService.URL + SocialService.PATH + '/p2pmessages';
        let body:any = messagePost;
        let options:{} = {};

        return this.http.post(url, body, options);
    }

    public postReadLastMessage(user:UserModel, lastMessage:MessageModel) : Observable<ResponseModel> {
        let url:string = SocialService.URL + SocialService.PATH + '/p2pmessages/read/'
            + user.contactId.toString() + '/' + lastMessage.id.toString();
        let body:any = null;
        let options:{} = {};

        return this.http.post(url, body, options);
    }
}
