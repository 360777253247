import { Component, Input, Output, EventEmitter, HostBinding } from '@angular/core';

@Component({
    selector: 'wep-image',
    templateUrl: './image.html',
    host: { 'class': 'wep-image' },
    styleUrls: ['./image.scss'],
})

export class ImageComponent {
    @Input()
    public src: string;

    @Input()
    public tnsrc: string;

    @Input()
    public fallbackSrc: string;

    @Input()
    public alt: string;

    @Input()
    public css: string;

    @Input()
    @HostBinding('class.wep-image--object-fit--contain')
    public objectFitContain: boolean;

    @Output()
    public load: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    public error: EventEmitter<boolean> = new EventEmitter<boolean>();

    private ticks: number = 0;

    @Input()
    public maxTicks: number = 50;

    constructor() {
    }

    public loaded(): void {
        this.load.emit();
    }

    public getSrc(): string {
        return this.tnsrc ? this.tnsrc : this.src;
    }

    /**
     * Attempt to reload the invalid images (or use the fallback src)
     * @param event
     */
    public reloadSrc(event): void {
        if (this.fallbackSrc || this.tnsrc) {
            this.loadFallbackSrc(event);
        }
        else {
            //Attempt to reload image every 100th of a second multiply by ticks
            //So in total approx. 5 seconds
            if (this.ticks < this.maxTicks) {
                setTimeout(() => {
                    event.target.onerror = null;
                    this.reloadSrc(event);
                    this.ticks++;
                }, 100);
            }
            else {
                this.error.emit();
            }
        }
    }

    /**
     * Attempt to load the fallbackSrc, otherwise emit an error
     * @param event
     */
    public loadFallbackSrc(event): void {
        if (this.tnsrc && event.target.src !== this.src) {
            event.target.src = this.src;
            event.target.tnsrc = this.src;
        }
        else if (this.fallbackSrc)
            event.target.src = this.fallbackSrc;
        event.target.onload = this.load.emit();
        event.target.onerror = this.error.emit();
    }
}
