import { BaseModel } from '~/models/baseModel';
import { Moment } from '~/decorators/moment/momentDecorator';
import * as moment from 'moment';
import { OfferLotModel } from './offerLotModel';
import { OfferFilterItemInterface } from '~/pages/admin/market/shared/components/offerMenuItems/offerMenuItems';

export enum TenderOfferStatuses {
    Open = 'Open',
    Sold = 'Sold',
    Unsold = 'Unsold',
    SoldWithdraw = 'SoldWithdraw'
};

export class TenderOfferModel extends BaseModel {
    public id:number;

    public status:TenderOfferStatuses;
    public lot:OfferLotModel;
    public myBid:number;
    public currentBid:number;
    public highestBid2:number;
    public highestBid3:number;
    public offerFilterItem:OfferFilterItemInterface;
    public brand:string; //top line in tender grid
    public canBid:boolean;
    public soldPrice:number;
    public buyer:string;
    public readonly isTender:boolean = true;

    //Tender Book
    public bidder:string;
    public highestBidder2:string;
    public highestBidder3:string;
    public currentMaxBid:number;
    public underBidMaxBid:number;
    public heldById:number;
    public heldBy:string;

    public offerId:number;//temporary to make it working existing sidebar

    constructor(values: Object = {}) {
        super(values);
        this.offerId = this.id;
    }

    public isAuctionRunning() : boolean {
        return  this.status === TenderOfferStatuses.Open || TenderOfferStatuses[this.status.toString()] === TenderOfferStatuses.Open;
    }

    public isAuction() : boolean {
        return true;
    }

    public isOffer() : boolean {
        return false;
    }

    public isOfferOpen() : boolean {
        return this.isAuctionRunning();
    }

    public isOfferUnsold() : boolean {
        return this.status === TenderOfferStatuses.Unsold || TenderOfferStatuses[this.status.toString()] === TenderOfferStatuses.Unsold;
    }

    public isOfferReauction() : boolean {
        return false
    }

    public isOfferSold() : boolean {
        return this.status === TenderOfferStatuses.Sold || TenderOfferStatuses[this.status.toString()] === TenderOfferStatuses.Sold;
    }

    public isOfferClosed() : boolean {
        return this.isOfferSold() || this.isOfferUnsold();
    }
}
