<div class="heading">
    <h3 class="heading__title">
        WoolQ eSpeci to be replaced by WoolClip    
    </h3>
    <h4 class="heading__title">
        How to link your WoolQ and WoolClip accounts:
    </h4>
</div>
<p>
    WoolClip<sup>TM</sup> is an online internet and mobile app that
    allows the user to create wool Specifications, Consignments and
    National Wool Declarations (NWDs). It uses a simple, intuitive
    workflow designed to reduce workload and errors.
</p>
<p>
    AWI and AWEX have collaborated to integrate WoolClip and WoolQ so
    that WoolClip captures the on-farm data through a digital
    specification and then WoolQ can use that data to help grower
    visualise their data in one spot and make informed business
    decisions.
</p>
<p>
    Moving forward, the WoolClip speci will be the sole digital wool specification
    and we encourage you to complete your upcoming speci’s through WoolClip.
    The WoolQ version of the digital wool specification will be phased out soon.
    Fortunately, you will still be able to get your test and sale information
    back into WoolQ after completing your WoolClip wool specification.
</p>
<p>
    To link your account to WoolClip, you must have a farm brand. You
    must also be registered on WoolClip.
</p>
<ol *ngIf="generic === false">
    <li>Press 'Get Code' to the right of the screen</li>
    <li>
        Copy the 6-digit code that is generated. Code will be live for 2
        hours.
    </li>
    <li>Go to WoolClip and enter your code into the platform.</li>
    <li>Click Link Accounts</li>
    <li>Done</li>
</ol>
<ol *ngIf="generic === true">
    <li>Go to your business profile</li>
    <li>Select the brand/users tab</li>
    <li>Select edit on the brand you wish to link to WoolClip</li>
    <li>Follow on page instructions.</li>
</ol>