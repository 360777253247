import { BaseModel } from '~/models/baseModel';

export class PicModel extends BaseModel {
    public pic:string;
    public street:string;
    public street2:string;
    public city:string;
    public postcode:string;
    public state:string;
    public country:string;

    public hasValues() : boolean {
        let hasValues = this.pic?.length > 0 ||
        this.street?.length > 0 ||
        this.street2?.length > 0 ||
        this.city?.length > 0 ||
        this.postcode?.length > 0 ||
        this.state?.length > 0 ||
        this.country?.length > 0;

        return hasValues;
    }
}
