import { Injectable } from '@angular/core';
import { isNil as _isNil } from 'lodash';

@Injectable()
export class SessionService {

    public setNumber(key:string, value:number) : void {
        if(!_isNil(value)) {
            sessionStorage.setItem(key, value.toString());
        }
        else {
            sessionStorage.setItem(key, '');
        }
    }

    public getNumber(key:string) : number {
        let value = sessionStorage.getItem(key);
        if(!_isNil(value) && value !== '') {
            return +value;
        }
        return null;
    }

    /**
     * Stores a string boolean
     * @param key
     * @param value
     */
    public setBoolean(key:string, value:boolean) : void {
        let storedValue = value ? 1 : 0;
        sessionStorage.setItem(key, storedValue.toString());
    }

    /**
     * Gets a boolean formatted from the string
     * @param key
     * @returns {boolean}
     */
    public getBoolean(key:string) : boolean {
        let value = +sessionStorage.getItem(key) === 1 ? true : false;
        return value;
    }

    /**
     * Removes the localStorage value
     * @param key
     */
    public remove(key:string) : void {
        sessionStorage.removeItem(key);
    }

    /**
     * Sets the object as a JSON string
     * @param key
     * @param object
     */
    public setObject(key:string, object:{}) : void {
        let value = JSON.stringify(object);
        sessionStorage.setItem(key, value);
    }

    /**
     * Gets the JSON string from the sessionStorage
     * @returns {any}
     */
    public getObject(key:string) : {} {
        if(sessionStorage.getItem(key)) {
            let value = JSON.parse(sessionStorage.getItem(key));
            return value;
        }
        return null;
    }
}
