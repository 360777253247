import { Component } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
    selector: 'wep-carousel-item',
    templateUrl: './carouselItem.html',
    host: {'class': 'wep-carouselItem'},
    styleUrls: ['./carouselItem.scss'],
    animations: [
        trigger('animate', [
            state('active', style({
                position: 'relative',
                transform: 'translate3d(0, 0, 0)',
            })),
            state('inactiveLeft', style({
                position: 'absolute',
                transform: 'translate3d(-100%, 0, 0)'
            })),
            state('inactiveRight', style({
                position: 'absolute',
                transform: 'translate3d(100%, 0, 0)'
            })),
            transition('* => active', animate('500ms ease-in-out')),
            transition('active => *', animate('500ms ease-in-out')),
        ])
    ]
})
export class CarouselItemComponent {
    public active = false;
    public index:number;
    public animationState:string = '';
}
