<div class="wep-carousel__content">
    <ng-content></ng-content>
</div>
<ul class="wep-carousel__dots" *ngIf="carouselItems?.length > 1">
    <li *ngFor="let carouselItem of carouselItems"
        (click)="selectCarouselItem(carouselItem)"
        [ngClass]="{'wep-carousel__dot--active' : carouselItem.active }"
        class="wep-carousel__dot">
    </li>
</ul>
