<div *ngIf="display" [hidden]="! visible">
    <div class="wep-hero-carousel__container">
        <wep-carousel class="wep-hero-carousel__carousel">
            <wep-carousel-item *ngFor="let item of items; trackBy: trackByItemId">
                <h1 class="wep-hero-carousel__header flex-container">
                    <span class="heading">{{item.heading}}</span>
                    <span>
                        <button type="button"
                                class="button button--style-2 button--secondary wep-hero-carousel__header__button"
                                (click)="hideCarousel(item)">
                            <span>Hide</span>
                            <i class="fas fa-times wep-hero-carousel__header__button__icon"></i>
                        </button>
                    </span>
                </h1>
                <div class="wep-hero-carousel__text">{{item.summary}}</div>
            </wep-carousel-item>
        </wep-carousel>
    </div>
</div>
