import { BaseModel } from '~/models/baseModel';

export class GalleryImageModel extends BaseModel {
    public entityImageId:number;
    public uploadedName:string;
    public fileUrl:string;
    public orderNumber:number;

    set filename(value) {
        this.uploadedName = value;
    }

    get filename() {
        return this.uploadedName;
    }
}

