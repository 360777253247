import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { AuctionCatalogModelStatuses } from '~/models/auctionCatalogModel';

@Component({
    selector: 'wep-ag-grid-cell-renderer-market-admin-auction-status',
    templateUrl: './status.html',
    host: { 'class' : 'wep-ag-grid-cell-renderer wep-ag-grid-cell-renderer-market wep-ag-grid-cell-renderer-market-admin-auction-status' },
    styleUrls: ['./status.scss']
})

export class AgGridCellRendererMarketAdminAuctionStatusComponent implements ICellRendererAngularComp {
    public params: any;

    public agInit(params: any) : void {
        this.params = params;
    }

    public refresh() : boolean {
        return false;
    }

    public getCircleClass(value:AuctionCatalogModelStatuses) : string {
        if(value === AuctionCatalogModelStatuses.Upcoming) {
            return 'circle--upcoming';
        }
        else if(value === AuctionCatalogModelStatuses.Live) {
            return 'circle--live';
        }
        else if(value === AuctionCatalogModelStatuses.Closed) {
            return 'circle--closed';
        }
    }
}