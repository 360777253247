import { Injectable } from '@angular/core';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { LineAbstractService } from '~/services/api/web/line/lineAbstractService';
import { HttpClientWrapper } from '~/services/api/httpClientWrapper';
import { ResponseModel } from '~/models/responseModel';
import { LineModel } from '~/models/lineModel';
import { ClipModel } from '~/models/clipModel';
import { Observable } from 'rxjs';
import { BaleModel } from '~/models/baleModel';
import { BaseWebService } from '~/services/api/web/base/baseWebService';
import { map as _map } from 'lodash';
import { forEach as _forEach } from 'lodash';
import { LineReorderModel } from 'src/app/models/lineReorderModel';

@Injectable()
export class LineService extends BaseWebService implements LineAbstractService {

    public static readonly PATH:string = '/lines';

    constructor(http: HttpClientWrapper) {
        super(http);
    }

    public getLines() : Observable<ResponseModel> {
        let url:string = LineService.URL + LineService.PATH;
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (line) => new LineModel(line));
        });
    }

    public getLineById(lineId:number) : Observable<ResponseModel> {
        let url:string = LineService.URL + LineService.PATH + '/' + lineId.toString();
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return new LineModel(response['data']);
        });
    }

    public getLinesByClip(clip:ClipModel) : Observable<ResponseModel> {
        let url:string = LineService.URL + LineService.PATH;

        let params = new HttpParams();
        params = params.append('clipId', clip.clipId.toString());

        let options:{} = {
            params : params
        };

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (line) => new LineModel(line));
        });
    }

    public updateLineOrder(reorder:LineReorderModel) : Observable<ResponseModel> {
        let url:string = LineService.URL + LineService.PATH + '/reorder';
        let body:any = reorder;
        let options:{} = {};

        return this.http.post(url, body, options);
    }

    public postLine(line:LineModel) : Observable<ResponseModel> {
        let url:string = LineService.URL + LineService.PATH;
        let body:any = line;
        let options:{} = {};

        return this.http.post(url, body, options, (response:HttpResponse<any>) => {
            return new LineModel(response['data']);
        });
    }

    public postBalesToLine(line:LineModel, bales:BaleModel[]) : Observable<ResponseModel> {
        //List of BaleIds
        let baleIds:number[] = [];

        //Set the BaleIds to be set
        _forEach(bales, (baleModel:BaleModel) => {
            baleIds.push(baleModel.baleId);
        });

        let url:string = LineService.URL + LineService.PATH + '/' + line.lineId + '/bales';
        let body:any = baleIds;
        let options:{} = {};

        return this.http.post(url, body, options);
    }

    public deleteLine(line:LineModel) : Observable<ResponseModel> {
        let url:string = LineService.URL + LineService.PATH + '/' + line.lineId.toString() + '/delete';
        let body:any = null;
        let options:{} = {};

        return this.http.post(url, body, options);
    }
}
