import { BaseModel } from '~/models/baseModel';
import { Moment } from '~/decorators/moment/momentDecorator';
import * as moment from 'moment';

export enum AuctionCatalogModelStatuses {
    Upcoming = 'Upcoming',
    Live = 'Live',
    Closed = 'Closed'
};

export class AuctionCatalogModel extends BaseModel {
    public id:number;
    public name:string;

    @Moment()
    public date:moment.Moment | string;
    public open:string;
    public close:string;
    public openFormatted:string;
    public closeFormatted:string;
    public endTimeFormatted:string;
    public startTimeFormatted:string;
    public status:AuctionCatalogModelStatuses;

    public season:string;
    public saleNo:string;
    public brokerEntityId:number;
    public brokerEntity:string;
    public businessLogo:string;
    public numOfLots:string;
    public numOfBales:string;
    public blockBreakInSecs:number;
    public blockDurationInSecs:number;
    public blockSize:number;
}
