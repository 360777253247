import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { HttpClientWrapper } from '~/services/api/httpClientWrapper';
import { BaseWebService } from '~/services/api/web/base/baseWebService';
import { PaymentAbstractService } from '~/services/api/web/payment/paymentAbstractService';

// Models
import { NabTokenModel } from '~/models/nabTokenModel';
import { NabTokenPostModel } from '~/models/nabTokenPostModel';
import { ResponseModel, ResponseModelCode, ResponseModelErrorMessages } from '~/models/responseModel';

// RxJS
import { Observable, Subject } from 'rxjs';

import { forOwn as _forOwn } from 'lodash';

@Injectable()
export class PaymentService extends BaseWebService implements PaymentAbstractService {
    public static readonly PATH:string = '/payment';
    public static readonly NAB_TOKEN_PATH = process.env['API_NAB_URL'];

    constructor(http: HttpClientWrapper) {
        super(http);
    }

    public getTokenRequest() : Observable<ResponseModel> {
        let url:string = PaymentService.URL + PaymentService.PATH + '/tokenrequest';
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return new NabTokenModel(response['data']);
        });
    }

    public postToken(nabToken:NabTokenPostModel) : Observable<ResponseModel> {
        let subject = new Subject<ResponseModel>();

        let formData = new FormData();
        _forOwn(nabToken, (value: any, key:string) => {
            formData.append(key, value.toString());
        });

        let request = new XMLHttpRequest();
        request.open('POST', PaymentService.NAB_TOKEN_PATH);
        request.send(formData);

        request.onreadystatechange = function () {
            if (this.readyState === XMLHttpRequest.DONE) {
                if (this.status === 200) {
                    subject.next(new ResponseModel({
                        code: ResponseModelCode.Ok
                    }));
                }
            }
        };
        request.onerror = function() {
            subject.next(new ResponseModel({
                code: ResponseModelCode.Error,
                errorMessage: ResponseModelErrorMessages.Unknown
            }));
        };

        return subject.asObservable();
    }

    public bindRefIdToEntity(entityId:number, refid:string) : Observable<ResponseModel> {
        let url:string = PaymentService.URL + PaymentService.PATH + '/entities/' + entityId.toString() + '/bind/' + refid.toString();
        let body:any = null;
        let options:{} = {};

        return this.http.post(url, body, options);
    }
}