import { BaseModel } from '~/models/baseModel';
import { OfferModel } from '~/models/offerModel';
import { map as _map } from 'lodash';

export class MyBidModel extends BaseModel {
    public highestBids:OfferModel[];
    public outBids:OfferModel[];

    constructor(values:Object = {}) {
        super(values);

        if(values['highestBids']) {
            this.highestBids = _map(values['highestBids'], (offer) => new OfferModel(offer));
        }

        if(values['outBids']) {
            this.outBids = _map(values['outBids'], (offer) => new OfferModel(offer));
        }
    }
}