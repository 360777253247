import { Component, Input, Output, OnChanges, SimpleChange, EventEmitter, ContentChildren, QueryList, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { CarouselItemComponent } from './carouselItem/carouselItem';

@Component({
    selector: 'wep-carousel',
    templateUrl: './carousel.html',
    host: {'class': 'wep-carousel'},
    styleUrls: ['./carousel.scss'],
    encapsulation: ViewEncapsulation.None
})

export class CarouselComponent implements AfterViewInit, OnChanges {
    @ContentChildren(CarouselItemComponent)
    public carouselItems:QueryList<CarouselItemComponent>;

    @Input()
    public carouselIndex:number;

    @Output()
    public carouselIndexChange = new EventEmitter<number>();

    private activeCarouselItem:CarouselItemComponent;

    constructor() {
    }

    public ngAfterViewInit() : void {
        setTimeout(() => {
            this.selectInitialCarouselItem();
        }, 100);
    }

    private selectInitialCarouselItem() : void {

        //Is the carouselItem-index attribute set in wep-carousel
        if(this.carouselIndex) {
            this.activeCarouselItem = this.carouselItems.filter((__, index) => {
                return +this.carouselIndex === index;
            })[0];
        }
        else {
            //Is the active attribute set in wep-carousel-item
            this.activeCarouselItem = this.carouselItems.filter((carouselItem) => {
                return carouselItem.active;
            })[0];
        }

        //Is there no active carouselItem?
        if(!this.activeCarouselItem) {
            this.activeCarouselItem = this.carouselItems.first;
        }

        this.activeCarouselItem.active = true;
        this.activeCarouselItem.animationState = 'active';

        this.setCarouselItemsIndex();
        this.carouselIndex = this.activeCarouselItem.index;
        this.carouselIndexChange.emit(this.carouselIndex);

        this.toggleInactiveCarouselItems();
    }

    private setCarouselItemsIndex() {
        this.carouselItems.forEach((item, index) => {
            item.index = index;
        });
    }

    private toggleInactiveCarouselItems() : void {

        this.carouselItems.forEach((carouselItem) => {

            if(this.activeCarouselItem.index > carouselItem.index) {
                carouselItem.animationState = 'inactiveLeft';
            }
            else if(this.activeCarouselItem.index < carouselItem.index) {
                carouselItem.animationState = 'inactiveRight';
            }
        });
    }

    public selectCarouselItem(carouselItem:CarouselItemComponent) : void {

        if(carouselItem !== this.activeCarouselItem) {
            carouselItem.animationState = 'active';
            carouselItem.active = true;

            this.activeCarouselItem.active = false;
            this.activeCarouselItem = carouselItem;
            this.carouselIndex = this.activeCarouselItem.index;
            this.carouselIndexChange.emit(this.carouselIndex);

            this.toggleInactiveCarouselItems();
        }
    }

    /**
     * Update the inputs from the speciComponent
     * @param changes
     */
    public ngOnChanges(changes: {[propertyName: string]: SimpleChange}) : void {

        if(changes['carouselIndex']) {
            if(this.carouselItems) {
                this.selectCarouselItem(this.carouselItems.filter((__, index) => {
                    return +this.carouselIndex === index;
                })[0]);
            }
        }
    }
}
