import { BaseModel } from '~/models/baseModel';

export class ContactPermissionModel extends BaseModel {
    public contactId:number;
    public clipID:number;
    public clipView:boolean;
    public clipEdit:boolean;
    public clipAdminister:boolean;
    public contactEmail:string;
    public contactName:string;
}
