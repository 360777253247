import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseModel, ResponseModelCode } from '~/models/responseModel';
import { Client, GeocodeRequest } from "@googlemaps/google-maps-services-js";

export interface MapCoordinates {
    latitude:number;
    longitude:number;
}

export interface MapParams {
    address:string;
    country?:string;
    state?:string;
    suburb?:string;
    postcode?:string;
}

//Refer to : https://developers.google.com/maps/documentation/geocoding/start
interface GeoCodeParams {
    address:string;
    components?: {
        country?:string; //Shortform as in AU, NZ etc.
        administrative_area_level_1?:string; //State
        administrative_area_level_2?:string; //Suburb
        postal_code?:string; //Postcode
    };
}

@Injectable()
export class MapsService {

    public static readonly API_KEY = 'AIzaSyCAY8ced9rA61bVqoZGiaaxn9lY8siujzo';

    private googleMapsClient:Client;

    constructor() {
        this.googleMapsClient = new Client({});
    }

    public getLatitudeAndLogitude(mapParams:MapParams) : Observable<ResponseModel> {

        let geocodeRequest:GeocodeRequest = this.getGeoCodeRequest(mapParams);

        return Observable.create((observer) => {
            this.googleMapsClient.geocode(geocodeRequest)
            .then((response) => {
                let locationResult = response.data.results[0].geometry.location;
                let data:MapCoordinates = {
                    longitude : locationResult.lng,
                    latitude: locationResult.lat
                };
                observer.next(new ResponseModel({
                    code: ResponseModelCode.Ok,
                    data: data
                }));
                observer.complete();
            })
            .catch((_error) => {
                observer.next(new ResponseModel({
                    code: ResponseModelCode.Error,
                    errorMessage: 'Map details not found'
                }));
                observer.complete();
            });
        });
    }

    /**
     * Converts the map params to parameters recognised by the google maps client
     * @param {MapParams} mapParams
     * @returns {GeocodeRequest}
     */
    public getGeoCodeRequest(mapParams:MapParams) : GeocodeRequest {
        let request:GeocodeRequest = {
            params:{
                key: MapsService.API_KEY,
                address: mapParams.address.trim(),
                components: {
                    country: mapParams.country?.trim() ?? 'AU',
                    administrative_area: mapParams.state?.trim() ?? null,
                    locality: mapParams.suburb?.trim() ?? null,
                    postal_code: mapParams.postcode?.trim() ?? null,
                },
            }
        };

        return request;
    }
}
