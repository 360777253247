import { PermissionModel } from '~/models/permissionModel';
import { BaleModel } from '~/models/baleModel';
import { MobGroupModel } from '~/models/mobGroupModel';
import { sortBy as _sortBy, map as _map, join as _join, filter as _filter, includes as _includes } from 'lodash';

export interface NLISIdentificationTag {
    nlisIdentificationColorTag:string;
    nlisIdentificationTextColor:string;
    colorName:string;
}

export class MobModel extends PermissionModel {
    public mobId:number;
    public mobNo:number;
    public name:string;
    public brandID:number;
    public clipID:number;
    public clipReference:string;
    public mobCount:string;
    public ageCode:string;
    public mobGroupId:number;

    public breedCodeList:string;
    public genderCodeList:string;
    public contactWithSheding:boolean;
    public mobCrutched:boolean;
    public crutchedPriorToShearing:boolean;
    public mulesing:string;
    public woolGrowthPeriod:string;
    public averageLength:string;
    public vmCodeList:string;
    public vmQuantity:string;
    public comments:string;
    public woolQuality:string;
    public nlisIdentificationTags:NLISIdentificationTag[];
    public nlisIdentificationTagList:string;
    public baleRange:string;
    public within3Months:boolean;
    public isSubmitted:boolean;

    //Local ID’s
    public localBrandID:string;
    public localClipID:string;

    //Associated Bales
    public baleIDs:number[];
    public baleNums:number[]; //@TODO remove this code in the future as it is legacy code (that is still used by mobile)
    public bales:BaleModel[] = [];

    public mobGroup:MobGroupModel;

    private _ageCodeLabel:string;
    private _genderCodeListDisplay:string;
    private _breadCodeListDisplay:string;

    constructor(values: Object = {}) {
        super(values);

        this.bales = _map(values['bales'], (bale) => {
            return new BaleModel(bale)
        });

        Object.defineProperty(this, 'ageCodeLabel', {
            enumerable: true,
            set: function (_value:string) {
            },
            get: function() {
                return this.getAgeCode();
             }
        });

        Object.defineProperty(this, 'breedCodeListDisplay', {
            enumerable: true,
            set: function (_value:string) {
            },
            get: function() {
                return this.getBreedCode();
             }
        });

        Object.defineProperty(this, 'genderCodeListDisplay', {
            enumerable: true,
            set: function (_value:string) {
            },
            get: function() {
               return this.getGenderCode();
             }
        });
    }

    get genderCodeListDisplay() {
        return this.getGenderCode();
    }

    get breedCodeListDisplay() {
        return this.getBreedCode();
    }

    get ageCodeLabel() {
        return this.getAgeCode();
    }

    public baleRangeForSpecie(lineIds:number[]) {
        if(this.bales && this.bales.length > 0) {
           return _join(_map(_filter(this.bales, (bale:BaleModel) => _includes(lineIds, bale.lineID)), (bale:BaleModel) => bale.baleNo),',');
        }
        return '';
    }

    set genderCodeListDisplay(_value:string) { }
    set breedCodeListDisplay(_value:string) { }
    set ageCodeLabel(_value:string) { }

    public getAgeCode() : string {
        let age = 'Mixed';
        if (typeof this.ageCode === 'undefined' || !this.ageCode || this.ageCode.length === 0) {
            age = '-';
        } else {
            let ages = this.ageCode.split(',');
            age = (ages.length > 1) ? age : this.ageCode;
        }
        return age;
    }

    public getGenderCode() : string {
        if(this.genderCodeList) {
            let genderCodeListArray:string[] = this.genderCodeList.split(',');
            if(genderCodeListArray.length === 1) {
                return genderCodeListArray[0];
            }
            else if(genderCodeListArray.length > 1) {
                return 'Mixed';
            }
        }
        return '';
    }

    public getBreedCode() : string {
        if(this.breedCodeList) {
            let breedCodeListArray:string[] = this.breedCodeList.split(',');
            if(breedCodeListArray.length === 1) {
                return breedCodeListArray[0];
            }
            else if(breedCodeListArray.length > 1) {
                return 'Mixed';
            }
        }
        return '';
    }

    public getSortedBales() : BaleModel[] {
        return _sortBy(this.bales, (bale:BaleModel) => {
            return bale.baleNo;
        });
    }

    public displayAsYesNo(value:string) : string {
        return value ? 'Y' : 'N';
    }

    public getBreedCodePriority() : any  {
        let breedCodeListArray:string[] = this.breedCodeList.split(',');

        if(breedCodeListArray.length > 1) {
            //Underscore goes after the “Z” ASCII character.
            return '_';
        }
        return breedCodeListArray[0];
    }

    /**
     * Returns true (to display an alert icon) if if certain fields are not set
     * @returns {boolean}
     */
    public shouldDisplayAlert() : boolean {
        return !this.ageCode ||
            !this.breedCodeList ||
            !this.genderCodeList ||
            !this.mulesing ||
            !this.woolGrowthPeriod ||
            !this.averageLength ||
            !this.vmCodeList ||
            !this.vmQuantity ||
            !this.woolQuality;
    }
}
