<button class="wep-ag-grid-cell-renderer-checkbox__checkbox"
        (click)="onClick()" *ngIf="!isDisabled()">
    <ng-container *ngIf="selected">
        <svg xml:space="preserve"
             xmlns="http://www.w3.org/2000/svg"
             focusable="false" version="1.1" viewBox="0 0 24 24">
            <path class="wep-ag-grid-cell-renderer-checkbox__checkbox__check" d="M4.1,12.7 9,17.6 20.3,6.3" fill="none" stroke="white"></path>
        </svg>
    </ng-container>
</button>
