import { Injectable } from '@angular/core';
import { isNil as _isNil } from 'lodash';

@Injectable()
export class FileService {
    public static readonly IMAGE_URL = process.env['API_WEB_IMAGE_URL'];

    public url(path:string) : string {

        if(_isNil(path)) {
            return '#';
        }

        if(path && path.length > 0 && path[0] !== '/') {
            path = '/' + path;
        }

        return FileService.IMAGE_URL + path;
    }
}
