import { HostBinding, HostListener, AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { trigger, state, style, animate, transition, group, query, animateChild } from '@angular/animations';
import { OffCanvasService } from '~/templates/admin/components/offCanvas/services/offCanvasService';
import { MediaQueryBreakpoints, MediaQueryService } from '~/services/util/mediaQueryService';

export enum OffCanvasAnimationStates {
    OpenedLeft = 'opened-left',
    OpenedRight = 'opened-right',
    Closed = 'closed'
}

export enum OffCanvasDisplayStates {
    Desktop = 'desktop',
    Tablet = 'tablet'
}

export let offCanvasAnimations = [
    trigger('canvas', [
        state('', style({ transform: 'translateX(0)' })),
        state('closed', style({ transform: 'translateX(0)' })),
        state('opened-left', style({ transform: 'translateX(140px)' })),
        transition('closed <=> opened-left', [
            group([
                query('@overlay', [
                    animateChild()
                ], {optional: true}),
                animate('500ms ease')
            ])
        ]),
        state('opened-right', style({ transform: 'translateX(-300px)' })),
        transition('closed <=> opened-right', [
            group([
                query('@overlay', [
                    animateChild()
                ], {optional: true}),
                animate('500ms ease')
            ])
        ]),
    ]),
    trigger('overlay', [
        state('*', style({ opacity: '0' })),
        state('opened-left', style({ opacity: '1', visibility: 'visible' })),
        state('opened-right', style({ opacity: '1', visibility: 'visible' })),
        state('closed', style({ opacity: '0', visibility: 'hidden' })),
        transition('closed <=> opened-left', animate('500ms ease')),
        transition('closed <=> opened-right', animate('500ms ease'))
    ])
];

/**
 * The offCanvas component works much like foundations offCanvas of where you have a main block and both
 * OffCanvasLeft for the left sidebar and OffCanvasRight for the right sidebar.
 * The components are bases used to be inherited by the component
 *
 * “AdminTemplateComponent” would inherit “OffCanvasComponent”.
 * “SidebarComponent” would inherit “OffCanvasLeftComponent”.
 * Sidebars in the market pages which have a situational sidebar would inherit “OffCanvasRightComponent”
 *
 * The sidebars interact with each other using the OffCanvasService and subscribe to the canvasAnimationState and canvasDisplayState.
 * From there, depending on the canvasAnimationState & canvasDisplayState each of the respective components will react accordingly.
 *
 */
@Component({
    template: ''
})
export class OffCanvasComponent implements AfterViewInit {
    protected offCanvasService:OffCanvasService;
    protected mediaQueryService:MediaQueryService;
    protected changeDetectorRef:ChangeDetectorRef;
    protected resizeTimer:any;

    @HostBinding('@canvas')
    protected offCanvasAnimationState:OffCanvasAnimationStates;
    protected canvasAnimationCompleted:boolean = true;
    public OffCanvasAnimationStates:typeof OffCanvasAnimationStates = OffCanvasAnimationStates;
    public offCanvasDisplayState:OffCanvasDisplayStates;
    public static OffCanvasAnimationDuration:number = 500;

    constructor(offCanvasService:OffCanvasService,
                mediaQueryService:MediaQueryService,
                changeDetectorRef:ChangeDetectorRef) {
        this.offCanvasService = offCanvasService;
        this.mediaQueryService = mediaQueryService;
        this.changeDetectorRef = changeDetectorRef;

        this.offCanvasService.getOffCanvasAnimationState().subscribe((offCanvasAnimationState) => {
            this.offCanvasAnimationState = offCanvasAnimationState;
        });

        this.offCanvasService.getOffCanvasDisplayState().subscribe((offCanvasDisplayState) => {
            this.offCanvasDisplayState = offCanvasDisplayState;
        });
    }

    public ngAfterViewInit() : void {
        this.setBreakpointState();
        this.changeDetectorRef.detectChanges();
    }

    @HostListener('@canvas.done')
    public setCanvasAnimationCompleted() : void {
        this.canvasAnimationCompleted = true;
    }

    @HostListener('window:resize', ['$event'])
    public onResize() : void {
        clearTimeout(this.resizeTimer);
        this.resizeTimer = setTimeout(() => {
            this.setBreakpointState();
            this.offCanvasService.setCanvasAnimationState(OffCanvasAnimationStates.Closed);
        }, 250);
    }

    public setBreakpointState() : void {
        if(!this.mediaQueryService.atLeast(MediaQueryBreakpoints.Large)) {
            this.offCanvasService.setCanvasDisplayState(OffCanvasDisplayStates.Tablet);
        }
        else {
            this.offCanvasService.setCanvasDisplayState(OffCanvasDisplayStates.Desktop);
        }
    }

    public toggleCanvasLeft() : void {
        if(this.canvasAnimationCompleted) {
            this.canvasAnimationCompleted = false;
            this.offCanvasService.toggleCanvasLeft();
        }
    }

    public toggleCanvasRight() : void {
        if(this.canvasAnimationCompleted) {
            this.canvasAnimationCompleted = false;
            this.offCanvasService.toggleCanvasRight();
        }
    }

    public closeCanvas() : void {
        if(this.canvasAnimationCompleted) {
            this.offCanvasService.setCanvasAnimationState(OffCanvasAnimationStates.Closed);
        }
    }

    public isOverlayActive() : boolean {
        return (this.offCanvasAnimationState === OffCanvasAnimationStates.OpenedLeft && this.canvasAnimationCompleted) ||
               (this.offCanvasAnimationState === OffCanvasAnimationStates.Closed && !this.canvasAnimationCompleted);
    }

    public isCanvasStateTablet() : boolean {
        return this.offCanvasDisplayState === OffCanvasDisplayStates.Tablet;
    }
}