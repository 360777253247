import { Injectable } from '@angular/core';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { HttpClientWrapper } from '~/services/api/httpClientWrapper';
import { BaseEpiService } from '~/services/api/epi/base/baseEpiService';
import { ResponseModel } from '~/models/responseModel';
import { Observable } from 'rxjs';
import { EpiModel } from '~/models/epiModel';
import { EntityEpiResponseModel } from '~/models/epi/entityEpiResponseModel';
import { forEach as _forEach, map as _map } from 'lodash';

@Injectable()
export class EntityEpiService extends BaseEpiService {

    public static readonly PATH:string = '/Entity';

    constructor(http:HttpClientWrapper) {
        super(http);
    }

    /**
     * Gets a list of names
     * @returns Observable<ResponseModel>
     */
    public getSuggestNames(name:string) : Observable<ResponseModel> {
        let url:string = EntityEpiService.URL + EntityEpiService.PATH + '/SuggestNames';

        let params = new HttpParams();
        params = params.append('name', name);

        let options = {
            params : params
        };

        return this.http.get(url, options);
    }

    public createSearchSuggestNames() : Function {
        return (name:string) => {
            return this.getSuggestNames(name);
        };
    }

    /**
     * Gets a list of names
     * @returns Observable<ResponseModel>
     */
    public getSuggestLocations(location:string) : Observable<ResponseModel> {
        let url = EntityEpiService.URL + EntityEpiService.PATH + '/SuggestLocations';

        let params = new HttpParams();
        params = params.append('location', location);

        let options = {
            params : params
        };

        return this.http.get(url, options);
    }

    public createSearchSuggestLocation() : Function {
        return (location:string) => {
            return this.getSuggestLocations(location);
        };
    }

    public search(query:{}) : Observable<ResponseModel> {

        //Set requestOptions
        let url = EntityEpiService.URL + EntityEpiService.PATH + '/Search';

        // name
        // location
        // distance
        // pageNr
        // pageSize
        // sort
        let params = new HttpParams();

        _forEach(query, function(value, key) {
            params = params.append(key, value);
        });

        let options = {
            params : params
        };

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            response['data']['entities'] = _map(response['data']['entities'], (entity) =>  {
                return new EpiModel(entity);
            });
            return new EntityEpiResponseModel(response['data']);
        });
    }
}
