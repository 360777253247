import { Injectable } from '@angular/core';
let Analytics = require('@segment/analytics-node');

export enum SegmentTrackingEvents {
    ClipCommences = 'Clip Commences',
    BusinessCreated = 'Business Created',
    SpecieCompleted = 'Speci Completed'
}

interface IdentifyInterface {
    userId?:string;
    traits:{};
}

interface TrackInterface {
    userId?:string;
    event:string;
    properties:{};
}

interface PageInterface {
    userId?:string;
    name?:string;
    properties:{};
}

//Refer to: https://segment.com/docs/sources/server/node
@Injectable()
export class SegmentService {

    private writeKey:string;
    private analytics:any;

    constructor() {
        this.writeKey = process.env['SEGMENT_ANALYTICS_WRITE_KEY'];
        if(this.writeKey) {
            this.analytics = new Analytics(this.writeKey, {flushAt: 1});
        }
    }

    public identify(data:IdentifyInterface) : void {
        if(this.analytics) {
            this.analytics.identify({
                userId: data.userId,
                traits: data.traits
            });
            this.analytics.flush();
        }
    }

    public track(data:TrackInterface) : void {
        if(this.analytics) {
            this.analytics.track({
                userId: data.userId,
                event: data.event,
                properties: data.properties
            });
            this.analytics.flush();
        }
    }

    public page(data:PageInterface) : void {
        if(this.analytics) {
            this.analytics.page({
                userId: data.userId,
                name: data.name,
                properties: data.properties
            });
            this.analytics.flush();
        }
    }
}