import { MatSnackBarRef, SimpleSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Component, Inject } from '@angular/core';

export enum SnackBarTheme {
    Normal,
    Error
}

export interface SnackBarData {
    message:string;
    snackBarTheme:SnackBarTheme;
}

@Component({
    selector: 'wep-snack-bar',
    templateUrl: './snackBar.html',
    host: {'class': 'wep-snack-bar'},
    styleUrls: ['./snackBar.scss'],
})

export class SnackBarComponent  {
    private snackRef:MatSnackBarRef<SimpleSnackBar>;
    public message:string = '';

    //Enum of snackbar theme
    public snackBarTheme:SnackBarTheme = SnackBarTheme.Normal;
    public SnackBarTheme: typeof SnackBarTheme = SnackBarTheme;

    constructor(@Inject(MAT_SNACK_BAR_DATA) public data:SnackBarData,
                snackRef: MatSnackBarRef<SimpleSnackBar>) {
        this.snackRef = snackRef;
        this.message = data.message;
        this.snackBarTheme = data.snackBarTheme;
    }

    public close() {
        this.snackRef.dismiss();
    }
}