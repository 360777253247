import { BaseModel } from '~/models/baseModel';
import { EpiModel } from '~/models/epiModel';

export class EntityEpiResponseModel extends BaseModel {
    public entities:EpiModel[];
    public totalPages:number;
    public totalItems:number;
    public businessTypes:string[];
    public avgs:{};

    constructor(values?:{}) {
        super(values);
    }
}