import { Injectable } from '@angular/core';
import { BaseService } from '~/services/api/base/baseService';
import { HttpClientWrapper } from '~/services/api/httpClientWrapper';

@Injectable()
export class BaseWebService extends BaseService {

    public static readonly URL = process.env['API_WEB_URL'];
    public static readonly IMAGE_URL = process.env['API_WEB_IMAGE_URL'];

    constructor(http:HttpClientWrapper) {
        super(http);
    }
}
