import { NgModule } from '@angular/core';

import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';

@NgModule({
    imports: [
        A11yModule,
        OverlayModule,
        PortalModule
    ],
    exports: [
        A11yModule,
        OverlayModule,
        PortalModule
    ]
})

export class AppCDKModule {
}