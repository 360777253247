import { Component, Input } from '@angular/core';

@Component({
    templateUrl: './woolClipInstruction.html',
    selector: 'wool-clip-instruction',
    host: {'class': 'wool-clip-instruction'},
    styleUrls: ['./woolClipInstruction.scss']
})

export class WoolClipInstructionComponent {

    @Input()
    generic: boolean = false;
    
}