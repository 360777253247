import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { OffCanvasAnimationStates, OffCanvasDisplayStates } from '~/templates/admin/components/offCanvas/offCanvas';
import { takeWhile } from 'rxjs/operators';

@Injectable()
export class OffCanvasService {

    private offCanvasDisplayStateBehaviour:BehaviorSubject<OffCanvasDisplayStates> = new BehaviorSubject<OffCanvasDisplayStates>(null);
    private offCanvasAnimationStateBehaviour:BehaviorSubject<OffCanvasAnimationStates> = new BehaviorSubject<OffCanvasAnimationStates>(OffCanvasAnimationStates.Closed);

    public setCanvasDisplayState(offCanvasDisplayState:OffCanvasDisplayStates) : void {
        this.offCanvasDisplayStateBehaviour.next(offCanvasDisplayState);
    }

    public getOffCanvasDisplayState() : Observable<OffCanvasDisplayStates> {
        return this.offCanvasDisplayStateBehaviour.asObservable();
    }

    public setCanvasAnimationState(offCanvasAnimationState:OffCanvasAnimationStates) : void {
        this.offCanvasAnimationStateBehaviour.next(offCanvasAnimationState);
    }

    public getOffCanvasAnimationState() : Observable<OffCanvasAnimationStates> {
        return this.offCanvasAnimationStateBehaviour.asObservable();
    }

    public toggleCanvasLeft() : void {
        let isSubscribeCompleted = false;

        this.getOffCanvasDisplayState().pipe(
            takeWhile(() => {
                return !isSubscribeCompleted;
            })
        ).subscribe((offCanvasDisplayState:OffCanvasDisplayStates) => {
            if (offCanvasDisplayState === OffCanvasDisplayStates.Tablet) {
                this.getOffCanvasAnimationState().pipe(
                    takeWhile(() => {
                        return !isSubscribeCompleted;
                    })
                ).subscribe((offCanvasAnimationState:OffCanvasAnimationStates) => {
                    isSubscribeCompleted = true;

                    if(offCanvasAnimationState === OffCanvasAnimationStates.Closed) {
                        this.setCanvasAnimationState(OffCanvasAnimationStates.OpenedLeft);
                    }
                    else {
                        this.setCanvasAnimationState(OffCanvasAnimationStates.Closed);
                    }
                });
            }
            else {
                isSubscribeCompleted = true;
            }
        });
    }

    public toggleCanvasRight() : void {
        let isSubscribeCompleted = false;

        this.getOffCanvasDisplayState().pipe(
            takeWhile(() => {
                return !isSubscribeCompleted;
            })
        ).subscribe((offCanvasDisplayState:OffCanvasDisplayStates) => {
            if(offCanvasDisplayState === OffCanvasDisplayStates.Tablet) {
                this.getOffCanvasAnimationState().pipe(
                    takeWhile(() => {
                        return !isSubscribeCompleted;
                    })
                ).subscribe((offCanvasAnimationState:OffCanvasAnimationStates) => {
                    isSubscribeCompleted = true;

                    if(offCanvasAnimationState === OffCanvasAnimationStates.Closed) {
                        this.setCanvasAnimationState(OffCanvasAnimationStates.OpenedRight);
                    }
                    else {
                        this.setCanvasAnimationState(OffCanvasAnimationStates.Closed);
                    }
                });
            }
            else {
                isSubscribeCompleted = true;
            }
        });
    }
}