import { LotModel } from '~/models/lotModel';

export class LotSummaryModel extends LotModel {
    public awexId:number; 
	public baleWeight:number; 
	public brandName:string;
	public brokerName:string;
	public buyerName:string;
	public comboDate:string;
	public description:string;
	public folio:string;
	public lotId:number;
	public lotNumber:number;
	public micron:string;
	public noOfBales:number;
	public testDate:string;
	public traceabilityStatus:string;
	public yield:string;
    public purchaseDate: string;

    constructor(values: Object = {}) {
        super(values);
    }
}
