<div class="wep-page-tpl-footer__container">
    <div class="large-flex-container">
        <div *ngIf="socialLinks.length > 0">
            <div class="wep-page-tpl-footer__social clearfix">
                <a class="wep-page-tpl-footer__social__item" [href]="socialLink.link" *ngFor="let socialLink of socialLinks; trackBy: trackBySocialLink">
                    <i class="wep-page-tpl-footer__social__icon" [ngClass]="socialLink.iconClass"></i>
                </a>
            </div>
        </div>
        <div class="large-flex-child-auto"></div>
        <div>
            <a class="wep-page-tpl-footer__link" href="//www.woolq.com/about/faqs/" target="_blank">FAQ</a>
            <a class="wep-page-tpl-footer__link" href="//www.woolq.com/about/privacy-policy/" target="_blank">Privacy</a>
            <a class="wep-page-tpl-footer__link" href="//www.woolq.com/about/terms-of-use/" target="_blank">Terms and Conditions</a>
            <span class="wep-page-tpl-footer__copyright">© 2024 AWTH</span>
        </div>
    </div>
</div>