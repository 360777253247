import { Injectable } from '@angular/core';
import { AnalyticsDataItemInterface } from '~/interfaces/util/analyticsDataItemInterface';

/**
 * A Wrapper for GA (Google Analytics). Assumes that the GA code has been rendered using GTM using angulartics2
 * The event track does not work despite putting in the config values
 * Refer to: https://github.com/angulartics/angulartics2/issues/145 and https://github.com/angulartics/angulartics2/issues/99#issuecomment-282915081
 * So this will be used in its place
 */
@Injectable()
export class AnalyticsService {
    public push(dataItem:AnalyticsDataItemInterface) : void {
        (<any>window).dataLayer.push(dataItem);
    }
}
