import { BaseModel } from '~/models/baseModel';
import * as moment from 'moment';
import { Moment } from '~/decorators/moment/momentDecorator';

export class TrendModel extends BaseModel {
    public historical19:string;
    public historical21:string;
    public historicalCustom:string;
    public future19:string;
    public future21:string;

    @Moment()
    public date:moment.Moment;
}
