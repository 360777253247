import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { isNil as _isNil, isFinite as _isFinite } from 'lodash';

@Component({
    selector: 'wep-ag-grid-cell-renderer-bar',
    templateUrl: './bar.html',
    host: { 'class' : 'wep-ag-grid-cell-renderer-bar' },
    styleUrls: ['./bar.scss']
})

export class AgGridCellRendererBarComponent implements ICellRendererAngularComp {

    public barItems:number[] = [1,2,3,4,5,6,7,8];
    private value:number;
    public params: any;

    public agInit(params: any) : void {
        this.params = params;
    }

    public refresh() : boolean {
        return false;
    }

    public getValue() : number {
        let value = !_isNil(this.params.value) && _isFinite(this.params.value) ? this.params.value : 0;

        if(value > 100) {
            value = 100;
        }
        else if(this.value < 0) {
            value = 0;
        }

        return value;
    }

    public shouldDisplayAsGreenBar(barItemIndex:number) : boolean {
        return (((100 / this.barItems.length) * barItemIndex) <= this.getValue());
    }
}