import { Injectable } from '@angular/core';
import { ExportAbstractService } from '~/services/api/web/export/exportAbstractService';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { HttpClientWrapper } from '~/services/api/httpClientWrapper';
import { BaseWebService } from '~/services/api/web/base/baseWebService';
import { ResponseModel } from '~/models/responseModel';
import { Observable } from 'rxjs';

@Injectable()
export class ExportService extends BaseWebService implements ExportAbstractService {

    public static readonly PATH:string = '/export';

    constructor(http: HttpClientWrapper) {
        super(http);
    }

    public getExportHTML(specieId:string) : Observable<ResponseModel> {
        let url:string = ExportService.URL + ExportService.PATH + '/' + specieId + '.html';

        let params = new HttpParams();
        params = params.append('specieId', specieId);

        let options:{} = {
            params : params,
            responseType: 'text' as 'text'
        };

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return response;
        });
    }

    public getExportPDFUrl(specieId:string) : Observable<ResponseModel> {
        let url:string = ExportService.URL + ExportService.PATH + '/' + specieId + '.pdf';

        let params = new HttpParams();
        params = params.append('specieId', specieId);

        let options:{} = {
            params : params,
            responseType: 'blob' as 'blob'
        };

        return this.http.get(url, options, (response:HttpResponse<any>) => {
             return response;
        });
    }
}
