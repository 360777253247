import { Injectable } from '@angular/core';
import { HttpClientWrapper } from '~/services/api/httpClientWrapper';
import { forOwn as _forOwn } from 'lodash';

@Injectable()
export class BaseService {
    protected http:HttpClientWrapper;

    constructor(http:HttpClientWrapper) {
        this.http = http;
    }

    public getFormUrlEncoded(object:any) : string {
        let formBody = [];
        _forOwn(object, (value: any, key:string) => {
            let encodedKey = encodeURIComponent(key);
            let encodedValue = encodeURIComponent(value.toString());
            formBody.push(encodedKey + '=' + encodedValue);
        });
        return formBody.join('&');
    };
}
