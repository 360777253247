<wep-page-tpl-admin-default>
    <wep-page-tpl-admin-default-heading-left>
        <ng-container *ngIf="eSpeciViewState === ESpeciViewState.SetupBrandView">
            <span>eSpeci</span>
        </ng-container>
        <ng-container *ngIf="eSpeciViewState === ESpeciViewState.SpecieView || eSpeciViewState === ESpeciViewState.SetupClipView">
            <ng-container *ngIf="currentBrand && currentBrand.brandName">
                <span class="show-for-medium">{{currentBrand.brandName | wepLimitTo:25 }}</span>
                <span class="hide-for-medium">{{currentBrand.brandName | wepLimitTo:16 }}</span>
                <a (click)="displaySetupBrandView()" href="javascript:void(0)" class="page__header__link">Change</a>
                <span class="hide-for-medium">
                    <br/>
                </span>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="eSpeciViewState === ESpeciViewState.SpecieView">
            <ng-container *ngIf="currentClip && currentClip.clipReference">
                <span class="show-for-medium">{{currentClip.clipReference | wepLimitTo:25 }}</span>
                <span class="hide-for-medium">{{currentClip.clipReference | wepLimitTo:16 }}</span>
                <a (click)="displaySetupClipView()" href="javascript:void(0)" class="page__header__link">Change</a>
            </ng-container>
        </ng-container>
    </wep-page-tpl-admin-default-heading-left>
    <wep-page-tpl-admin-default-content>
        <div>
            <div class="row">
                <div class="small-12 medium-6 columns">
                    <wool-clip-instruction [generic]="true"></wool-clip-instruction>
                </div>
                <div class="small-12 medium-6 columns right-column" *ngIf="!currentBrand || !currentBrand.woolClipLinked">
                    <wep-button (click)="onConnectToWoolClipClicked()"> Connect to WoolClip </wep-button>
                    <h3>
                        <a href="https://www.woolclip.com.au" target="_blank">Go to WoolClip</a>
                    </h3>
                </div>
                <div class="small-12 medium-6 columns right-column" *ngIf="currentBrand && currentBrand.woolClipLinked">
                    <wep-button> Accounts are Linked </wep-button>
                    <h3>
                        <a href="https://www.woolclip.com.au" target="_blank">Go to WoolClip to complete your Speci</a>
                    </h3>
                </div>
            </div>
        </div>
        <!--
        <wep-notification-error class="margin--bottom--double" *ngIf="currentBrand && !currentBrand.parentEntityID">
            <wep-notification-heading>
                Temporary brand in use
            </wep-notification-heading>
            <wep-notification-content>
                This eSpeci is being completed using a temporary brand. You will be required to connect this eSpeci to a business profile in order to receive test and sale results.
            </wep-notification-content>
        </wep-notification-error>
        <wep-box class="padding--none margin--bottom--double">
            <div class="speci-tabs">
                <div [@animate]="tabAnimationString[0]" class="speci-tabs__tab speci-tabs__tab--clip" cdkTrapFocus>
                    <wep-especi-view-setup-brand
                            *ngIf="eSpeciViewState === ESpeciViewState.SetupBrandView"
                            [eSpeciRepository]="eSpeciRepository"
                            [showNext]="!currentBrand  || !currentBrand.woolClipLinked"
                            (brandSelecting)="brandSelectingEvent($event)"
                            (brandSelected)="brandSelectedEvent($event)">
                    </wep-especi-view-setup-brand>
                    <wep-especi-view-setup-clip
                            *ngIf="eSpeciViewState === ESpeciViewState.SetupClipView"
                            [eSpeciRepository]="eSpeciRepository"
                            (clipSelected)="clipSelectedEvent($event)">
                    </wep-especi-view-setup-clip>
                </div>
                <div [@animate]="tabAnimationString[1]" class="speci-tabs__tab speci-tabs__tab--speci" cdkTrapFocus
                >
                    <wep-tabs [tabIndex]="activeTabIndex" (tabIndexChange)="activeTabIndexEvent($event)">
                        <wep-tab tab-title="Overview" [tab-icon-src]="iconClipPath">
                            <wep-especi-view-tab-overview
                                    [eSpeciRepository]="eSpeciRepository"
                                    (goToMobs)="goToMobsTab()"
                                    (goToBales)="goToBalesTab()"
                                    (goToLines)="goToLinesTab()"
                                    (goToSpecies)="goToSpeciesTab()"
                                    (clipDisabled)="clipDisabled()"
                                    [loading]="!clipItemsLoaded"
                                    (currentClipChange)="currentClipEvent($event)">
                            </wep-especi-view-tab-overview>
                        </wep-tab>
                        <wep-tab tab-title="Mob" [tab-icon-src]="iconMobPath">
                            <wep-especi-view-tab-mobs
                                    *ngIf="!selectedMob && clipItemsLoaded"
                                    [eSpeciRepository]="eSpeciRepository"
                                    [loading]="!clipItemsLoaded"
                                    (setSelectedMobChange)="setSelectedMobEvent($event)">
                            </wep-especi-view-tab-mobs>
                            <wep-especi-view-tab-mob
                                    *ngIf="selectedMob && clipItemsLoaded"
                                    [eSpeciRepository]="eSpeciRepository"
                                    [selectedMob]="selectedMob"
                                    (deselectMobChange)="deselectMobEvent($event)">
                            </wep-especi-view-tab-mob>
                        </wep-tab>
                        <wep-tab tab-title="Wool Book" [tab-icon-src]="iconWoolBookPath">
                            <wep-especi-view-tab-bales
                                    *ngIf="!selectedBale && clipItemsLoaded"
                                    [eSpeciRepository]="eSpeciRepository"
                                    [loading]="!clipItemsLoaded"
                                    (setSelectedBaleChange)="setSelectedBaleEvent($event)"
                                    (currentClipChange)="currentClipEvent($event)">
                            </wep-especi-view-tab-bales>
                            <wep-especi-view-tab-bale
                                    *ngIf="selectedBale && clipItemsLoaded"
                                    [eSpeciRepository]="eSpeciRepository"
                                    [selectedBale]="selectedBale"
                                    (deselectBaleChange)="deselectBaleEvent($event)"
                                    (currentClipChange)="currentClipEvent($event)">
                            </wep-especi-view-tab-bale>
                        </wep-tab>
                        <wep-tab tab-title="Lines" [tab-icon-src]="iconLinePath">
                            <wep-especi-view-tab-lines
                                    *ngIf="!selectedLine && clipItemsLoaded"
                                    [eSpeciRepository]="eSpeciRepository"
                                    [loading]="!clipItemsLoaded"
                                    (setSelectedLineChange)="setSelectedLineEvent($event)">
                            </wep-especi-view-tab-lines>
                            <wep-especi-view-tab-line
                                    *ngIf="selectedLine && clipItemsLoaded"
                                    [eSpeciRepository]="eSpeciRepository"
                                    [selectedLine]="selectedLine"
                                    (deselectLineChange)="deselectLineEvent($event)">
                            </wep-especi-view-tab-line>
                        </wep-tab>
                        <wep-tab tab-title="eSpeci" [tab-icon-src]="iconSignPath">
                            <wep-especi-view-tab-species
                                    *ngIf="!selectedSpecie && clipItemsLoaded"
                                    [eSpeciRepository]="eSpeciRepository"
                                    [loading]="!clipItemsLoaded"
                                    (setSelectedSpecieChange)="setSelectedSpecieEvent($event)">
                            </wep-especi-view-tab-species>
                            <wep-especi-view-tab-specie
                                    *ngIf="selectedSpecie && clipItemsLoaded"
                                    [eSpeciRepository]="eSpeciRepository"
                                    [selectedSpecie]="selectedSpecie"
                                    (currentBrandChange)="currentBrandEvent($event)"
                                    (deselectSpecieChange)="deselectSpecieEvent($event)">
                            </wep-especi-view-tab-specie>
                        </wep-tab>
                    </wep-tabs>
                </div>
            </div>
        </wep-box>
        <wep-integrated-partners>
        </wep-integrated-partners>
    -->
    </wep-page-tpl-admin-default-content>
</wep-page-tpl-admin-default>
