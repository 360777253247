import { Injectable } from '@angular/core';
import { ConstantsAbstractService } from '~/services/api/web/constants/constantsAbstractService';
import { HttpResponse } from '@angular/common/http';
import { HttpClientWrapper } from '~/services/api/httpClientWrapper';
import { BaseWebService } from '~/services/api/web/base/baseWebService';
import { ResponseModel } from '~/models/responseModel';
import { ConstantsModel } from '~/models/constantsModel';
import { EntityConstantsModel } from '~/models/entityConstantsModel';
import { Observable } from 'rxjs';
import { IndicatorCategoryModel } from '~/models/indicatorCategoryModel';
import { map as _map } from 'lodash';

@Injectable()
export class ConstantsService extends BaseWebService implements ConstantsAbstractService {

    constructor(http: HttpClientWrapper) {
        super(http);
    }

    public getConstants() : Observable<ResponseModel> {
        let url:string = ConstantsService.URL + '/util/constants';
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return new ConstantsModel(response['data']);
        });
    }

    public getCategoryIndicators() : Observable<ResponseModel> {
        let url:string = ConstantsService.URL + '/util/constants/categoryindicators';
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
           return _map(response['data'], (indi) => new IndicatorCategoryModel(indi));
        });
    }

    public getEntityConstants() : Observable<ResponseModel> {
        let url:string = ConstantsService.URL + '/util/entity_constants';
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return new EntityConstantsModel(response['data']);
        });
    }
}
