import { BaseModel } from "./baseModel";

export class IndicatorCategoryModel extends BaseModel  {
    categoryIndicatorId:number;
    name:string;
    description:string;
    displayOrder:number;
    actionUrl:string;

    selected:boolean;
    iconUrl:string;
}