export class BrokerServicePriceType {
    public priceUnit:string = '';
    public priceValue:number = 0;

    public setPrice(values:{}) {

        // if price prop exists override
        // then use same algorithm
        if (typeof values['price'] !== 'undefined') {
            values = values['price'];
        }

        if (typeof values['priceUnit'] !== 'undefined') {
            this.priceUnit = values['priceUnit'];
        }

        if (typeof values['priceValue'] !== 'undefined') {
            this.priceValue = parseInt(values['priceValue'], 10);
        }
    }
}