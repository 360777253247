import { PermissionModel } from '~/models/permissionModel';
import { LotTestResultModel } from '~/models/lotTestResultModel';
import { SalesResultModel } from '~/models/salesResultModel';

export class LotModel extends PermissionModel {
    public id:number;
    public clipId:number;
    public baleStatus:string;
    public testStatus:string;
    public saleStatus:string;
    public classedBy:string;
    public classerId:string;
    public assignedTo:string;
    public mobs:string;
    public clipReference:string;
    public classerComments:string;
    public isSelling:boolean;
    public salesResult:SalesResultModel;
    public testResult:LotTestResultModel;
    public noMobs:number;

    public canEditTestData:boolean = false;

    constructor(values: Object = {}) {
        super(values);
        if(values['salesResult']) {
            this.salesResult = new SalesResultModel(values['salesResult'])
        }
        if(values['testResult']) {
            this.testResult = new LotTestResultModel(values['testResult'])
        }
    }
}
