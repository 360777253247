import { BaseModel } from '~/models/baseModel';
import { OfferModel } from '~/models/offerModel';
import { OfferSaleModel } from '~/models/offerSaleModel';
import { Moment } from '~/decorators/moment/momentDecorator';
import * as moment from 'moment';

export enum DisputeStatus {
    Open = 'Open',
    Approved = 'Approved',
    Cancelled = 'Cancelled'
}

export class DisputeModel extends BaseModel {
    public id:number;
    public saleId:number;
    public comment:string;

    @Moment()
    public createdData:moment.Moment;
    public status:DisputeStatus;
    public createdByContactId:number;
    public createdByContactName:string;
    public createdByEntityName:string;

    @Moment()
    public dueDate:moment.Moment;

    public categoryId:number;
    public category:string;
    public createdByEntityId:number;

    public offer:OfferModel;
    public sale:OfferSaleModel;

    //@TODO add these values to the API
    public details:string;
    public userName:string;
    public business:string;
    public description:string;

    constructor(values:Object = {}) {
        super(values);

        if(values['offer']) {
            this.offer = new OfferModel(values['offer']);
        }

        if(values['sale']) {
            this.sale = new OfferSaleModel(values['sale']);
        }
    }
}
