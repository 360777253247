import { Injectable } from '@angular/core';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { MarketAbstractService } from '~/services/api/web/market/marketAbstractService';
import { HttpClientWrapper } from '~/services/api/httpClientWrapper';
import { BaseWebService } from '~/services/api/web/base/baseWebService';

// Models
import { AwexModel } from '~/models/awexModel';
import { PricesModel } from '~/models/pricesModel';
import { TrendModel } from '~/models/trendModel';
import { ResponseModel } from '~/models/responseModel';
import { SaleFiltersModel } from '~/models/saleFiltesModel';
import { RecentSaleModel } from '~/models/recentSaleModel';

// Rxjs
import { Observable } from 'rxjs';
import { map as _map } from 'lodash';
import { PRMMicPirce } from '~/models/prmMicPrice.Model';
import { Percentile } from '~/models/percentile.Model';


@Injectable()
export class MarketService extends BaseWebService implements MarketAbstractService {

    public static readonly PATH:string = '/market';

    constructor(http: HttpClientWrapper) {
        super(http);
    }

    public getAwexIndicator(region?:string) : Observable<ResponseModel> {
        let url:string = MarketService.URL + MarketService.PATH + '/awex/indicator';
        let params = new HttpParams();

        if(region) {
            params = params.append('region', region);
        }

        let options = {
            params : params
        };

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (item) => new AwexModel(item));
        });
    }

    public getPrices(region?:string) : Observable<ResponseModel> {
        let url:string = MarketService.URL + MarketService.PATH + '/dashboard/prices';
        let params = new HttpParams();

        if(region) {
            params = params.append('region', region);
        }

        let options = {
            params : params
        };

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return new PricesModel(response['data']);
        });
    }

    public getTrends(region?:string, custom?:string) : Observable<ResponseModel> {
        let url:string = MarketService.URL + MarketService.PATH + '/dashboard/trend';
        let params = new HttpParams();

        if(region) {
            params = params.append('region', region);
        }

        if(custom) {
            params = params.append('custom', custom);
        }

        let options = {
            params : params
        };

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (item) => new TrendModel(item));
        });
    }

    public getMarketTrends(months:number) : Observable<ResponseModel> {
        let url:string = MarketService.URL + MarketService.PATH + '/trend/' + months.toString();
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (item) => new TrendModel(item));
        });
    }

    public getRecentSales(salesFilter:SaleFiltersModel) : Observable<ResponseModel> {
        let url:string = MarketService.URL + MarketService.PATH + '/recentsales';

        let body:any = salesFilter;
        let options:{} = {};

        return this.http.post(url, body, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (item) => new RecentSaleModel(item));
        });
    }

    public getPRMByMic(mic?:string) : Observable<ResponseModel> {
        let url:string = MarketService.URL + MarketService.PATH + '/prmbymic';
        let params = new HttpParams();

        params = params.append('micName', mic);

        let options = {
            params : params
        };

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], p => new PRMMicPirce(p));
        });
    }

    public getPercentileData() : Observable<ResponseModel> {
        let url:string = MarketService.URL + MarketService.PATH + '/percentile';
        let options = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], p => new Percentile(p));
        });
    }
}
