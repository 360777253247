import { Component, AfterViewChecked } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { isFunction as _isFunction, find as _find } from 'lodash';

export interface AgGridCellEditorSelectParams extends ICellRendererParams {
    setInitialValue:(data:any, value:any) => void;
    updateValue:(data:any, value:any) => void;
    getDisplayText:(data:any) => string;
    getOptions:(data:any) => any[];
}

@Component({
    selector: 'wep-ag-grid-cell-renderer-market-auction-limit-select',
    templateUrl: './limitSelect.html',
    host: { 'class' : 'wep-ag-grid-cell-renderer wep-ag-grid-cell-renderer-market wep-ag-grid-cell-renderer-market-auction-limit-select' },
    styleUrls: ['./limitSelect.scss']
})

export class AgGridCellRendererMarketAuctionLimitSelectComponent implements ICellRendererAngularComp, AfterViewChecked {

    public options:any[];
    public params:any;

    constructor() {
    }

    public agInit(params: any) : void {
        this.params = params;
        this.options = params.getOptions(params.data);

        //Mark first value as selected
        if(_isFunction(this.params.setInitialValue) && this.options.length > 0) {
            if(params.value || (params.data && params.data.myBid)) {
                let op = _find(this.options, (p:any) => p.limit === (params.value || params.data.myBid));
                if(op) { //May be check if it has description
                    this.params.setInitialValue(params, op);
                }else {
                this.params.setInitialValue(params, this.options[0]);
                }
            }
            else {
                this.params.setInitialValue(params, this.options[0]);
            }
        }
    }

    public ngAfterViewChecked() : void {
        this.params.eGridCell.style.minHeight = '56px';
    }

    public refresh() : boolean {
        return false;
    }

    public onChanged(value:any) : void {
        this.params.updateValue(this.params, value);
    }

    public getDisplayText(value:any) : string {
        if(_isFunction(this.params.getDisplayText) && value) {
            return this.params.getDisplayText(value,this.params);
        }
        return '';
    }
}
