import { BaseModel } from '~/models/baseModel';
import { OfferLotModel } from '~/models/offerLotModel';
import { OfferSaleModel } from '~/models/offerSaleModel';
import { Moment } from '~/decorators/moment/momentDecorator';
import * as moment from 'moment';

export enum TransactionStatus {
    Unresolved = 'Unresolved',
    InProgress = 'InProgress',
    Completed = 'Completed',
    Cancelled = 'Cancelled',
}

//@TODO when the api is ready and the real columns are available refactor the field values.
export class TransactionModel extends BaseModel {
    public transactionId:number;

    public business:string;
    public userName:string;

    public bales:number;
    public bidCost:number;

    @Moment()
    public invoiceDate:moment.Moment;

    public totalFee:number;
    public status:TransactionStatus;

    public lot:OfferLotModel;
    public sale:OfferSaleModel;

    //@TODO add these values to the API
    public description:string;

    constructor(values:Object = {}) {
        super(values);

        if(values['lot']) {
            this.lot = new OfferLotModel(values['lot']);
        }

        if(values['sale']) {
            this.sale = new OfferSaleModel(values['sale']);
        }
    }
}
