import { BaseModel } from '~/models/baseModel';
import { ArrayBooleanMapper } from '~/decorators/arrayBooleanMapper/arrayBooleanMapperDecorator';

export class BrokerModel extends BaseModel {

    public brokerId: number;
    public brokerContactId: number;
    public brokerCode: string;
    public ediCode: string;
    public awexRegistrationNumber: string;

    @ArrayBooleanMapper(
        'brokerServicesTypes',
        [
            { key: 'auction', value: 'Auction' },
            { key: 'directFarm', value: 'DirectFromFarm' },
            { key: 'directMill', value: 'DirectToMill' },
            { key: 'elecSales', value: 'ElectronicSales' },
            { key: 'exportSale', value: 'DirectExportSale' },
            { key: 'farmBuying', value: 'OnFarmBuying' },
            { key: 'forwMarket', value: 'ForwardsMarket' },
            { key: 'otherServices', value: 'OtherAgriServices' },
            { key: 'privateBuying', value: 'PrivateBuying' },
            { key: 'storeSale', value: 'StoreDoorSale' }
        ]
    )
    public brokerServices:string[];
    public getBrokerServiceDescription(service: string) {
        return BrokerServiceEnum[service];
    }

    public hasValues(): boolean {
        let hasValues = (this.brokerId > 0);

        hasValues = hasValues || (typeof this.brokerCode !== 'undefined' && this.brokerCode.length > 0);
        hasValues = hasValues || (typeof this.ediCode !== 'undefined' && this.ediCode.length > 0);
        hasValues = hasValues || (typeof this.awexRegistrationNumber !== 'undefined' && this.awexRegistrationNumber.length > 0);

        hasValues = hasValues || (this.brokerServices.length > 0);

        return hasValues;
    }
}

enum BrokerServiceEnum {
    Auction = 'Auction',
    ElectronicSales = 'Electronic Sales',
    DirectToMill = 'Direct to Mill',
    DirectFromFarm = 'Direct from Farm',
    StoreDoorSale = 'Store Door Sale',
    DirectExportSale = 'Direct Export Sale',
    ForwardsMarket = 'Forwards Market',
    OnFarmBuying = 'On Farm Buying',
    PrivateBuying = 'Private Buying',
    OtherAgriServices = 'Other Agri Services'
}