import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { OKTA_AUTH } from '@okta/okta-angular';
import { Injectable, Inject } from '@angular/core';
import { OktaAuth } from '@okta/okta-auth-js';
import { AuthService } from './api/auth/authService';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private authService: AuthService;

  constructor(authService: AuthService) {
    this.authService = authService;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handleAccess(request, next));
  }

  private handleAccess(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    // Only add an access token to whitelisted origins
    const allowedOrigins = ['http://localhost', 'https://qa-api.woolq.com', 'https://qa.woolq.com', 'https://stage-api.woolq.com', 'https://stage.woolq.com', 'https://api-woolq-demo.azurewebsites.net', 'https://playground.woolq.com/', 'https://api.woolq.com', 'https://www.woolq.com/', 'https://woolq.com/'];
    if (allowedOrigins.some(url => request.urlWithParams.includes(url))) {
      const accessToken = this.authService.getAuthToken();
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + accessToken
        }
      });
    }
    return next.handle(request).toPromise();
  }
  
}