import { BaseModel } from '~/models/baseModel';
import { ForwardMarketValueModel } from '~/models/forwardMarketValueModel';
import { map as  _map } from 'lodash';

export class ForwardMarketModel extends BaseModel {
    public estMicron:number;
    public values:ForwardMarketValueModel[];

    constructor(values: Object = {}) {
        super(values);

        if ((typeof values['values'] !== 'undefined')) {
            this.values = _map(values['values'], (data:{}) => new ForwardMarketValueModel(data));
        }
    }
}
