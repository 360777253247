import { BaseModel } from '~/models/baseModel';

interface HasYear {
    year:number;
}

export type YieldData = HasYear & { yield:number; };
export type StrengthData = HasYear & { strength:number; };
export type MicronData = HasYear & { micron:number; };
export type BaleWeightData = HasYear & { baleWeight:number; };
export type AvgBaleWeightData = HasYear & { avgBaleWeight:number; };
export type CutPerHeadData = HasYear & { cutPerHead:number; };
export type BaleData = HasYear & { bales:number; };

export class InsightModel extends BaseModel {
    public yields:YieldData[];
    public strengths:StrengthData[];
    public microns:MicronData[];
    public baleWeights:BaleWeightData[];
    public noBales:BaleData[];
    public avgBaleWeights:AvgBaleWeightData[];
    public cutPerHeads:CutPerHeadData[];

    public micron:number;
    public preMicron:number;
    public micronChange:number;

    public strength:number;
    public preStrength:number;
    public strengthChange:number;

    public yield:number;
    public preYield:number;
    public yieldChange:number;

    public baleWeight:number;
    public preBaleWeight:number;
    public baleWeightChange:number;

    public avgBaleWeight:number;
    public preAvgBaleWeight:number;
    public avgBaleWeightChange:number;

    public cutPerHead:number;
    public preCutPerHead:number;
    public cutPerHeadChange:number;

    public noBale:number;
    public preNoBale:number;
    public noBaleChange:number;
}
